import React from "react";
import sift from "sift";
import { Redirect } from "react-router-dom";
import { useApi } from "traffic-property-app/api";
import { LandForSalePage } from "traffic-property-app/pages/explore/land-for-sale";

// Layout components
import { Content, ContentFooter } from "layouts/MainLayout";

// land-for-sale specific components
import { PackagesList } from "components/Package";
import SendDetailsForm from "components/SendDetailsForm";

import styles from "./land-for-sale.module.scss";

const LandForSale = props => {
  const pathname = window.location.pathname;
  const { match } = props;
  const api = useApi();
  const filter = {
    sort: "name",
    release: match.params.releaseOrLot ? match.params.releaseOrLot : null,
    statusOptions: "featured"
  };

  if (["/land-for-sale", "/land-for-sale/"].includes(pathname)) {
    return <Redirect to="/land-for-sale/stage-1" />;
  }

  const defaultLegends = {
    neighbourhood: [
      {
        label: "Available",
        className: "available"
      },
      {
        label: "Deposit Taken",
        className: "hold"
      },
      {
        label: "House & Land Exclusive",
        className: "builders"
      },
      {
        label: "Sold",
        className: "sold"
      },
      {
        label: "Coming Soon",
        className: "coming_soon"
      }
    ]
  };

  const lotID =
    match.params.lotOrLotID !== "lot"
      ? match.params.lotOrLotID
      : match.params.lotID;

  let packages = api.lots.list(filter);
  let allPackages = api.lots.list();

  let pkg = lotID ? allPackages.find(sift({ slug: lotID })) : null;

  packages = !lotID ? packages : packages.filter(lot => lot.slug !== lotID);
  allPackages = allPackages.filter(
    lot => lot.release_slug === match.params.releaseOrLot
  );

  const [firstPackage = {}] = allPackages || [];

  return (
    <>
      <style>
        {`.ta-filter--extra .ta-filter--extra__submit .ta-btn {
            color: transparent;
          }
          .ta-filter--extra .ta-filter--extra__submit .ta-btn:after {
            content: 'Search Land for Sale';
            color: initial;
            position: absolute;
            left: 0;
            right: 0;
          }
          `}
      </style>
      <SendDetailsForm className={styles.sendDetailsForm} package={pkg} />
      <LandForSalePage
        {...props}
        className="ta-has-results ta-no-lfs-header"
        noAdvancedSearch={false}
        isResultPage={true}
        defaultLegends={defaultLegends}
        showFilters
      />
      <Content>
        <ContentFooter className="contentfooter">
          {allPackages.length || packages.length ? (
            <h1 className={styles.title}>
              {match.params.releaseOrLot
                ? `${firstPackage.release_name} Lots`
                : "Featured Lots"}
            </h1>
          ) : null}
          <PackagesList
            packages={match.params.releaseOrLot ? allPackages : packages}
          />
        </ContentFooter>
      </Content>
    </>
  );
};

export default LandForSale;
