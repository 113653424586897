import config from "traffic-property-app/config";

// Pages
import { LandForSalePage } from "./land-for-sale";

const masterplan = config.routes.get("landforsale.masterplan");

export const Routes = [
  {
    name: "Masterplan",
    path: `${masterplan}/:neighbourhoodOrRelease?/:releaseOrLot?/:lotOrLotID?/:lotID?`,
    component: LandForSalePage
  }
];

export * from "./land-for-sale";
