import React from "react";
import classnames from "classnames";
import config from "traffic-property-app/config";

import styles from "./EstateLabel.module.scss";

export const EstateLabel = ({
  estate = {},
  builder = {},
  neighbourhood = {},
  className,
  style = {},
  ...props
}) => {
  const isDevCompany =
    !config.api.get("project") && !config.api.get("neighbourhood");
  const isProject = config.api.get("project");

  const logo = isDevCompany
    ? estate.logo
    : isProject
      ? neighbourhood.logo
      : null;

  const label = isDevCompany
    ? estate.label
    : isProject
      ? neighbourhood.label
      : null;

  const color = isDevCompany
    ? estate.color
    : isProject
      ? neighbourhood.color
      : null;

  const builderLogo = builder.logo ? (
    <img
      src={builder.logo}
      alt={builder.name}
      width="100px"
      className={styles.builder}
    />
  ) : null;

  if (logo)
    return (
      <div
        {...props}
        className={classnames(styles.wrapper, className)}
        style={{ ...style, backgroundColor: color }}
      >
        <img src={logo} alt={label} className={styles.logo} />
        {builderLogo}
      </div>
    );

  if (builder.logo) return builderLogo;

  return null;
};

export default EstateLabel;
