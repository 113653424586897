import React, { useContext } from "react";
// import PropTypes from "prop-types";
import classnames from "classnames";
import { withRouter } from "react-router-dom";

// Components
import Icon from "traffic-property-app/components/Icon";

import { RouterHistoryContext } from "./RouterHistory";

import "./BackButton.scss";

export const BackButton = ({
  type: Type,
  children,
  className,
  title: titleProp,
  to,
  defaultTo,
  history,
  external,
  unless,
  ...props
}) => {
  if (!Type) Type = "button";
  className = classnames(className, "backbutton");

  const { old } = useContext(RouterHistoryContext);

  let goBackPath = {};
  const path = to || (old && old.path) || defaultTo;
  const paths = (old && old.path && old.path.split("/")) || [];
  unless = unless && unless.split("/")[1];

  if (unless && paths[1] && unless === paths[1]) {
    goBackPath = {
      ...goBackPath,
      ...old,
      path: unless
    };
  } else {
    goBackPath = {
      ...goBackPath,
      name: to ? titleProp : (old && old.name) || titleProp,
      path
    };
  }

  const goBack = () => {
    const link =
      goBackPath.path[0] !== "/" ? `/${goBackPath.path}` : goBackPath.path;

    if (external) return (window.location = link);
    return history.push(link);
  };

  const title = goBackPath.name;

  return (
    <Type tag={Type} className={className} onClick={goBack}>
      <Icon icon="arrow" />
      {title ? `back to ${title}` : "back"}
    </Type>
  );
};

// BackButton.propTypes = {};

export default withRouter(BackButton);
