import React, { useRef, useState } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import useScrollEngine from "utils/useScrollEngine";

// Layout components
import { Sidebar } from "layouts/MainLayout";
import Amenities from "traffic-property-app/acf-blocks/amenities";
import config from "config";

import styles from "./amenities.module.scss";

import "./amenities.scss";

const { isTouchTable } = config;

const AmenitiesPage = props => {
  const amenitiesListRef = useRef();
  const { amenities } = config.acf_settings || {};
  const defaultActiveCategory = amenities.categories
    ? amenities.categories[0]
    : [];
  const [activeCategory, setActiveCategory] = useState(defaultActiveCategory);
  const [amenitiesListParentStyle, amenitiesListStyle] = useScrollEngine(
    amenitiesListRef
  );
  const backgroundImage = `url("${config.get("style.icons.masterplan")}")`;
// console.log(amenities);
  return (
    <>
      <Amenities
        {...amenities}
        categories={[activeCategory]}
        zoom={false}
        amenitiesListParentStyle={amenitiesListParentStyle}
        amenitiesListRef={!isTouchTable && amenitiesListRef}
        amenitiesListStyle={amenitiesListStyle}
        
      />

      <Sidebar
        className={classnames(styles.sidebar, styles.sidebar_footer)}
        draggable
      >
        <div className={styles.title}>Categories</div>
        <ul className={styles.listgroup}>
          {amenities.categories.map((category, i) => {
            const isActive = activeCategory.name === category.name;
           
            return (
              <li
                key={`category-${i}`}
                className={classnames(styles.listitem, {
                  [styles.active]: isActive,
                  "active": isActive
                })}
              >
                <button
                  onClick={() => setActiveCategory(category)}
                  //eslint-disable-next-line
                  style={{ padding: 0 }, isActive ? { color: category.fontColour, borderColor: category.colour, backgroundColor: category.colour} : null}
                >
                  <div
                    className={styles.image}
                    style={{ backgroundColor: category.colour }}
                  />
                  {category.name}
                </button>
              </li>
            );
          })}
          {isTouchTable ? (
            <>
              <div className={styles.title}>Eliston</div>
              <li key="levels-amenities" className={classnames(styles.listitem, styles.masterplan)}>
                <Link to="/masterplan">
                  <div
                    className={styles.image}
                    style={{
                      backgroundImage
                    }}
                  />
                  Masterplan
                </Link>
              </li>
            </>
          ) : null}
        </ul>
      </Sidebar>
    </>
  );
};

export default AmenitiesPage;
