import React from "react";
import classnames from "classnames";
import sift from "sift";
import config from "traffic-property-app/config";

import styles from "./Advertising.module.scss";

const ads = config.promotions;

const otherAds = ads.filter(
  sift({
    match: {
      $elemMatch: {
        type: { $nin: ["neighbourhood", "release"] }
      }
    }
  })
);

const releaseAds = ads.filter(
  sift({ match: { $elemMatch: { type: "release" } } })
);

const neighbourhoodsAds = ads.filter(
  sift({ match: { $elemMatch: { type: "neighbourhood" } } })
);

const filterAds = (query, index, type) => {
  const ads = !type
    ? otherAds
    : type === "release"
      ? releaseAds
      : type === "neighbourhood"
        ? neighbourhoodsAds
        : null;

  const $or = query.filter(
    sift({ type: !type ? { $nin: ["neighbourhood", "release"] } : type })
  );

  // console.log("-----");
  // console.log("type", type);
  // console.log("$or", $or);
  // console.log("ads", ads);

  const siftQuery = $or ? { match: { $elemMatch: { $or } } } : null;

  const ad = siftQuery
    ? index
      ? ads.filter(sift(siftQuery))[index]
      : ads.find(sift(siftQuery))
    : null;

  if (ad || type === "neighbourhood") return ad;
  if (!ad && !type) return filterAds(query, index, "release");
  if (!ad && type === "release")
    return filterAds(query, index, "neighbourhood");
};

export const Advertising = ({ className, query, index, height, ...props }) => {
  const ad = query ? filterAds(query, index) : null;

  if (!ad) return null;

  const image = ad.get("image.desktop") || ad.get("image");
  const imageMobile = ad.get("image.mobile") || ad.get("image");
  const link = ad.get("link");

  if (!image || !link) return null;

  return (
    <a
      {...props}
      className={classnames(styles.promo, className)}
      href={link}
      target="_BLANK"
      rel="noopener noreferrer"
    >
      <div
        className={classnames({ [styles.desktop]: imageMobile })}
        style={{ backgroundImage: `url(${image})`, height }}
      />
      {imageMobile ? (
        <div
          className={styles.mobile}
          style={{ backgroundImage: `url(${imageMobile})`, height }}
        />
      ) : null}
      {/* <img
        src={image}
        title="Promo"
        alt="promo"
        className={classnames({ styles.desktop: imageMobile })}
      />
      {imageMobile && (
        <img
          src={imageMobile}
          title="Promo"
          alt="promo"
          className={styles.mobile}
        />
      )} */}
    </a>
  );
};

export default Advertising;
