import React, { useState, useEffect, useRef, useCallback } from "react";
import classnames from "classnames";
import { Power3, TweenLite, Draggable } from "gsap/all";
import { useWindowWidth } from "@react-hook/window-size";

import styles from "./MarkersMap.module.scss";

export const MarkersMap = ({
  className,
  levels,
  onMarkerClick,
  activeMarker,
  zoom
}) => {
  const width = useWindowWidth();
  const [firstLevel] = levels || [];
  const [selected, setSelected] = useState(firstLevel || {});
  const { markers } = selected;
  // const [initialMarker] = markers;
  // const [activeMarker, setActiveMarker] = useState(initialMarker);

  const map = useRef(null);
  const wrapper = useRef(null);
  const draggable = useRef(null);

  const centerActiveMarker = useCallback(() => {
    const marker = activeMarker;
    const markerXPercentage = parseInt(marker.coordinates.x);
    const markerYPercentage = parseInt(marker.coordinates.y);

    // =================
    // X Coordinate
    // =================

    // get the current position of the image on the wrapper (transform: xx)
    const currentX = Math.trunc(Math.abs(draggable.current.x));
    // get the wrapper width (parent div)
    const wrapperWidth =
      draggable.current.target.parentNode.parentNode.clientWidth;
    // calculate the middle point of the wrapper
    const xMiddlePoint = currentX + wrapperWidth / 2;

    // width of the map image
    const width = draggable.current.target.clientWidth;
    // calculate the marker position base on the map image
    const markerX = Math.trunc((markerXPercentage / 100) * width);
    // get the distance from the marker point to the middle point
    const distanceX = Math.abs(markerX - xMiddlePoint);

    // get the direction of travel by checking if the marker is on the
    // left side or the right side of the middle point (marker > xMiddlePoint)
    const directionX =
      distanceX === 0 ? "none" : markerX > xMiddlePoint ? "left" : "right";

    // console.log("currentX", currentX);
    // console.log("markerX", markerX);
    // console.log("wrapperWidth", wrapperWidth);
    // console.log("xMiddlePoint", xMiddlePoint);
    // console.log("distanceX", distanceX);
    // console.log("directionX", directionX);
    // console.log("=======================");

    const x = directionX === "left" ? `-=${distanceX}` : `+=${distanceX}`;

    // =================
    // Y Coordinate
    // =================

    // get the current position of the image on the wrapper (transform: xx)
    const currentY = Math.trunc(Math.abs(draggable.current.y));
    // get the wrapper width (parent div)
    const wrapperHeight =
      draggable.current.target.parentNode.parentNode.clientHeight;
    // calculate the middle point of the wrapper
    const yMiddlePoint = currentY + wrapperHeight / 2;

    // width of the map image
    const height = draggable.current.target.clientHeight;
    // calculate the marker position base on the map image
    const markerY = Math.trunc((markerYPercentage / 100) * height);
    // get the distance from the marker point to the middle point
    const distanceY = Math.abs(markerY - yMiddlePoint);
    // get the direction of travel by checking if the marker is on the
    // left side or the right side of the middle point (marker > yMiddlePoint)
    const directionY =
      distanceY === 0 ? "none" : markerY > yMiddlePoint ? "top" : "bottom";

    const y = directionY === "top" ? `-=${distanceY}` : `+=${distanceY}`;

    // console.log("markerY", markerY);
    // console.log("currentY", currentY);
    // console.log("wrapperHeight", wrapperHeight);
    // console.log("yMiddlePoint", yMiddlePoint);
    // console.log("distanceY", distanceY);
    // console.log("directionY", directionY);
    // console.log("=======================");
    //
    TweenLite.to(map.current, 0.8, {
      x,
      y,
      ease: Power3.easeInOut,
      onUpdate: () => draggable.current.update(true)
    });

    // const timeline = new TimelineMax();
    //
    // timeline
    //   .to(map.current, 0.8, {
    //     x,
    //     y,
    //     scale: 1,
    //     ease: Power3.easeInOut,
    //     onUpdate: () => draggable.current.update(true)
    //   })
    //   .to(
    //     map.current,
    //     0.8,
    //     {
    //       scale: 1.3,
    //       ease: Power3.easeIn
    //       // onUpdate: () => draggable.current.update(true)
    //     },
    //     0.5
    //   );

    return () => {};
  }, [activeMarker]);
  useEffect(() => setSelected(firstLevel), [firstLevel]);
  useEffect(() => {
    if (zoom && map.current) {
      draggable.current = new Draggable(map.current, {
        type: "x,y",
        bounds: wrapper.current
      });
    }
  }, [zoom, selected]);

  useEffect(() => {
    if (zoom) setTimeout(() => centerActiveMarker(), 10);
  }, [zoom, activeMarker, activeMarker.id, centerActiveMarker, width]);


  return (
    <div className={classnames(className, styles.container)}>
      <div ref={wrapper} className={styles.wrapper}>
        <div ref={map} className={classnames(styles.map, 'markers-map')}>
          <img
            src={selected.image}
            alt={selected.name}
            style={{ width: zoom ? "initial" : "100%" }}
          />
          <img
            src="/img/amenities_zoom.png"
            alt="Zoom"
            className={classnames(styles.amenitieszoom)}
          />
          {/* <img src="./img/north_amenities.svg" alt="North" className={classnames(styles.north)} /> */}
          <div className={styles.markers}>
            <style>
              {`.markers-active-colour:before {
              border-color: ${selected.colour} !important;
            }`}
            </style>
            {markers.map((marker, i) => {
              if (!marker) return null;
              if (!marker.coordinates) return null;

              const active = activeMarker.id === marker.id;
              return (
                <button
                  key={`markers-${marker.id || i}`}
                  className={classnames(
                    { [styles.active]: active },
                    "markers-active-colour",
                    styles.marker
                  )}
                  style={{
                    left: `${marker.coordinates.x}%`,
                    top: `${marker.coordinates.y}%`,
                    backgroundColor: selected.colour,
                    color: selected.fontColour
                  }}
                  onClick={() => onMarkerClick(marker)}
                >
                  {i + 1}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarkersMap;
