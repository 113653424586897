import config from "traffic-property-app/config";

// Pages
import { PackageDetailsPage } from "./details";

export const Routes = [
  {
    path: config.routes.get("houseandland"),
    component: PackageDetailsPage
    // exact: true
  }
];

export * from "./details";
