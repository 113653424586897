import React from "react";
import config from "traffic-property-app/config";

// Pages
import { SearchPage } from "./search";

export const Routes = [
  {
    name: "Search",
    path: config.routes.get("property.search"),
    component: SearchPage,
    exact: true
  },
  {
    name: "Results",
    path: config.routes.get("property.results"),
    component: props => <SearchPage {...props} showResults />,
    exact: true
  },
  {
    name: "Results",
    path: config.routes.get("landforsale.results"),
    component: props => <SearchPage {...props} showResults onlyLots />,
    exact: true
  }
];

export * from "./search";
