import React from "react";
import classnames from "classnames";

import styles from "./Content.module.scss";

export const ContentHeader = ({ className, ...props }) => (
  <div className={classnames(className, styles.header)} {...props} />
);

export default ContentHeader;
