import React from "react";
// import classnames from "classnames";

// components
import withUserMenu from "traffic-property-app/components/UserMenu/UserMenu.store";

// components
import Package from "traffic-property-app/components/Package";

import styles from "./RecentlyViewed.module.scss";

export const RecentlyViewedComponent = ({ userMenu, onPackageClick }) => {
  const [state] = userMenu;
  const packages = state.recentlyViewed;

  if (!packages) return null;

  return (
    <div className="ta-container">
      <div className={styles.wrapper}>
        <div className="ta-row">
          {packages.map(pkg => (
            <Package
              key={pkg.slug}
              data={pkg}
              onClick={onPackageClick}
              className={styles.package}
              summary
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export const RecentlyViewed = withUserMenu(RecentlyViewedComponent);
export default RecentlyViewed;
