import React, { useRef, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { default as ReactIdleTimer } from "react-idle-timer";
import { parse } from "query-string";

export const IdleTimer = withRouter(
  ({ children, history, location, ...props }) => {
    const idleTimer = useRef();
    const query = parse(location.search);
    const { set_initial_screen } = query;

    const initial_screen =
      set_initial_screen || localStorage.getItem("initial_screen");

    const [initialPath] = useState(initial_screen || "/");
    const MINUTES_TO_RESET_KIOSK = 5;
    const resetToHome = () => {
      if (typeof window.client === "function") window.client("793fd22b");
      history.push(initialPath);
    };

    useEffect(() => {
      if (set_initial_screen) {
        localStorage.setItem("initial_screen", set_initial_screen);
      }
    }, [set_initial_screen]);

    useEffect(() => {
      if (location.path !== initial_screen) {
        idleTimer.current.reset();
      }
    }, [location.path, initial_screen]);

    return (
      <>
        <ReactIdleTimer
          ref={idleTimer}
          element={document}
          onIdle={resetToHome}
          debounce={250}
          timeout={MINUTES_TO_RESET_KIOSK * 60 * 1000}
          stopOnIdle
        />
        {children}
      </>
    );
  }
);

export default IdleTimer;
