import React, { createContext, useState, useEffect } from "react";
import ReactDOM from "react-dom";

import config from "traffic-property-app/config";

// Components
// import Button from "traffic-property-app/components/Button";
import Icon from "traffic-property-app/components/Icon";

import "./EnquiryForm.scss";

export const EnquiryFormContext = createContext();

const EnquiryFormModal = ({ pkg, toggle }) => {
  if (pkg) {
    const { name, url } = pkg;
    const id = pkg.id || pkg.lot_id;
    const refUrl = window.location.origin + url;

    const tfaIDs = config.enquiryForm.get("tfa_ids") || [];
    const differentID = tfaIDs.find(id => {
      const [neighbourhoodReleaseOrProject] = id.split("$");

      if (
        pkg.slug.indexOf(neighbourhoodReleaseOrProject) > -1 ||
        pkg.neighbourhood_slug === neighbourhoodReleaseOrProject ||
        pkg.project_slug === neighbourhoodReleaseOrProject ||
        pkg.release_slug === neighbourhoodReleaseOrProject
      )
        return true;
      return false;
    });

    let tfaID = config.enquiryForm.get("tfa_id");
    if (differentID) tfaID = differentID.split("$")[1];

    return ReactDOM.createPortal(
      <div className="enquiryform">
        <div className="container">
          <div className="ta-row">
            <div className="enquiryform--content">
              <Icon onClick={toggle} icon="close_black" className="close" />
              <h3>Register your interest</h3>
              <h2>
                Find out more information on
                <span>{name}</span>
              </h2>

              {/* <form>
                <div className="ta-row">
                  <div className="enquiryform--field">
                    <label>First Name</label>
                    <input type="text" />
                  </div>
                  <div className="enquiryform--field">
                    <label>Last Name</label>
                    <input type="text" />
                  </div>
                </div>

                <div className="ta-row">
                  <div className="enquiryform--field">
                    <label>Email Address</label>
                    <input type="email" />
                  </div>
                  <div className="enquiryform--field">
                    <label>Contact Number</label>
                    <input type="phone" />
                  </div>
                </div>

                <div className="ta-row">
                  <div className="enquiryform--field">
                    <label>Postcode</label>
                    <input type="text" />
                  </div>
                </div>

                <div className="ta-row">
                  <div className="enquiryform--field checkbox">
                    <input type="checkbox" />
                    <label>
                      I have read and accept the{" "}
                      <a href={config.enquiryForm.get("privacy_policy")}>
                        Privacy Policy
                      </a>{" "}
                      and{" "}
                      <a href={config.enquiryForm.get("terms_and_conditions")}>
                        Terms of Use
                      </a>
                    </label>
                  </div>
                </div>

                <div className="ta-row">
                  <div className="enquiryform--field button">
                    <Button color="primary" size="medium">
                      Register
                    </Button>
                  </div>
                </div>
              </form> */}

              <iframe
                src={`https://tfaforms.com/${tfaID}?tfa_21=${refUrl}&tfa_55=${id}`}
                height="450"
                width="100%"
                frameBorder="0"
                title="enquiry"
              />
              <script src="//tfaforms.com/js/iframe_resize_helper.js" />
            </div>
          </div>
        </div>
      </div>,
      document.body
    );
  }

  return null;
};

export const EnquiryForm = ({ children }) => {
  const [info, setInfo] = useState();
  const toggle = pkg => setInfo(!info ? pkg : null);

  useEffect(
    () => {
      if (info && !document.body.classList.contains("ta-overflow")) {
        document.body.classList.toggle("ta-overflow");
      } else if (!info && document.body.classList.contains("ta-overflow")) {
        document.body.classList.toggle("ta-overflow");
      }
    },
    [info]
  );

  return (
    <EnquiryFormContext.Provider value={toggle}>
      <>
        {children}
        <EnquiryFormModal pkg={info} toggle={toggle} />
      </>
    </EnquiryFormContext.Provider>
  );
};

export default EnquiryForm;
