import { queryBuilder } from "../utils";
// import { generateBuilderSlug } from "../utils";

export const initLots = api => {
  let lots = [];
  const data = api.current || {};
  const { landforsale } = data || {};

  const {
    estate,
    neighbourhood,
    landSize,
    frontage,
    release,
    otherOptions
  } = api.filters;
  const filters = {
    estate,
    neighbourhood,
    landSize,
    frontage,
    release,
    otherOptions
  };

  // API NAME is different for lots
  landSize.apiName = "area";
  frontage.apiName = "frontage";

  if (landforsale) {
    landforsale.map(release => {
      // release.lots = release.lots.map(lot => {
      //   // pkg.neighbourhood_id = pkg.precinct_id;
      //   // pkg.neighbourhood_name = neighbourhoodName;
      //   // pkg.neighbourhood_slug = neighbourhoodSlug;
      //   // delete pkg.precinct_id;
      //   // delete pkg.precinct_name;
      //   //
      //   // pkg.project_name = projectName;
      //   // pkg.project_slug = projectSlug;
      //
      //   // path: `${masterplan}/:neighbourhoodOrRelease?/:releaseOrLot?/:lotOrLotID?/:lotID?`,
      //   // lot.release
      //
      //   return lot;
      // });

      lots = [...lots, ...release.lots];

      return release;
    });
  }

  return {
    list: options => {
      let { sort, ...query } = options || {};
      if (!sort) sort = "frontage-desc";

      if (lots.length) {
        return queryBuilder(lots, { ...query, filters, sort });
      }

      return lots;
    }
  };
};

export default initLots;
