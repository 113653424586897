import React, { useRef, useEffect, useState} from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { parse } from "qs";
import { ContentLeftBar } from "layouts/MainLayout";

import useScrollEngine from "utils/useScrollEngine";
import config from "config";

import styles from "pages/explore/townhomes/map.module.scss";
import Icon from "traffic-property-app/components/Icon";


export const addScrollEngine = Component => props => {
  const [currentY, setCurrentY] = useState(0);
  const resultsRef = useRef(null);
  const [, resultsStyle] = useScrollEngine(resultsRef, {}, currentY);
  const { type } = parse(props.location.search.replace("?", ""));
  const isHouse = type === "house";
  const isTownhome = type === "townhome";
  const isLandForSale = !isHouse && !isTownhome;

  const title = isHouse
    ? "House & Land"
    : isTownhome
    ? "Townhomes"
    : "Land for Sale";

  // coords
  let selectBuilders = [];
  let selectOptions = [];
  let sienna = document.createElement("option");
  let burbank = document.createElement("option");

  useEffect(() => {
    selectBuilders = document.querySelector(".ta-builder select"); // eslint-disable-line
    if (selectBuilders) {
      selectOptions = selectBuilders.options; // eslint-disable-line

      sienna.value = "sienna_homes";
      sienna.innerHTML = "Sienna Homes";
      burbank.value = "burbank_urban";
      burbank.innerHTML = "Burbank Urban";

      if (isTownhome) {
        for (let i = 0; i < selectOptions.length; i++) {
          if (
            selectOptions[i].label.indexOf("Search") === -1 &&
            selectOptions[i].label.indexOf("Sienna") === -1
          ) {
            selectOptions[i].remove();
            i--;
          }
        }
        selectBuilders.appendChild(sienna);
        selectBuilders.appendChild(burbank);
      }
    }
  }, []);  

  return (
    <>
      {isTownhome ? (
        <ContentLeftBar style={{ left: 0 }}>
          <Link
            to={config.routes.get("property.results", "?type=townhome")}
            className={styles.listview}
          >
            <Icon icon="listviewActive" />
          </Link>
          <Link to={config.routes.get("townhomes")} className={styles.mapview}>
            <Icon icon="mapview" />
          </Link>
        </ContentLeftBar>
      ) : null}
      <style>
        {`.ta-has-results .ta-filter--heading h2:after {
            content: '${title}';
          }
          .ta-filter--extra .ta-filter--extra__submit .ta-btn {
            color: transparent;
          }
          .ta-filter--extra .ta-filter--extra__submit .ta-btn:after {
            content: 'Search ${title}';
            color: initial;
            position: absolute;
            left: 0;
            right: 0;
          }
          ${
            isLandForSale
              ? `
                .ta-has-results .package--content { height: 130px; }
                .ta-has-results .results--count { top: 35px; }
                `
              : ""
          }
          ${
            isHouse ? `
              .ta-has-results .results--count { top: 35px; }
            `
            : ""
          }
          `}
      </style>

      <div className={classnames(styles.arrowContainer)}>
        <div 
        onClick={() => { 
          const element = resultsRef.current;
          const yBoundary = element.parentNode.offsetHeight || window.innerHeight;
          const maximumY = Math.max(0, element.scrollHeight - yBoundary);
          if((currentY * -1) < (maximumY-100) && currentY <= 0 ) {
            setCurrentY(currentY-100)
          }
        }} 
        className={classnames(styles.arrowUp)}>
        </div>
        <div 
        onClick={() => { currentY < 0 ? setCurrentY(currentY+100) :setCurrentY(0) }} 
        className={classnames(styles.arrowDown)}
        ></div>
      </div>

      <Component
        {...props}
        defaultQuery={{ type }}
        resultsRef={resultsRef}
        resultsStyle={resultsStyle}
      />
    </>
  );
};

export default addScrollEngine;
