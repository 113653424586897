import React from "react";
import classnames from "classnames";

import { useApi } from "traffic-property-app/api";
import config from "traffic-property-app/config";

import { qs, range, arrayToInt } from "traffic-property-app/utils";

// Components
import BackButton from "traffic-property-app/components/BackButton";
import PackageSlider from "traffic-property-app/components/PackageSlider";
import { RecentSearches } from "traffic-property-app/components/UserMenu";
import Advertising from "traffic-property-app/components/Advertising";

//
// Search Components
//
import Results from "./components/Results";
import Filters from "./components/Filters";

import styles from "./search.module.scss";

export const SearchPage = ({
  location,
  showResults,
  onlyLots,
  resultsRef,
  resultsStyle,
  onlyFiltersOnTheLeft,
  defaultQuery = {}
}) => {
  const api = useApi();
  const query = showResults
    ? { ...defaultQuery, ...qs.parse(location.search) }
    : defaultQuery;

  const otherOptions =
    typeof query.other_options === "object"
      ? arrayToInt.parse(query.other_options)
      : query.other_options
      ? [parseInt(query.other_options)]
      : undefined;

  const statusOptions =
    typeof query.featured_options === "object"
      ? query.featured_options
      : query.featured_options
      ? [query.featured_options]
      : undefined;

  const propertyToCompare =
    !onlyLots && query.similar_properties
      ? api.packages.retrieve(query.similar_properties)
      : null;

  if (propertyToCompare) {
    // query.propertyType = propertyToCompare.propertyType;
    query.price = propertyToCompare.price;
    query.bedrooms = propertyToCompare.bedrooms;
    query.bathrooms = propertyToCompare.bathrooms;
    query.parking = propertyToCompare.garage;
    // query.land_size = propertyToCompare.lot_area;
    // query.frontage = propertyToCompare.lot_frontage;
  }

  // @TODO find a better way to stringify and parse query values
  // it should be all in one single file.
  const options = onlyLots
    ? {
        estate:
          typeof query.estate === "string" ? [query.estate] : query.estate,
        neighbourhood:
          typeof query.neighbourhood === "string"
            ? [query.neighbourhood]
            : query.neighbourhood,
        landSize: range.parse(query.land_size),
        frontage: range.parse(query.frontage),
        release: query.release,
        otherOptions,
        statusOptions,

        // sort option
        sort: query.sort || "name"
      }
    : {
        estate:
          typeof query.estate === "string" ? [query.estate] : query.estate,
        neighbourhood:
          typeof query.neighbourhood === "string"
            ? [query.neighbourhood]
            : query.neighbourhood,
        propertyType:
          typeof query.type === "string" ? [query.type] : query.type,
        price: range.parse(query.price),
        bedrooms: range.parse(query.bedrooms),
        bathrooms: range.parse(query.bathrooms),
        parking: range.parse(query.parking),
        stories: range.parse(query.stories),
        landSize: range.parse(query.land_size),
        frontage: range.parse(query.frontage),
        builder: query.builder,
        otherOptions,
        statusOptions,

        // sort option
        sort: query.sort
      };

  if (propertyToCompare) options.propertyToCompare = propertyToCompare;

  const packages = onlyLots
    ? api.lots.list(options)
    : api.packages.list(options);

  const featuredPackages = packages.filter(
    ({ flags }) => flags && flags.featured
  );

  const { landforsale = [] } = api.current || {};

  const className = classnames({ "ta-has-results": showResults });

  const { current } = api;
  const showBackButton =
    (current.townhomes && current.townhomes.length) ||
    (current.apartments && current.apartments.length) ||
    (current.packages && current.packages.length);

  return (
    <>
      <div className={`ta-container ` + className}>
        {showBackButton && showResults && !onlyFiltersOnTheLeft ? (
          <BackButton
            title="search"
            to={config.routes.get("property.search")}
            unless={config.routes.get("landforsale.masterplan")}
          />
        ) : null}
        <div className="ta-row">
          <Filters
            filters={api.filters}
            defaultValues={options}
            onlyLotsFilters={onlyLots}
            isResultPage={showResults}
            containsLand={landforsale.length}
          />
          {showResults && !onlyFiltersOnTheLeft ? (
            <Results
              ref={resultsRef}
              packages={packages}
              onlyLotsResults={onlyLots}
              style={resultsStyle}
            />
          ) : null}
        </div>
        {!showResults ? (
          <>
            <h1 className={styles.title}>Recent Searches</h1>
            <RecentSearches />
          </>
        ) : null}
        {!showResults ? (
          <Advertising
            className={styles.ad}
            query={[{ type: "route", value: "property.search" }]}
            height={180}
          />
        ) : null}
      </div>
      {!showResults ? (
        <PackageSlider
          title="Featured properties"
          packages={featuredPackages}
        />
      ) : null}
    </>
  );
};

export default SearchPage;
