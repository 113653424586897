import React, { useState } from 'react';
import classnames from 'classnames';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';

// config
import config from 'config';

// components
import Logo from 'components/Logo';
import Navbar from 'components/Navbar';

// images
import background from './images/background.jpg';

import styles from './splash.module.scss';

const Splash = () => {
  const menu = config.sidebar;
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.background}
        style={{ backgroundImage: `url(${background})` }}
      />
      <Container className={styles.content}>
        <Logo className={classnames(styles.logo)} size='xl' layout="landscape" />
       <div className={styles.headerWrapper}>
          <h1 className={classnames(styles.header)}>
            <span className={'bold'}>
              LIVE WITH
              <br /> NATURE
            </span>
            <br /> AT SYDNEY’S
            <br /> GATEWAY
          </h1>
          {/* <p className="h2">
          A range of options ensures you'll find what you're looking forl.
        </p> */}
       </div>
        <div className={classnames(styles.bottom, 'text-right')}>
          {config.isTouchTable ? (
            <Link to='/masterplan'>
              <button 
              className={classnames('ta-btn ta-btn__large ta-btn__primary', styles.splashBttn)}
              >
                Start
              </button>
            </Link>
          ) : (
            <button
              onClick={toggle}
              className={classnames('ta-btn ta-btn__large ta-btn__primary', styles.splashBttn)}
            >
              Start
            </button>
          )}
        </div>
        <div className={styles.shape}></div>
      </Container>
      <Navbar show={show} toggle={toggle} menu={menu} splash={true} animated />
    </div>
  );
};

export default Splash;
