import React, { useState } from 'react';
import classnames from 'classnames';
import config from 'config';
import { Link } from 'react-router-dom';

// components
import Map from './Map';

import { Sidebar } from 'layouts/MainLayout';

import styles from './masterplan.module.scss';

import youarehere from 'assets/images/masterplan/you-are-here.svg';
import masterplanNorth from 'assets/images/masterplan/masterplan-north.svg';
// import overview from 'assets/images/masterplan/overview.svg';
import camera from 'assets/images/masterplan/camera.svg';
import goBack from 'assets/images/masterplan/goback.svg';
import melbcbd from 'assets/images/masterplan/Arrow_MelbourneCBD.svg';
import cranbourne from 'assets/images/masterplan/Arrow_Cranbourne.svg';

import backgroundImage from 'assets/images/masterplan/wilton/masterplan.png';
// import townCentre from "assets/images/masterplan/town-centre.svg";
// import communityIndoor from "assets/images/masterplan/community_indoor.svg";
// import communityChildcare from "assets/images/masterplan/community_childcare.svg";
// import parks from "assets/images/masterplan/parks/elmpark.svg";
// import oak from "assets/images/masterplan/parks/oakpark.svg";
// import wetlands from "assets/images/masterplan/parks/wetlands.svg";
// import education from "assets/images/masterplan/education.svg";
// import trainStation from "assets/images/masterplan/train-station.svg";
// import residentialEastQuarter from "assets/images/masterplan/residential/east-quarter.svg";
// import residentialSold from "assets/images/masterplan/residential/sold.svg";
// import residentialNowSelling from "assets/images/masterplan/residential/now-selling.svg";

import school from 'assets/images/masterplan/wilton/school.png';
import environment from 'assets/images/masterplan/wilton/environment.png';
import future from 'assets/images/masterplan/wilton/future.png';
import sports from 'assets/images/masterplan/wilton/sports.png';
import parks from 'assets/images/masterplan/wilton/parks.png';
import residential from 'assets/images/masterplan/wilton/residential.png';
import retail from 'assets/images/masterplan/wilton/retail.png';
import employment from 'assets/images/masterplan/wilton/employment.png';

import schoolIcon from 'assets/images/masterplan/wilton/icons/School.svg';
import environmentIcon from 'assets/images/masterplan/wilton/icons/Nature.svg';
import futureIcon from 'assets/images/masterplan/wilton/icons/DisplayGallery.svg';
import sportsIcon from 'assets/images/masterplan/wilton/icons/Sport.svg';
import parksIcon from 'assets/images/masterplan/wilton/icons/ParksGardens.svg';
import residentialIcon from 'assets/images/masterplan/wilton/icons/residential.svg';
import retailIcon from 'assets/images/masterplan/wilton/icons/Shopping.svg';
import employmentIcon from 'assets/images/masterplan/wilton/icons/Enterprise.svg';

const assets = {
  pinpoint: youarehere,
  masterplanNorth: masterplanNorth,
  camera: camera,
  melbcbd: melbcbd,
  cranbourne: cranbourne,
};

const { isTouchTable } = config;

const levels = [
  // {
  //   title: 'adasd ',
  //   zoom: ['1', '0', '0'],
  //   markers: [
  //     {
  //       backgroundImage: overview,
  //     },
  //   ],
  // },
  {
    color: '#E8999E',
    name: 'Future Sales Gallery',
    icon: futureIcon,
    zoom: isTouchTable ? ['1.3', '0', '245px'] : ['1', '0', '0'],
    markers: [
      {
        backgroundImage: future,
      },
    ],
  },
  {
    color: '#E8999E',
    name: 'Residential',
    icon: residentialIcon,
    zoom: isTouchTable ? ['1.3', '0', '245px'] : ['1', '0', '0'],
    markers: [
      {
        backgroundImage: residential,
      },
    ],
  },
  {
    color: '#E8999E',
    name: 'Environmental Conservation Area',
    icon: environmentIcon,
    zoom: isTouchTable ? ['1.3', '0', '245px'] : ['1', '0', '0'],
    markers: [
      {
        backgroundImage: environment,
      },
    ],
  },
  {
    color: '#E8999E',
    name: 'Parks and Recreation',
    icon: parksIcon,
    zoom: isTouchTable ? ['1.3', '0', '245px'] : ['1', '0', '0'],
    markers: [
      {
        backgroundImage: parks,
      },
    ],
  },
  {
    color: '#E8999E',
    name: 'Proposed Primary School',
    icon: schoolIcon,
    zoom: isTouchTable ? ['1.3', '0', '245px'] : ['1', '0', '0'],
    markers: [
      {
        backgroundImage: school,
      },
    ],
  },
  {
    color: '#E8999E',
    name: 'Sports Facilities and Ovals',
    icon: sportsIcon,
    zoom: isTouchTable ? ['1.3', '0', '245px'] : ['1', '0', '0'],
    markers: [
      {
        backgroundImage: sports,
      },
    ],
  },
  {
    color: '#E8999E',
    name: 'Retail and Medical',
    icon: retailIcon,
    zoom: isTouchTable ? ['1.3', '0', '245px'] : ['1', '0', '0'],
    markers: [
      {
        backgroundImage: retail,
      },
    ],
  },
  {
    color: '#E8999E',
    name: 'Employment Opportunities',
    icon: employmentIcon,
    zoom: isTouchTable ? ['1.3', '0', '245px'] : ['1', '0', '0'],
    markers: [
      {
        backgroundImage: employment,
      },
    ],
  },
];

export const Masterplan = () => {
  const [activeLevel, setActiveLevel] = useState(0);
  const selectItem = (i) => () => setActiveLevel(i);
  const amenitiesBgImage = `url("${config.get('style.icons.amenities')}")`;

  const { isTouchTable } = config;

  return (
    <div className={classnames(styles.wrapper, 'masterplan--wrapper')}>
      <Map
        levels={levels}
        activeLevel={activeLevel}
        className={styles.map}
        baseMap={backgroundImage}
        assets={assets}
      />

      <Sidebar className={styles.sidebar} draggable>
        <div className={classnames(styles.title, 'masterplan--title')}>
          {isTouchTable ? ' Menu' : 'Masterplan'}
          {activeLevel !== 0 && (
            <img
              src={goBack}
              alt='go back'
              className={styles.reset}
              onClick={selectItem(0)}
            />
          )}
        </div>
        <h4 className={classnames(styles.subtitle, 'masterplan--sub-title')}>
          Notable lifestyle amenities include a major town centre, future train
          station and beautifully landscaped open spaces.
        </h4>
        <ul className={classnames(styles.listgroup, 'masterplan--listgroup')}>
          {levels.map(({ name, color, icon }, i) => {
            // console.log('icon', icon);
            const isActive = i === activeLevel;

            if (!name) return null;

            return (
              <li
                key={`levels-${i}`}
                className={classnames(styles.item, {
                  [styles.active]: isActive,
                })}
                onClick={selectItem(i)}
              >
                <div
                  className={styles.color}
                  style={
                    icon !== null
                      ? { backgroundImage: `url(${icon})` }
                      : { backgroundColor: '#000' }
                  }
                />
                {name}
              </li>
            );
          })}
        </ul>
      </Sidebar>

      {isTouchTable ? (
        <Sidebar
          className={classnames(styles.sidebar, styles.sidebar_footer)}
          draggable
        >
          <div className={styles.title}>Greater Area</div>
          <ul className={styles.listgroup}>
            <li
              key='levels-amenities'
              className={classnames(styles.item, styles.amenitieslink)}
            >
              <Link to='/amenities'>
                <div
                  className={styles.color}
                  style={{
                    backgroundImage: amenitiesBgImage,
                    backgroundColor: 'unset',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                  }}
                />
                Amenities
              </Link>
            </li>
          </ul>
        </Sidebar>
      ) : null}
    </div>
  );
};

export default Masterplan;
