import React from "react";
import Icon from "traffic-property-app/components/Icon";

import "./SocialSharing.scss";

export const SocialSharing = ({ ...props }) => {
  return (
    <div className="socialsharing">
      <Icon
        tag="a"
        icon="email"
        href={
          "mailto:?subject=Check out this property/lot!&body=" +
          window.location.href
        }
        className="email"
      />
      <Icon
        tag="a"
        icon="facebook"
        href={
          "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href
        }
        className="facebook"
        external
      />
    </div>
  );
};

export default SocialSharing;
