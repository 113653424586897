import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from 'react-router-dom';

// Initial setup
import config from 'traffic-property-app/config';
import { Api } from 'traffic-property-app/api';

// Components
import { EnquiryForm } from 'traffic-property-app/components/EnquiryForm';
import { RouterHistory } from 'traffic-property-app/components/BackButton';
import { UserMenuProvider } from 'traffic-property-app/components/UserMenu';

// Routes
import { Routes as SearchRoutes } from 'pages/explore/search';
import { Routes as ApartmentsRoutes } from 'traffic-property-app/pages/explore/apartments';
import { Routes as LandForSaleRoutes } from 'pages/explore/land-for-sale';
import { Routes as PackageRoutes } from 'pages/explore/package';
import { Routes as TownhomesRoutes } from 'pages/explore/townhomes';
import Videos from 'pages/explore/videos';
import Masterplan from 'pages/explore/masterplan';
import Amenities from 'pages/explore/amenities';
import PageNotFound from 'traffic-property-app/pages/errors/404';
import Splash from './pages/splash';

import MainLayout from 'layouts/MainLayout';

import IdleTimer from './IdleTimer';

// styles
import 'traffic-property-app/App.scss';
import 'assets/scss/app.scss';

const ScrollToTop = withRouter(
  ({ children, location: { pathname, search } }) => {
    useEffect(() => {
      // setOldLocation(window.location.pathname);
      // const houseandland = config.routes.get("houseandland");
      // const isHouseandland =
      //   oldLocation.indexOf(houseandland) > -1 &&
      //   pathname.indexOf(houseandland) > -1;
      // const oldPath = oldLocation.substr(0, oldLocation.lastIndexOf("/") + 1);
      // const newPath = pathname.substr(0, oldLocation.lastIndexOf("/") + 1);
      //

      if (window.location.pathname.indexOf(config.routes.apartments) === -1) {
        window.scrollTo(0, 0);
      }
    }, [pathname, search]);

    return children || null;
  },
);

const isDevelopmentCompany =
  !config.api.get('project') && !config.api.get('neighbourhood');

const App = () => {
  let routes = [...SearchRoutes, ...PackageRoutes, ...LandForSaleRoutes];

  if (!isDevelopmentCompany) {
    routes = [...routes, ...ApartmentsRoutes, ...TownhomesRoutes];
  }

  const { isTouchTable } = config;
  // console.log(window.location.pathname);

  // http://kiosk2.eliston.com.au/

  return (
    <>
      {process.env.NODE_ENV === 'production' ? (
        <style>
          {`
              html, body {
                cursor: none;
              }

              body a {
                cursor: none;
              }
              `}
        </style>
      ) : null}
      <Router>
        <RouterHistory routes={routes}>
          <IdleTimer />
          <UserMenuProvider>
            <EnquiryForm>
              <Api url={config.get('api.url')}>
                <ScrollToTop>
                  <Switch>
                    {isTouchTable ? (
                      <>
                        <Route path='/' component={Splash} exact />
                        <Route
                          path='/amenities'
                          component={MainLayout(Amenities)}
                          exact
                        />
                        <Route component={MainLayout(Masterplan)} exact />
                      </>
                    ) : (
                      <>
                        <Route path='/' component={Splash} exact />
                        <Route
                          path='/masterplan'
                          component={MainLayout(Masterplan)}
                          exact
                        />
                        <Route
                          path='/amenities'
                          component={MainLayout(Amenities)}
                          exact
                        />
                        <Route
                          path='/videos'
                          component={MainLayout(Videos)}
                          exact
                        />
                        {routes.map(({ name, component, ...props }, i) => (
                          <Route
                            key={i}
                            component={MainLayout(component)}
                            {...props}
                          />
                        ))}
                      </>
                    )}

                    <PageNotFound />
                  </Switch>
                </ScrollToTop>
              </Api>
            </EnquiryForm>
          </UserMenuProvider>
        </RouterHistory>
      </Router>
    </>
  );
};

export default App;
