import React, { forwardRef, useEffect } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import { qs } from "traffic-property-app/utils";

// Components
import Package from "traffic-property-app/components/Package";
import { saveRecentSearch } from "traffic-property-app/components/UserMenu";
import Advertising from "traffic-property-app/components/Advertising";

import "./Results.scss";

export const Results = forwardRef(
  ({ sortBy, onlyLotsResults, style, ...props }, ref) => {
    const packages = props.packages || [];

    const path = window.location.pathname;
    const query = qs.parse(window.location.search);
    const { sort, similar_properties: similarProperties } = query;

    useEffect(() => {
      if (!similarProperties) {
        const queriesRecentSearch = { ...query };
        delete queriesRecentSearch.sort;
        const queriesRecentSearchString = qs.stringify(queriesRecentSearch);
        saveRecentSearch(queriesRecentSearchString);
      }
    }, [query, similarProperties]);

    // @NOTE for featuredPriceParams since featured is the default
    // by making sort undefined (removing it from the url)
    // it will work out of the box
    const featuredPriceParams = qs.stringify({ ...query, sort: undefined });
    const lowestPriceParams = qs.stringify({ ...query, sort: "price" });
    const highestPriceParams = qs.stringify({ ...query, sort: "price-desc" });

    const highestFrontageParams = qs.stringify({ ...query, sort: undefined });
    const lowestFrontageParams = qs.stringify({
      ...query,
      sort: "frontage"
    });
    const lowestLandsizeParams = qs.stringify({ ...query, sort: "area" });
    const highestLandsizeParams = qs.stringify({
      ...query,
      sort: "area-desc"
    });

    let adIndex = -1;

    return (
      <>
        <div className="results--count" style={{ display: `none` }}>
          {packages.length} <span>results</span>
        </div>
        <section ref={ref} className="results" style={style}>
          <div className="ta-row">
            {packages.length ? (
              <>
                <div className="results--sorting">
                  <h4>Sort by</h4>
                  {onlyLotsResults ? (
                    <>
                      <Link
                        to={`${path}?${highestFrontageParams}`}
                        className={classnames({ active: !sort })}
                      >
                        Frontage High
                      </Link>
                      <Link
                        to={`${path}?${lowestFrontageParams}`}
                        className={classnames({ active: sort === "frontage" })}
                      >
                        Frontage Low
                      </Link>
                      <Link
                        to={`${path}?${highestLandsizeParams}`}
                        className={classnames({ active: sort === "area-desc" })}
                      >
                        Land Size High
                      </Link>
                      <Link
                        to={`${path}?${lowestLandsizeParams}`}
                        className={classnames({ active: sort === "area" })}
                      >
                        Land Size Low
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link
                        to={`${path}?${featuredPriceParams}`}
                        className={classnames({ active: !sort })}
                      >
                        Featured
                      </Link>
                      <Link
                        to={`${path}?${lowestPriceParams}`}
                        className={classnames({ active: sort === "price" })}
                      >
                        Lowest Price
                      </Link>
                      <Link
                        to={`${path}?${highestPriceParams}`}
                        className={classnames({
                          active: sort === "price-desc"
                        })}
                      >
                        Highest Price
                      </Link>
                    </>
                  )}
                </div>
                <div>
                  {packages.map((pkg, i) => {
                    if (i === 6 || (i > 9 && (i + 3) % 9 === 0)) {
                      adIndex++;
                      const key = pkg.slug + i;
                      return [
                        <Advertising
                          key={`${key}-ad`}
                          className="package ad"
                          query={[
                            {
                              type: "route",
                              value: onlyLotsResults
                                ? "landforsale.results"
                                : "property.results"
                            },
                            { type: "route", value: "results" }
                          ]}
                          height={120}
                          index={adIndex}
                        />,
                        <Package key={key} package={pkg} />
                      ];
                    }

                    return <Package key={pkg.slug + i} package={pkg} />;
                  })}
                </div>
              </>
            ) : (
              <>
                <div className="results--sorting no-results">
                  <h4 className="no-results">No results</h4>
                </div>
              </>
            )}
          </div>
        </section>
      </>
    );
  }
);

export default Results;
