import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Checkbox from "../Checkbox";

import "./CheckList.scss";

export const CheckList = ({
  name,
  label = "",
  options = [],
  value: valueProp,
  color,
  showSelectAll,
  allSelectedByDefault,
  hideCheckIcon,
  ...props
}) => {
  const initialRender = useRef();
  const [initialState, setInitialState] = useState([]);
  const [isSelectedAll, setSelectedAll] = useState([]);

  useEffect(
    () => {
      if (!initialRender.current && allSelectedByDefault) {
        const allOptions = options.map(({ value }) => value);
        setInitialState(allOptions);

        if (allSelectedByDefault) {
          setSelectedAll(allOptions);
        }

        initialRender.current = true;
      }
    },
    [allSelectedByDefault, options]
  );

  const state = Object.assign(valueProp || initialState, []);

  if (!name) name = label.toLowerCase();

  const onChange = (value, e) => {
    let newState = [];
    const { checked } = e.target;

    if (initialState.length === state.length) {
      newState = [value];
    } else {
      newState = Object.assign(state, []);
      const index = state.indexOf(value);
      const exists = index > -1;

      if (checked && !exists) newState.push(value);
      else if (!checked && exists) newState.splice(index, 1);
    }

    setSelectedAll(newState.length === initialState.length);

    if (typeof props.onChange === "function") {
      props.onChange({ name, value: newState }, e);
    }
  };

  const className = classnames(props.className, "checklist", {
    [`checklist__${color}`]: color
  });

  const selectAll = e => {
    const { checked } = e.target;
    const newState = checked ? initialState : [];

    setSelectedAll(checked);
    if (typeof props.onChange === "function") {
      props.onChange({ name, value: newState }, e);
    }
  };

  return (
    <div className={className}>
      {showSelectAll ? (
        <Checkbox
          id={`${name}-all`}
          onChange={selectAll}
          checked={isSelectedAll}
          className="select-all"
          label="all"
        />
      ) : null}
      {options.map(({ label, value, icon, image, color }) => {
        const isChecked = state.indexOf(value) > -1;
        const key = value;
        if (!icon && !image && !hideCheckIcon) {
          icon = isChecked ? "check" : "minus";
        }

        return (
          <Checkbox
            key={key}
            id={`ts-checkbox-${name}-${key}`}
            name={key}
            onChange={e => onChange(value, e)}
            checked={isChecked}
            label={label}
            icon={icon}
            image={image}
            imageStyle={{ background: color }}
          />
        );
      })}
    </div>
  );
};

CheckList.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary"]),
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      icon: PropTypes.string
    })
  ).isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string)
  ]),
  allSelectedByDefault: PropTypes.bool
};

export const UncontrolledCheckList = props => {
  const [state, setState] = useState();
  const onChange = ({ value }) => setState(value);
  return <CheckList {...props} value={state} onChange={onChange} />;
};

export default CheckList;
