import rawHtml from "./edm.raw.html";

// const url = window.location.origin;
// for dev purposes
const url = "http://kiosk-app.surge.sh";

function escapeRegExp(string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
}

const defaultVariables = [["images/", `${url}/edm/images/`]];

export const generateEdm = (variables = []) => {
  let html = rawHtml;

  variables = [...defaultVariables, ...(variables || [])];
  variables.forEach(v => {
    if (v.length) {
      const [variable, value] = v;
      html = replaceAll(html, variable, value);
    }
  });

  return {
    html: html,
    text: "Example text content"
  };
};

export default generateEdm;
