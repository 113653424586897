import { parse, stringify } from "query-string";
import numeral from "numeral";

export const lowercaseAndParseSpaces = (name, replaceWith) => {
  return name
    .toLowerCase()
    .split(" ")
    .join(replaceWith || "_");
};

const defaultOptions = { arrayFormat: "comma", parseNumbers: true };

export const qs = {
  parse: (value, options) => {
    const parsedValue = parse(value, { ...(options || {}), ...defaultOptions });
    return parsedValue;
  },
  stringify: (value, options) =>
    stringify(value, { ...(options || {}), ...defaultOptions })
};

export const range = {
  parse: value => {
    if (value) {
      value = value.toString();

      const range = value.split("-");
      if (range.length)
        return range.map(number => (number ? parseInt(number) : number));
    }

    return value;
  },
  stringify: value => (value && value.length > 1 ? value.join("-") : value)
};

export const arrayToInt = {
  parse: array => {
    if (Array.isArray(array))
      return array.map(number => (number ? parseInt(number) : number));
    return array;
  }
};

export const formatPrice = (price, options) => {
  const { million = "($0.00a)", other = "($0,00)" } = options || {};
  const format = price >= 1000000 ? million : other;
  return price ? numeral(price).format(format) : price || "-";
};
