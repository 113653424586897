import React from "react";
import classnames from "classnames";
import { formatPrice } from "traffic-property-app/utils";

import styles from "./Price.module.scss";

export const Price = ({ number, promoted, className }) => {
  const price = formatPrice(number);

  return (
    <span className={classnames(className, styles.container)}>
      <span className={styles.from}>From</span>
      <span className={styles.number}>
        {price}
        {promoted ? "*" : ""}
      </span>
    </span>
  );
};

export default Price;
