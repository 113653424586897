import React from "react";
import classnames from "classnames";

import styles from "./Content.module.scss";

export const ContentFooter = ({ className, ...props }) => (
  <div className={classnames(className, styles.footer)} {...props} />
);

export default ContentFooter;
