import React, { useState, useRef } from "react";
import classnames from "classnames";
import useAxios from "axios-hooks";
import Keyboard from "react-simple-keyboard";
import { useForm } from "react-hook-form";
import Icon from "traffic-property-app/components/Icon";

import { generateEdm } from "./edm";

import styles from "./EmailForm.module.scss";

const EmailForm = ({
  package: pkg,
  show: showForm,
  toggleForm = () => null
}) => {
  const keyboard = useRef();
  const [activeInput, setActiveInput] = useState("first_name");
  const { register, handleSubmit, errors: formErrors, setValue } = useForm();
  const [{ loading, data, error }, refetch] = useAxios(
    {
      method: "POST",
      url: "https://mandrillapp.com/api/1.0/messages/send.json"
    },
    { manual: true }
  );

  const onSubmit = form => {
    // @NOTE for development purposes
    if (process.env.NODE_ENV === "development") {
      form.first_name = "Matt";
      form.last_name = "Smith";
      form.email = "digital@traffic.com.au";
      form.phone_number = "1234567890";
      form.postcode = "1234";
    }

    const name = pkg.lot_name
      ? `${pkg.name} Lot ${pkg.lot_name}`
      : `${pkg.release_name} ${pkg.name}`;

    const variables = [
      ["|*FNAME*|", form.first_name],
      ["|*LOT_NAME*|", name],
      ["|*LOT_FLYER_URL*|", pkg.lot_flyer || pkg.flyer]
    ];

    const { html, text } = generateEdm(variables);

    const data = {
      key: "0sCIXpu7tIy76LV1Mpx1Eg",
      message: {
        html,
        text,
        subject: "Eliston Lot Details",
        from_email: "info@eliston.com.au",
        from_name: "Eliston Kiosk",
        to: [
          {
            email: form.email,
            name: `${form.first_name} ${form.last_name}`,
            type: "to"
          }
        ],
        headers: {
          "Reply-To": "info@eliston.com.au"
        },
        important: false,
        track_opens: null,
        track_clicks: null,
        auto_text: null,
        auto_html: null,
        inline_css: true,
        url_strip_qs: null,
        preserve_recipients: null,
        view_content_link: null,
        bcc_address: null,
        tracking_domain: null,
        signing_domain: null,
        return_path_domain: null,
        merge: true,
        merge_language: "mailchimp",
        global_merge_vars: [
          {
            name: "FNAME",
            content: form.first_name
          },
          {
            name: "LOTIMAGE",
            content: pkg.image.url
          },
          {
            name: "PRECINCTNAME",
            content: pkg.neighbourhood_name
          },
          {
            name: "LOTNAME",
            content: name
          },
          {
            name: "STATUSTEXT",
            content: "N/A"
          },
          {
            name: "STAGENAME",
            content: pkg.release_name
          },
          {
            name: "LOTAREA",
            content: pkg.total_area || pkg.lot_area || pkg.area
          },
          {
            name: "LOTFRONTAGE",
            content: pkg.lot_frontage || pkg.frontage
          },
          {
            name: "LOTDEPTH",
            content: "N/A"
          }
        ],
        merge_vars: [],
        tags: [],
        subaccount: null,
        google_analytics_domains: [],
        google_analytics_campaign: null,
        metadata: {},
        recipient_metadata: [],
        attachments: [],
        images: []
      },
      async: false,
      ip_pool: "Main Pool",
      send_at: null
    };

    if (typeof window.isLog === "function") {
      window.isLog("Register​ ​User", {
        first_name: form.first_name,
        last_name: form.last_name,
        email: form.email,
        phone: form.phone,
        meta: {
          postcode: form.postcode,
          enquiry: "Send details to mobile from kiosk",
          interest: name
        }
      });
    }

    refetch({ data });
  };

  const onKeyPress = value => {
    if (value === "{tab}") {
      const allFields = [
        "first_name",
        "last_name",
        "email",
        "phone_number",
        "postcode"
      ];
      const index = allFields.indexOf(activeInput);
      const nextIndex = index + 1;
      const nextInput =
        nextIndex < allFields.length ? allFields[nextIndex] : allFields[0];
      if (nextInput) setActiveInput(nextInput);
    }
    // console.log('value', value);

    //   const values = getValues();
    //   console.log('values', values);
    //   const value = values[activeInput];
    //   setValue(activeInput, `${value}${newValue}`)
  };

  const onChange = value => setValue(activeInput, value);
  const onChangeInput = event => keyboard.current.setInput(event.target);
  const handleInputFocus = e => setActiveInput(e.target.name);

  const successfullySent = !loading && data && !error;

  return (
    <div
      className={classnames(styles.content, {
        [styles.show]: showForm
      })}
    >
      {!loading && !successfullySent ? (
        <div className={styles.simpleKeyboard}>
          <div className={styles.close_form} onClick={() => toggleForm()}>
            <Icon icon="arrow" className={styles.icon} />
            GO BACK
          </div>
          <Keyboard
            keyboardRef={r => (keyboard.current = r)}
            onChange={onChange}
            onKeyPress={onKeyPress}
            tabCharOnTab={false}
            inputName={activeInput}
            mergeDisplay={true}
            display={{
              "{tab}": "next"
            }}
          />
        </div>
      ) : null}
      {successfullySent ? (
        <div className={styles.thanks_message}>
          <h5>Thank you.</h5>
          <div>
            The information package you requested has been sent to your inbox.
          </div>
          <div className={styles.close_form} onClick={() => toggleForm()}>
            <Icon icon="arrow" className={styles.icon} />
            GO BACK
          </div>
        </div>
      ) : null}
      {!data || error ? (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className={classnames("ta-row", styles.two_col_input)}>
            <div
              className={classnames(styles.field, {
                [styles.error]: formErrors.first_name
              })}
            >
              <label htmlFor="first_name">First Name *</label>
              <input
                onChange={onChangeInput}
                type="text"
                name="first_name"
                ref={register({ required: true })}
                onFocus={handleInputFocus}
                autoComplete="off"
                disabled={loading}
                autoFocus={true}
              />
            </div>
            <div
              className={classnames(styles.field, {
                [styles.error]: formErrors.last_name
              })}
            >
              <label htmlFor="last_name">Last Name *</label>
              <input
                onChange={onChangeInput}
                type="text"
                name="last_name"
                ref={register({ required: true })}
                onFocus={handleInputFocus}
                autoComplete="off"
                disabled={loading}
              />
            </div>
          </div>
          <div className="ta-row">
            <div
              className={classnames(styles.field, {
                [styles.error]: formErrors.email
              })}
            >
              <label htmlFor="email">Email *</label>
              <input
                onChange={onChangeInput}
                type="email"
                name="email"
                ref={register({ required: true })}
                onFocus={handleInputFocus}
                autoComplete="off"
                disabled={loading}
              />
            </div>
          </div>
          <div className={classnames("ta-row", styles.two_col_input)}>
            <div
              className={classnames(styles.field, {
                [styles.error]: formErrors.phone_number
              })}
            >
              <label htmlFor="phone_number">Phone number *</label>
              <input
                onChange={onChangeInput}
                type="text"
                name="phone_number"
                ref={register({ required: true, pattern: /^[0-9]+$/i })}
                onFocus={handleInputFocus}
                autoComplete="off"
                disabled={loading}
              />
            </div>
            <div
              className={classnames(styles.field, {
                [styles.error]: formErrors.postcode
              })}
            >
              <label htmlFor="postcode">Postcode</label>
              <input
                onChange={onChangeInput}
                type="text"
                name="postcode"
                ref={register({ pattern: /^[0-9]+$/i })}
                onFocus={handleInputFocus}
                autoComplete="off"
                disabled={loading}
              />
            </div>
          </div>
          <div className={styles.field}>
            <button
              type="submit"
              className="ta-btn ta-btn__large ta-btn__primary"
              disabled={loading}
            >
              {/* @TODO add nice loader */}
              {loading ? "Loading..." : "Email me details"}
            </button>
          </div>
        </form>
      ) : null}
    </div>
  );
};

export default EmailForm;
