import path from "path";
import { pick } from "dot-object";
import { lowercaseAndParseSpaces } from "traffic-property-app/utils";

import icons from "./icons";

const getter = function(value) {
  return pick(value, this);
};

const traffic = {
  ...(window.traffic || {}),
  get: getter
};

const parseTfaIDs = ids => {
  if (ids) {
    const array = ids.split(",");
    return array;
  }
  return [];
};

export const config = {
  ...traffic,

  //
  // Configure API
  //
  api: {
    //
    // Examples
    //
    // One single endpoint will have all the required information for one big development company
    // url: '/api/payload.json',
    //
    // Depending on the website the traffic app needs to know which data is loading.
    // Either project or neighbourhood needs to be set, not both.
    // If none of those options is set all the projects and neighbourhoods will be shown
    //
    // example to setup single project website
    // project: 'silverwoods',
    //
    // example for single neighbourhood website
    // neighbourhood: 'The+Mill+Quarter'
    // @NOTE for testing stories use scholars_green instead
    // this is just for the sample_data.json
    // neighbourhood: 'Scholars+Green'
    ...(traffic.get("api") || {}),
    get: getter
  },

  style: {
    ...(traffic.get("style") || {}),
    icons: {
      ...icons,
      ...(traffic.get("style.icons") || {})
    },
    get: getter
  },

  //
  // Estate Icons and labels
  //
  estates: {
    //
    // Examples
    //
    // jubilee: {
    //   label: 'Wyndham Vale - Vic',
    //   logo: '/img/estates/jubilee.png',
    //   color: '#ba0c2f'
    // },
    // silverwoods: {
    //   label: 'Yarrawonga - Vic',
    //   logo: '/img/estates/silverwoods.png',
    //   color: '#000'
    // },
    ...(traffic.get("estates") || {}),
    get: getter
  },

  //
  // Neighbourhoods Icons and labels
  //
  neighbourhoods: {
    //
    // Examples
    //
    //
    // 'urban-square': {
    //   label: "Urban Square",
    //   logo: '/img/estates/urban_square.png',
    //   color: "#ba0c2f"
    // },
    // 'the-mill-quarter': {
    //   label: "The Mill Quarter",
    //   logo: '/img/estates/the_mill_quarter.png',
    //   color: "#000"
    // },
    ...(traffic.get("neighbourhoods") || {}),
    get: getter
  },

  //
  // Routes
  //
  routes: {
    houseandland: "house-land-package",
    townhomes: "townhomes",
    apartments: "apartments",
    ...(traffic.get("routes") || {}),
    landforsale: {
      masterplan: "land-for-sale",
      results: "land-for-sale-search-results",
      ...(traffic.get("routes.landforsale") || {})
    },
    property: {
      search: "property-search",
      results: "property-search-results",
      ...(traffic.get("routes.property") || {})
    },

    get(value, ...paths) {
      let route = pick(value, this) || "";
      if (route) route = `/${route}`;
      route = path.join(route, ...paths);
      return route === "." ? "" : route;
    }
  },

  //
  // Confire labels names
  //
  labels: {
    ...(traffic.get("labels") || {}),
    filters: {
      neighbourhood: "Neighbourhood",
      estate: "Estate",
      ...(traffic.get("labels.filters") || {})
    },
    get: getter
  },

  //
  // Advertising
  //
  promotions: traffic.get("promotions") || [],

  //
  // Enquiry Form
  //
  enquiryForm: {
    privacy_policy: "",
    terms_and_conditions: "",
    ...(traffic.get("enquiry_form") || {}),
    tfa_ids: parseTfaIDs(traffic.get("enquiry_form.tfa_ids")),

    get: getter
  },

  //
  // INMUTABLE CONFIGS
  //
  attributes: {
    1: "Corner Lot",
    2: "Standard Lot",
    3: "Irregular Lot",
    4: "Water Front",
    5: "Garden Front",
    6: "Golf Course",
    7: "Close to School",
    8: "Close to Child Care",
    9: "Close to Train Station",
    10: "Wetlands",
    11: "Park Front",
    12: "Swimming Pool",
    13: "Gym",
    14: "Passeggiata",
    15: "Extra Parking",
    16: "Dual Key",
    17: "Terrace",
    18: "Investment",
    19: "Balcony"
  },

  status: {
    1: "Available",
    2: "Sold",
    3: "Builders",
    4: "Hold",

    get(id, opts) {
      const { slug } = opts || {};
      const status = this[id];
      if (slug) return lowercaseAndParseSpaces(status, "-");
      return status;
    }
  },

  flags(arr) {
    if (arr && arr.custom) return arr.custom;
    if (arr && arr.featured === 1) return "featured";
    if (arr && arr.titled === 1) return "titled";
  },

  propertyTypes: {
    house: "House and Land",
    townhome: "Townhome",
    apartment: "Apartment",
    land: "Land for Sale"
  },

  acf_settings: {
    ...traffic.get("acf_settings"),
    get: getter
  }
};

if (config.promotions && config.promotions.length) {
  config.promotions = config.promotions.map(ad => {
    ad.get = getter;
    return ad;
  });
}

//
// @TODO remove this when the plugin can add this configuration
//
if (config.api.get("project") === "jubilee") {
  config.neighbourhoods = {
    ...(config.neighbourhoods || {}),
    "the-mill-quarter": {
      label: "The Mill Quarter",
      logo: `${process.env.PUBLIC_URL}/img/neighbourhoods/the-mill-quarter.png`,
      color: "#e6cd21"
    },
    "scholars-green": {
      label: "Scholars Green",
      logo: `${process.env.PUBLIC_URL}/img/neighbourhoods/scholars-green.png`,
      color: "#5daa2c"
    },
    "urban-square": {
      label: "Urban Square",
      logo: `${process.env.PUBLIC_URL}/img/neighbourhoods/urban-square.png`,
      color: "#F37020"
    }
  };
}

["estates", "neighbourhoods"].map(type => {
  const keys = Object.keys(config[type]);

  if (keys.length) {
    keys.map(key => {
      const estate = config[type][key];

      if (typeof estate === "object") {
        //
        // @TODO remove this when the plugin can add this configuration
        //
        if (key === "jubilee") estate.color = "#ba0c2f";
        if (key === "silverwoods") estate.color = "#000";

        if (!estate.logo) {
          estate.logo = `${process.env.PUBLIC_URL}/img/estates/${key}.svg`;
        }
      }

      return null;
    });
  }

  return null;
});

export default config;
