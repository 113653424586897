import React from "react";
import classnames from "classnames";
import ReactQRCode from "qrcode.react";

import styles from "./QRCode.module.scss";

export const QRCode = ({ toggleForm = () => null, link, show }) => {
  if (!link) return null;

  return (
    <div className={classnames(styles.container, { [styles.show]: show })}>
      <div className={styles.content}>
        <h3>Scan QR Code</h3>
        <div className={styles.paragraph}>
          Use your camera on your phone to scan the QR Code to view the flyer
        </div>
        <div className={styles.qrcode}>
          <ReactQRCode
            value={link}
            size={200}
            // imageSettings={{ height: "200px", width: "200px" }}
          />
        </div>
        <div
          className={classnames(styles.sendEmail)}
          onClick={() => toggleForm("email")}
        >
          Send to email instead
        </div>
        <div className={classnames(styles.close)} onClick={() => toggleForm()}>
          Close
        </div>
      </div>
    </div>
  );
};

export default QRCode;
