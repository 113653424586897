import React from "react";
import classnames from "classnames";

const _isBetween = (n, a, b) => (n - a) * (n - b) <= 0;

export const HorizontalRange = ({
  name,
  options,
  value: range,
  onChange,
  ...props
}) => {
  const from = options[0].value;
  const to = options[options.length - 1].value;
  const checkAll = () => {
    if (typeof onChange === "function") onChange(null);
  };

  const [currentFrom, currentTo] = range;
  const isCheckedAll = from === currentFrom && to === currentTo;

  const handleChange = e => {
    let newRange = range;
    const [min, max] = range;
    const value = parseInt(e.target.value);
    if (min === value || max === value) return null;

    const isBetween = _isBetween(value, min, max);

    if (isBetween) {
      newRange = [value];
    } else {
      if (range.length === 1) {
        const isGreater = value >= min;
        if (isGreater) {
          newRange = [min, value];
        } else {
          newRange = [value, min];
        }
      } else if (range.length > 1) {
        const isSmaller = value <= min;
        if (isSmaller) {
          newRange = [value, max];
        } else {
          newRange = [min, value];
        }
      }
    }

    const isCheckedAll = from === newRange[0] && to === newRange[1];
    if (isCheckedAll) newRange = null;

    if (typeof onChange === "function") onChange(newRange, e);
  };

  return (
    <>
      <span className="checkbox">
        <input
          type="checkbox"
          id={`any-${name}`}
          checked={isCheckedAll}
          onChange={checkAll}
        />
        <label htmlFor={`any-${name}`} className="checkall">
          All
        </label>
      </span>

      {options.map((option, i) => {
        const number = option.value;
        const key = `${name}-${i + 1}`;

        const isLastItem = i === options.length - 1;
        const checked = range.indexOf(number) > -1;

        const isMin = range.indexOf(number) === 0;
        const isMax = range.indexOf(number) === range.length - 1;

        return (
          <span
            className={classnames(`checkbox`, {
              min: isMin,
              max: isMax
            })}
            key={key}
          >
            <input
              type="checkbox"
              id={key}
              value={number}
              onChange={handleChange}
              checked={checked}
            />
            <label htmlFor={key}>{isLastItem && name !== 'parking' &&  name !== 'stories' ? `${number}+` : number}</label>
          </span>
        );
      })}
    </>
  );
};

export default HorizontalRange;
