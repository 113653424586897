import React, { useState, useEffect } from "react";
import classnames from "classnames";
// import { Card } from "reactstrap";
// import config from "config";
import Gallery from "traffic-property-app/components/Gallery";

import styles from "./Map.module.scss";

// const { isTouchTable } = config;

const Map = ({
  levels = [],
  labels = [],
  baseMap,
  activeLevel: activeLevelProp = 0,
  className,
  assets,
  ...props
}) => {
  const [activeLevel, setActiveLevel] = useState(activeLevelProp);
  useEffect(() => setActiveLevel(activeLevelProp), [activeLevelProp]);
  const [activeGallery, setActiveGallery] = useState({
    marker: -1,
    index: -1
  });
  const toggleGallery = ({ marker, index }) => (e) => {

    if(!marker || marker >= 0){
        return setActiveGallery({ marker, index });
    }

    if(e.target.classList.contains('ta-gallery-wrapper') || e.target.classList.contains('close')) {
      if(marker && marker < 0){
        return setActiveGallery({ marker, index });
      }
    } 
  };

  const [zoom, x, y] = levels[activeLevel].zoom || [];
  const transform = `scale(${zoom}) translate(${x}, ${y})`;

  return (
    <>
      <div
        className={classnames(className, styles.map)}
        {...props}
        key={levels.name}
      >
        {/* <div
          className={styles.north}
          style={{ backgroundImage: `url(${assets.masterplanNorth})` }}
        />

        <img
          className={styles.melbcbd}
          src={assets.melbcbd}
          alt="Melbourne CBD"
        />

        <img
          className={styles.cranbourne}
          src={assets.cranbourne}
          alt="Cranbourne"
        /> */}

        <div className={styles.markers}>
          {levels.length
            ? levels.map((level, i) => {
                const isActive = i === activeLevel;
                const markers = level.markers || [];

                return (
                  <span
                    key={`level-gallery-${i}`}
                    className={classnames({ [styles.active]: isActive })}
                  >
                    {markers.map((marker, i) => {
                      const { title, subtitle, coords, animation } = marker;
                      const gallery = marker.gallery || [];
                      const [left, top] = coords || [];

                      return (
                        <>
                          {title ? (
                            <div
                              key={`title-${i}`}
                              className={styles.marker__item}
                              style={{
                                left: left,
                                top: top
                              }}
                            >
                              <span className={styles.marker__title} dangerouslySetInnerHTML={{ __html: title }}/>
                              {gallery.length ? (
                                <>
                                  <img
                                    className={styles.marker__gallery}
                                    onClick={toggleGallery({
                                      marker: i,
                                      index: 0
                                    })}
                                    alt="Gallery"
                                    src={assets.camera}
                                  />
                                </>
                              ) : null}
                              {subtitle ? (
                                <span className={styles.marker__subtitle}>
                                  {subtitle}
                                </span>
                              ) : null}
                              <span className={classnames(styles.marker__line, animation === 'short' ? styles.short : null, animation === 'long' ? styles.long : null)} />
                            </div>
                          ) : null}

                          {isActive && activeGallery.marker === i ? (
                            <span className={classnames(styles.galleryWrapper, "ta-gallery-wrapper")} onClick={toggleGallery({
                              marker: -1,
                              index: -1
                            })}>
                              <Gallery
                                key={`gallery-${i}`}
                                slides={gallery}
                                colour="grey"
                                show={activeGallery.index > -1}
                                startIndex={activeGallery.index}
                                onClose={toggleGallery({
                                  marker: -1,
                                  index: -1
                                })}
                              />
                            </span>
                          ) : null}
                        </>
                      );
                    })}
                  </span>
                );
              })
            : null}
        </div>
        <div
          className={styles.basemap}
          style={{
            backgroundImage: `url(${baseMap})`,
            transform: transform
          }}
        >
          {/* <img
            className={styles.youarehere}
            src={assets.pinpoint}
            alt="you are here"
          /> */}
          {levels.length
            ? levels.map((level, i) => {
                const isActive = i === activeLevel;
                const markers = level.markers || [];

                return markers.map((marker, i) => {
                  const { backgroundImage } = marker;
                  // const gallery = marker.gallery || [];
                  // const [left, top] = coords || [];

                  return (
                    <span key={`level-${i}`}>
                      <div
                        key={`level-${i}`}
                        className={classnames(styles.level, {
                          [styles.active]: isActive
                        })}
                      >
                        {backgroundImage ? (
                          <div
                            key={`image-${i}`}
                            className={classnames(styles.surface, {
                              [styles.active]: isActive
                            })}
                            style={{
                              backgroundImage: `url(${backgroundImage})`
                            }}
                          />
                        ) : null}
                      </div>
                    </span>
                  );
                });
              })
            : null}
        </div>
      </div>
    </>
  );
};

export default Map;
