import React from "react";
import styles from './Loader.module.scss';

export const Loader = () => (
  <svg
    width="128px"
    height="128px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    className={styles.loader}
    style={{
      background: "none",
      position: "absolute",
      zIndex: "999",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)"
    }}
  >
    <circle
      cx="50"
      cy="50"
      r="7.13938"
      fill="none"
      strokeWidth="6"
    >
      <animate
        attributeName="r"
        calcMode="spline"
        values="0;40"
        keyTimes="0;1"
        dur="1"
        keySplines="0 0.2 0.8 1"
        begin="-0.5s"
        repeatCount="indefinite"
      />
      <animate
        attributeName="opacity"
        calcMode="spline"
        values="1;0"
        keyTimes="0;1"
        dur="1"
        keySplines="0.2 0 0.8 1"
        begin="-0.5s"
        repeatCount="indefinite"
      />
    </circle>
    <circle
      cx="50"
      cy="50"
      r="29.0007"
      fill="none"
      strokeWidth="6"
    >
      <animate
        attributeName="r"
        calcMode="spline"
        values="0;40"
        keyTimes="0;1"
        dur="1"
        keySplines="0 0.2 0.8 1"
        begin="0s"
        repeatCount="indefinite"
      />
      <animate
        attributeName="opacity"
        calcMode="spline"
        values="1;0"
        keyTimes="0;1"
        dur="1"
        keySplines="0.2 0 0.8 1"
        begin="0s"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);

export default Loader;
