import React, { useEffect } from "react";

import config from "traffic-property-app/config";
import useApi from "traffic-property-app/api";

// Components
import Map from "traffic-property-app/components/Map";
import Button from "traffic-property-app/components/Button";

const PackagePlan = ({ package: pkg, getMainImage }) => {
  const api = useApi();

  const isApartment = pkg.propertyType === "apartment";
  const isTownhome = pkg.propertyType === "townhome";

  const neighbourhood = api.neighbourhoods.retrieve(pkg.neighbourhood_slug);
  const release =
    neighbourhood.landforsale.find(({ slug }) => slug === pkg.release_slug) ||
    {};
  const lot =
    release && release.lots
      ? release.lots.find(({ slug }) => slug === pkg.lot_slug)
      : {};

  const townhome = isTownhome && api.townhomes.retrieve(pkg.release_slug);
  const building = isApartment && api.buildings.retrieve(pkg.building);
  const level = isApartment && building.levels[pkg.floor_number];
  let coordMapLevels = isApartment
    ? [
        {
          coords: level.apartments,
          image: level.image,
          link: apartment =>
            apartment.slug && config.routes.get("houseandland", apartment.slug),
          legend: [
            { label: "Available" },
            { label: "Hold", className: "builders" },
            { label: "Sold" }
          ]
        },
        {
          image: building.image
        }
      ]
    : [];

  if (!isApartment) {
    if (isTownhome) {
      // @TODO allow this when images are the correct ones.
      if (townhome.image) {
        coordMapLevels.push({
          image: townhome.image
        });
      }

      if (neighbourhood.image) {
        coordMapLevels.push({
          image: neighbourhood.image,
          coords: [{ coords: townhome.coords }],
          active: townhome.coords
        });
      }
    }

    if (!isTownhome) {
      if (lot.image) coordMapLevels.push({ image: lot.image });
      if (release.image) {
        coordMapLevels.push({
          image: release.image,
          coords: [{ coords: lot.coords }],
          active: lot.coords
        });
      }

      if (neighbourhood.image) {
        coordMapLevels.push({
          image: neighbourhood.image,
          coords: [{ coords: release.coords }],
          active: release.coords
        });
      }
    }

    coordMapLevels.push({
      image: neighbourhood.plan,
      coords: [{ coords: neighbourhood.coords }],
      active: neighbourhood.coords
    });
  }

  // remove levels without images
  coordMapLevels = coordMapLevels.filter(
    level => level.image && level.image.url
  );

  useEffect(
    () => {
      if (typeof getMainImage === "function") {
        const { url: image } = coordMapLevels[0].image;
        if (image) getMainImage(image);
      }
    },
    [coordMapLevels, getMainImage]
  );

  const {
    masterplan_flyer: masterplanFlyer,
    design_guidelines_flyer: designGuidelinesFlyer,
    duediligence_flyer: duediligenceFlyer
  } = neighbourhood;

  return (
    <>
      <Map levels={coordMapLevels} active={pkg.slug} showLabel />

      {masterplanFlyer || designGuidelinesFlyer || duediligenceFlyer ? (
        <div className="lfs--downloads">
          <h4>Downloads</h4>
          {masterplanFlyer && (
            <Button
              size="small"
              href={masterplanFlyer}
              icon="download"
              color="secondary"
              external
            >
              Masterplan
            </Button>
          )}
          {designGuidelinesFlyer && (
            <Button
              size="small"
              href={designGuidelinesFlyer}
              icon="download"
              color="secondary"
              external
            >
              Design guidelines
            </Button>
          )}
          {duediligenceFlyer && (
            <Button
              size="small"
              href={duediligenceFlyer}
              icon="download"
              color="secondary"
              external
            >
              Due Diligence
            </Button>
          )}
        </div>
      ) : null}
    </>
  );
};

export default PackagePlan;
