import React from "react";

import config from "config";

// components
import Navbar from "components/Navbar";
import BackButton from "components/BackButton";

import styles from "./MainLayout.module.scss";

export const MainLayout = Component => props => {
  const menu = config.sidebar;

  return (
    <>
      <BackButton />
      <Navbar menu={menu} className={styles.navbar} collapse />
      <main className={styles.main}>
        <Component {...props} />
      </main>
    </>
  );
};

export default MainLayout;
