import React, { useContext } from "react";
import path from "path";

import useApi from "traffic-property-app/api";
import config from "traffic-property-app/config";
import PageNotFound from "traffic-property-app/pages/errors/404";

// components
import { RouterHistoryContext } from "traffic-property-app/components/BackButton";
import Masterplan from "traffic-property-app/components/Masterplan";
import BackButton from "traffic-property-app/components/BackButton";

const NB_SLUG = config.api.get("neighbourhood");
const PROJECT_SLUG = config.api.get("project");

export const LandForSalePage = ({
  showFilters,
  noAdvancedSearch,
  isResultPage,
  className,
  ...props
}) => {
  const api = useApi();
  const { old } = useContext(RouterHistoryContext);

  const neighbourhoods = api.neighbourhoods.list();

  const { params } = props.match;
  const { neighbourhoodOrRelease, releaseOrLot, lotOrLotID } = params;

  let neighbourhood = neighbourhoodOrRelease;
  let release = releaseOrLot;
  let lotID = params.lotID;

  if (NB_SLUG) {
    neighbourhood = NB_SLUG;
    release = neighbourhoodOrRelease;
    lotID = lotOrLotID;
  }

  const isDevelopmentCompany = !PROJECT_SLUG && !NB_SLUG;
  if (isDevelopmentCompany && !neighbourhood) return <PageNotFound />;

  const oldWasResultsPage = old && old.name === "Results";

  let backButtonTitle = "";
  let backButtonLink = "";

  if (lotID && (!old || oldWasResultsPage)) {
    backButtonTitle = "Results";
    backButtonLink = config.routes.get("landforsale.results");
  } else {
    if (lotID) {
      backButtonTitle = "Release";
      backButtonLink = NB_SLUG ? `${release}` : `${neighbourhood}/${release}`;
    } else if (NB_SLUG) {
      backButtonTitle = "Masterplan";
    } else if (release) {
      backButtonTitle = config.labels.get("filters.neighbourhood");
      backButtonLink = `${neighbourhood}`;
    } else if (neighbourhood) {
      backButtonTitle = "Masterplan";
      backButtonLink = ``;
    }

    backButtonLink = path.join(
      config.routes.get("landforsale.masterplan"),
      backButtonLink
    );
  }

  const isNotMasterplanView =
    (!NB_SLUG && neighbourhood) || (NB_SLUG && release);


  return (
    <>
      {isNotMasterplanView ? (
        <div className="ta-container">
          <div className="ta-row">
            <BackButton title={backButtonTitle} to={backButtonLink} />
          </div>
        </div>
      ) : null}
      <Masterplan
        {...props}
        className={className}
        neighbourhoods={neighbourhoods}
        selectedNeighbourhood={neighbourhood}
        selectedRelease={release}
        selectedLot={lotID}
        showFilters={showFilters}
        noAdvancedSearch={noAdvancedSearch}
        isResultPage={isResultPage}
        navigation
      />
    </>
  );
};

export default LandForSalePage;
