import React from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

import "./Icon.scss";

import config from "traffic-property-app/config";

const icons = config.get("style.icons");

export const Icon = ({
  tag: Tag = "span",
  icon: iconName,
  size,
  children,
  href,
  style,
  className,
  title,
  external,
  ...props
}) => {
  const icon = icons[iconName];
  return (
    <>
      { !external ? (
        <Tag
          className={classnames("ta-icon", className, {
            [`ta-icon-${size}`]: size
          })}
          style={{ ...style, backgroundImage: `url(${icon})` }}
          href={href}
          title={title && title.replace("_", " ")}
          {...props}
        >
          {children}
        </Tag>
      ) : (
        <Tag
          className={classnames("ta-icon", className, {
            [`ta-icon-${size}`]: size
          })}
          style={{ ...style, backgroundImage: `url(${icon})` }}
          href={href}
          title={title && title.replace("_", " ")}
          {...props}
          target={"_blank"}
          rel="noopener noreferrer"
          >
          {children}
        </Tag>
        
      )}
    </>
  );
};

Icon.propTypes = {
  size: PropTypes.oneOf(["sm", "lg"]),
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired
};

export default Icon;
