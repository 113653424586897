import deepmerge from "deepmerge";
import sift from "sift";

import config from "traffic-property-app/config";

import { generateBuilderSlug, lowercaseAndParseSpaces } from "../utils";

const PROJECT_SLUG = config.get("api.project");
const NB_SLUG = config.get("api.neighbourhood");

export const initNeighbourhoods = (api, { parseUrl, parseSlug }) => {
  const {
    assetpath,
    landsearch,
    apartmentSearch,
    packageSearch,
    ...devCompanies
  } = api.data || {};

  const neighbourhoodsList = [];
  const projectsList = [];

  const getProjectNeibourhoodData = project => {
    projectsList.push(project);
    const {
      estate_name: projectName,
      slug: projectSlug,
      precincts: neighbourhoods
    } = project;

    // if we are looking for an specific neighbourhood name
    // loop through all the project neighbourhoods (third level)
    return neighbourhoods.find(neighbourhood => {
      // @TODO THIS MIGHT NEED TO BE REMOVED WHEN API IS READY
      // THIS IS A QUICK FIX TO REMOVE "townhomes" from the slug
      if (!neighbourhood.landforsale) {
        neighbourhood.landforsale = neighbourhood["land-for-sale"];
        delete neighbourhood["land-for-sale"];
      }

      const {
        name: neighbourhoodName,
        slug: neighbourhoodSlug,
        landforsale
      } = neighbourhood;

      neighbourhood.masterplan_flyer = neighbourhood.masterplan_flyer
        ? parseUrl(neighbourhood.masterplan_flyer)
        : project.masterplan_flyer;

      neighbourhood.duediligence_flyer = neighbourhood.duediligence_flyer
        ? parseUrl(neighbourhood.duediligence_flyer)
        : project.duediligence_flyer;

      neighbourhood.design_guidelines_flyer = neighbourhood.design_guidelines_flyer
        ? parseUrl(neighbourhood.design_guidelines_flyer)
        : project.design_guidelines_flyer;

      neighbourhood.image = {
        ...neighbourhood.image,
        url: parseUrl(neighbourhood.image.url)
      };

      neighbourhood.project_name = project.name;
      neighbourhood.project_slug = project.slug;

      //
      // Parse packages
      //
      if (neighbourhood.packages) {
        neighbourhood.packages = neighbourhood.packages.map(pkg => {
          pkg.slug = parseSlug(pkg, { projectSlug, neighbourhoodSlug });

          if (pkg.similar) {
            // @NOTE there's "similar" packages that are undefined
            pkg.similar = pkg.similar.filter(el => el);

            pkg.similar = pkg.similar.map(similarPkg => {
              // @TODO REMOVE THIS WHEN API IS READY AND ALL
              // THE similar have consistency
              if (typeof similarPkg !== "object")
                similarPkg = {
                  slug: similarPkg,
                  propertyType: pkg.propertyType
                };

              if (similarPkg.slug) {
                similarPkg.slug = parseSlug(similarPkg, {
                  projectSlug,
                  neighbourhoodSlug
                });
              }

              return similarPkg;
            });
          }

          if (pkg.propertyType === "ap") {
            pkg.propertyType = "apartment";
          } else if (pkg.propertyType === "th") {
            pkg.propertyType = "townhome";
          } else {
            pkg.propertyType = "house";
          }

          // if contains price it means it is a houseandland
          if (pkg.builders_name)
            pkg.builders_slug = generateBuilderSlug(pkg.builders_name);

          pkg.price = parseInt(pkg.price) || 0;
          pkg.lot_frontage = parseInt(pkg.lot_frontage) || 0;

          if (pkg.builder_logo) pkg.builder_logo = parseUrl(pkg.builder_logo);
          if (pkg.lot_flyer) pkg.lot_flyer = parseUrl(pkg.lot_flyer);
          if (pkg.flyer) pkg.flyer = parseUrl(pkg.flyer);

          // @TODO NOW IMAGES ARE AN OBJECT
          pkg.image = pkg.image ? parseUrl(pkg.image.url) : null;
          if (pkg.image_single) pkg.image_single = parseUrl(pkg.image_single);
          if (pkg.plan_image)
            pkg.plan_image = parseUrl(`/packages/single${pkg.plan_image}`);

          // FLOORPLANS
          if (pkg.floor_image) pkg.floor_image = parseUrl(pkg.floor_image);
          if (pkg.floorplan_image)
            pkg.floorplan_image = parseUrl(pkg.floorplan_image);

          // TOWNHOMES PKGS
          if (pkg.floorplan_ground_image)
            pkg.floorplan_ground_image = parseUrl(pkg.floorplan_ground_image);
          if (pkg.floorplan_first_image)
            pkg.floorplan_first_image = parseUrl(pkg.floorplan_first_image);

          // CONVERT GALLERY STRING OF COMMAS INTO ARRAY
          if (pkg.gallery) {
            pkg.gallery = pkg.gallery.replace(/\s/g, "").split(",");
            pkg.gallery = pkg.gallery.map(photo => parseUrl(photo));
          }

          pkg.neighbourhood_id = pkg.precinct_id;
          pkg.neighbourhood_name = neighbourhoodName;
          pkg.neighbourhood_slug = neighbourhoodSlug;
          delete pkg.precinct_id;
          delete pkg.precinct_name;

          pkg.project_name = projectName;
          pkg.project_slug = projectSlug;

          // pkg.release_name = projectName;

          return pkg;
        });
      }

      //
      // Parse landforsale
      //
      // @TODO THIS MIGHT NEED TO BE REMOVED WHEN API IS READY
      if (neighbourhood.landforsale) {
        neighbourhood.landforsale = neighbourhood.landforsale.map(release => {
          if (release.lots) {
            release.lots = release.lots.map(lot => {
              // @TODO REMOVE THIS WHEN API RETURNS SLUG
              // THIS IS A QUICK FIX FOR NOW
              if (!lot.slug) lot.slug = lowercaseAndParseSpaces(lot.name);

              if (lot.packages) {
                lot.packages = lot.packages.map(pkg => {
                  pkg.propertyType = "hal";
                  const slug = parseSlug(pkg, {
                    projectSlug,
                    neighbourhoodSlug
                  });

                  const p = neighbourhood.packages.find(
                    pkg => pkg.slug === slug
                  );
                  if (p) {
                    p.release_slug = release.slug;
                    p.release_name = release.name;

                    // merge attributes from lots with lot packages
                    if (lot.attributes && lot.attributes.length) {
                      p.attributes = [...lot.attributes, ...p.attributes];

                      // remove duplicates
                      p.attributes = p.attributes.filter(
                        (attribute1, attribute2) => {
                          return (
                            p.attributes.indexOf(attribute1) === attribute2
                          );
                        }
                      );
                    }
                  }

                  return { slug };
                });
              }

              return {
                ...lot,
                neighbourhood_slug: neighbourhoodSlug,
                release_slug: release.slug,
                release_name: release.name,
                propertyType: "land",
                image: {
                  ...lot.image,
                  url: parseUrl(lot.image.url)
                },
                flyer: parseUrl(lot.flyer)
              };
            });
          }

          return {
            ...release,
            image: {
              ...release.image,
              url: parseUrl(release.image.url)
            }
          };
        });
      }

      //
      // Parse townhomes
      //
      // @TODO THIS MIGHT NEED TO BE REMOVED WHEN API IS READY
      if (neighbourhood.townhomes) {
        neighbourhood.townhomes = neighbourhood.townhomes.map(release => {
          // @TODO THIS MIGHT NEED TO BE REMOVED WHEN API IS READY
          // THIS IS A QUICK FIX TO REMOVE "townhomes" from the slug
          release.slug = release.slug.split("townhomes/")[1];

          release.neighbourhood_name = neighbourhoodName;
          release.neighbourhood_slug = neighbourhoodSlug;

          release.lots = release.lots.map(lot => {
            // @TODO remove this once the api & react-app migrated succesfully to streetscape_coords
            lot.coords = lot.coords || lot.streetscape_coords;

            lot.packages = lot.packages.map(pkg => {
              if (pkg && pkg.id) {
                const neighbourhoodPkg = neighbourhood.packages.find(
                  ({ id }) => id === pkg.id
                );

                if (neighbourhoodPkg) {
                  neighbourhoodPkg.release_slug = release.slug;
                  neighbourhoodPkg.release_name = release.name;
                }
              }

              return pkg;
            });

            return lot;
          });

          const image = release.image || {};
          const imageStreetscape = release.image_streetscape || {};

          return {
            ...release,
            neighbourhood_slug: neighbourhoodSlug,
            release_slug: release.slug,
            release_name: release.name,
            propertyType: "townhome",
            image_streetscape: {
              ...imageStreetscape,
              url: parseUrl(imageStreetscape.url)
            },
            image: {
              ...image,
              url: parseUrl(image.url)
            }
          };
        });
      }

      //
      // Parse apartments
      //
      // @TODO THIS MIGHT NEED TO BE REMOVED WHEN API IS READY
      if (neighbourhood.apartments) {
        neighbourhood.apartments = neighbourhood.apartments.map(building => {
          // @TODO THIS MIGHT NEED TO BE REMOVED WHEN API IS READY
          // THIS IS A QUICK FIX TO REMOVE spaces from the slug
          building.slug = building.slug.split(" ").join("-");

          if (building.image && building.image.url)
            building.image.url = parseUrl(building.image.url);

          building.levels = building.levels.map(level => {
            level.apartments = level.apartments.map(apartment => {
              if (apartment && apartment.package_id) {
                const pkg = neighbourhood.packages.find(
                  ({ id }) => id === apartment.package_id
                );

                if (pkg) {
                  pkg.level = level.name;
                  pkg.release_name = building.name;
                  pkg.release_slug = building.slug;
                }
              }

              return apartment;
            });

            // Remove null apartments
            level.apartments = level.apartments.filter(apartment => apartment);

            if (level.image && level.image.url)
              level.image.url = parseUrl(level.image.url);

            return level;
          });

          return building;
        });
      }

      if (NB_SLUG) {
        const isNeighbourhood = neighbourhoodSlug === NB_SLUG;

        if (isNeighbourhood) {
          api.current = deepmerge(api.current, neighbourhood);

          if (landforsale) {
            neighbourhoodsList.push(neighbourhood);
          }

          return true;
        }

        return false;
      }

      api.current = deepmerge(api.current, neighbourhood);

      if (landforsale) {
        neighbourhoodsList.push(neighbourhood);
      }

      return false;
    });
  };

  // loop through all the dev companies (top level)
  Object.keys(devCompanies).find(key => {
    const projects = devCompanies[key];

    // loop through all the dev company projects (second level)
    return projects.find(project => {
      const { image, slug } = project;

      // Set plan to the project image (project plan)
      project.image = image ? { ...image, url: parseUrl(image.url) } : null;

      project.masterplan_flyer = project.masterplan_flyer
        ? parseUrl(project.masterplan_flyer)
        : null;
      project.duediligence_flyer = project.duediligence_flyer
        ? parseUrl(project.duediligence_flyer)
        : null;
      project.design_guidelines_flyer = project.design_guidelines_flyer
        ? parseUrl(project.design_guidelines_flyer)
        : null;

      // if we are looking for an specific project name
      // loop through all the projects
      if (PROJECT_SLUG) {
        const isProject = slug === PROJECT_SLUG;
        if (isProject) {
          getProjectNeibourhoodData(project);
          return true;
        }
      } else {
        return getProjectNeibourhoodData(project);
      }

      return false;
    });
  });

  api.projects = {
    list: () => ({ data: projectsList }),
    retrieve: slug => projectsList.find(sift({ slug }))
  };

  return {
    list: () => {
      const project = api.projects.retrieve(PROJECT_SLUG) || {};

      return {
        assetpath,
        data: neighbourhoodsList,
        project
      };
    },
    retrieve: slug => {
      const neighbourhood = neighbourhoodsList.find(
        neighbourhood => neighbourhood.slug === slug
      );

      const project = api.projects.retrieve(neighbourhood.project_slug) || {};
      neighbourhood.plan = project.image;

      return neighbourhood;
    }
  };
};

export default initNeighbourhoods;
