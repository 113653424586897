import React, { useRef } from 'react';
import classnames from 'classnames';

import useOutsideClick from 'hooks/useOutsideClick';

import styles from './Modal.module.scss';

export const Modal = ({ children, open, toggleOpen }) => {
  // console.log('open modal', open);
  const inner = useRef(null);

  useOutsideClick(inner, () => {
    // console.log('oustide click');
    toggleOpen(false);
  });
  return (
    <div className={classnames(styles.modal, { [styles.open]: open })}>
      <div
        className={styles.close}
        onClick={() => {
          toggleOpen();
        }}
      ></div>
      <div className={styles.inner} ref={inner}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
