import React, { useEffect } from "react";
import sift from "sift";
import { Link } from "react-router-dom";
import Map from "traffic-property-app/components/Map";
import useApi from "traffic-property-app/api";
import {
  Content,
  Sidebar,
  ContentHeader,
  ContentLeftBar,
  ContentFooter
} from "layouts/MainLayout";
import { PackagesList } from "components/Package";

import config from "config";
import Icon from "traffic-property-app/components/Icon";
import BackButton from "traffic-property-app/components/BackButton";
import { SearchPage } from "traffic-property-app/pages/explore/search";
// import PackagePlan from "traffic-property-react-apps/components/PackagePlan";

import styles from "./map.module.scss";

import masterplanImage from "./images/masterplan.jpg";
import mapleHero from "./images/maple/hero.jpg";
import mapleLots from "./images/maple/lots.jpg";
import apiumHero from "./images/apium/hero.jpg";
import apiumLots from "./images/apium/lots.jpg";
import parkviewHero from "./images/parkview/hero.jpg";
import parkviewLots from "./images/parkview/lots.jpg";
import wellingtonLots from "./images/wellington/lots.jpg";

const RELEASES = {
  maple: {
    slug: "maple",
    title: "The Maple Release",
    description: `The new Maple Collection of seven unique homes, offers a rare opportunity to secure a premium 3 bedroom townhome that feels more like a house.`,
    hero_image: { url: mapleHero },
    image: { url: mapleLots },
    coords: "315,406,469,406,469,351,459,341,326,342,315,350",
    inclusions: `High ceilings
Timber laminate flooring
LED downlights throughout
Engineered stone benchtops to kitchen and bathrooms
European inspired kitchen appliances including dishwasher
Mirror kitchen splashback
Heating
Landscaping, fencing & paving
Letterbox & clothesline
Remote controlled sectional garage door`
  },
  apium: {
    slug: "apium",
    title: "The Apium Release",
    description: `Secure a premium 4 bedroom townhome residence in a coveted parkfront location from $419,000. Designed by acclaimed Sienna Homes, The Apium Release offers fixed site costs and comprehensive inclusions.`,
    hero_image: { url: apiumHero },
    image: { url: apiumLots },
    coords: "1200,440,1249,440,1257,448,1259,557,1250,569,1200,570",
    inclusions: `Fixed site costs
  Stainless steel Ariston appliances
  Flooring throughout
  Gas central heating
  Driveway
  Landscaping
  Clothesline
  Letterbox`
  },
  parkview: {
    slug: "parkview",
    title: "The Parkview Release",
    description: `Exclusive 4 bedroom townhome residences in a central prime position opposite the soon to be completed Oak Park. The Parkview Collection offers comprehensive superior inclusions as standard all built to the exacting Burbank standards.`,
    hero_image: { url: parkviewHero },
    image: { url: parkviewLots },
    coords: "315,406,469,406,469,351,459,341,326,342,315,350",
    inclusions: `Technika kitchen appliances
  Stone bench tops in kitchen as standard
  LED fixed downlights
  Double glazed windows and doors
  2590mm (8ft/6inch) ceiling height to ground floor living
  Reverse cycle split system air-conditioning to living areas and heating panels to other areas
  Landscaping to front, rear and side (where applicable) including concrete rear terraces`
  },
  wellington: {
    slug: "wellington",
    title: "The Wellington Collection",
    description: `Secure a premium 4 bedroom townhome residence in a coveted parkfront location from $419,000. Designed by acclaimed Sienna Homes, The Wellington offers fixed site costs and comprehensive inclusions.`,
    hero_image: { url: apiumHero },
    image: { url: wellingtonLots },
    coords: "1200,257,1249,256,1257,263,1258,394,1252,401,1201,401",
    inclusions: `Fixed site costs
  Stainless steel Ariston appliances
  Flooring throughout
  Gas central heating
  Driveway
  Landscaping
  Clothesline
  Letterbox`
  }
};

const COORDS = {
  "/townhomes/wellington/2201": "547,140,801,140,838,177,837,247,548,247",
  "/townhomes/wellington/2202": "548,252,837,253,838,327,548,324",

  "/townhomes/parkview/2009": "281,511,335,453,408,453,408,835,281,835",
  "/townhomes/parkview/2008": "413,453,501,453,502,836,413,836",
  "/townhomes/parkview/2007": "506,454,595,453,597,835,508,835",
  "/townhomes/parkview/2005": "693,453,780,453,784,835,693,836",
  "/townhomes/parkview/2004": "786,453,874,452,875,836,789,836",
  "/townhomes/parkview/2001": "1069,453,1137,453,1196,511,1196,835,1067,835",

  "/townhomes/apium/2232": "495,125,495,249,817,251,817,162,776,125",
  "/townhomes/apium/2233": "496,257,815,256,818,337,496,337",
  "/townhomes/apium/2234": "496,344,817,344,820,430,497,429",
  "/townhomes/apium/2237": "496,635,819,634,819,716,495,716"
};

export const TownhomesMapPage = props => {
  const { history, match } = props;
  const api = useApi();
  // const neighbourhoods = api.neighbourhoods.list();
  // const [masterplan] = neighbourhoods.data || [];
  const townhomes = api.townhomes.list();
  const availableTownhomes = townhomes.filter(
    townhome => townhome.lots.filter(lot => lot.status === 1).length
  );
  const defaultLevel = {
    image: { url: masterplanImage },
    coords: availableTownhomes.map(townhome => ({
      ...townhome,
      ...RELEASES[townhome.slug]
    }))
  };

  const { slug } = match.params;
  const release = slug
    ? {
        ...availableTownhomes.find(sift({ slug })),
        ...RELEASES[slug],
        legend: [
          {
            label: "Available",
            color: "#90CEC9"
          },
          {
            label: "Sold",
            color: "#357972"
          }
        ]
      }
    : null;

  const levels = release ? [release, defaultLevel] : [defaultLevel];
  const packages = release
    ? api.packages.list({ release: release.slug })
    : null;

  if (release) {
    release.coords = release.lots.map(lot => {
      const [pkg] = lot.packages;
      if (pkg) {
        lot.package = packages.find(sift({ id: pkg.id }));
        lot.coords = COORDS[lot.slug];
      }
      return lot;
    });
  }

  const onClick = polygon => {
    const release = polygon;
    const pkg = polygon.package;
    if (release.propertyType === "townhome") {
      history.push(config.routes.get("townhomes", release.slug));
    } else if (pkg) {
      history.push(config.routes.get("houseandland", pkg.slug));
    }
  };

  // coords
  let selectBuilders = [];
  let selectOptions = [];
  let sienna = document.createElement("option");
  let burbank = document.createElement("option");

  useEffect(() => {
    selectBuilders = document.querySelector(".ta-builder select"); // eslint-disable-line
    selectOptions = selectBuilders.options; // eslint-disable-line

    sienna.value = "sienna_homes";
    sienna.innerHTML = "Sienna Homes";
    burbank.value = "burbank_urban";
    burbank.innerHTML = "Burbank Urban";

    if (selectBuilders) {
      for (let i = 0; i < selectOptions.length; i++) {
        if (
          // selectOptions[i].label.indexOf("") === -1 &&
          selectOptions[i].label.indexOf("Search") === -1 &&
          selectOptions[i].label.indexOf("Sienna") === -1
        ) {
          selectOptions[i].remove();
          i--;
        }
      }
      selectBuilders.appendChild(sienna);
      selectBuilders.appendChild(burbank);
    }
  }, []);

  return (
    <>
      <Content className={styles.townhomes}>
        <ContentLeftBar>
          <Link
            to={config.routes.get("property.results", "?type=townhome")}
            className={styles.listview}
          >
            <Icon icon="listview" />
          </Link>
          <Link to={config.routes.get("townhomes")} className={styles.mapview}>
            <Icon icon="mapviewActive" />
          </Link>
        </ContentLeftBar>
        {release ? <BackButton to={config.routes.get("townhomes")} /> : null}
        <ContentHeader>
          <Map levels={levels} onClick={onClick} showLabel />
        </ContentHeader>
        <ContentFooter>
          {release ? (
            <>
              <h1 className={styles.title}>{release.name} Release Townhomes</h1>
              <PackagesList packages={packages} />
            </>
          ) : (
            <>
              <h1 className={styles.title}>Featured Townhomes</h1>
              {availableTownhomes.map(release => {
                const { slug, title, hero_image } = RELEASES[release.slug];
                // const { slug, title, hero_image } = release;
                return (
                  <Link
                    key={title}
                    to={config.routes.get("townhomes", slug)}
                    className={styles.release}
                  >
                    <img
                      src={hero_image.url}
                      alt={title}
                      className={styles.image}
                      width="20px"
                    />{" "}
                    <p className={styles.title}>{title}</p>
                  </Link>
                );
              })}
            </>
          )}
        </ContentFooter>
        {release ? (
          <Sidebar className={styles.sidebar}>
            <h1 className={styles.h1}>Townhomes</h1>
            <h2 className={styles.h2}>{release.title}</h2>
            <img
              src={release.hero_image && release.hero_image.url}
              alt={release.name}
              className={styles.render}
              width="100%"
            />
            <p>{release.description}</p>
            <h5 className={styles.h5}>Inclusions</h5>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  release.inclusions &&
                  release.inclusions.replace(/\n/g, "<br />")
              }}
            />
          </Sidebar>
        ) : null}
      </Content>

      <style>
        {`.ta-has-results .ta-filter--heading h2:after {
          content: 'Townhomes';
        }
        .ta-filter--extra .ta-filter--extra__submit .ta-btn {
          color: transparent;
        }
        .ta-filter--extra .ta-filter--extra__submit .ta-btn:after {
          content: 'Search Townhomes';
          color: initial;
          position: absolute;
          left: 0;
          right: 0;
        }
        `}
      </style>
      <SearchPage
        showResults={true}
        onlyFiltersOnTheLeft={true}
        defaultQuery={{ type: "townhome" }}
        {...props}
      />
    </>
  );
};

export default TownhomesMapPage;
