import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import capitalize from "capitalize";

import {
  formatPrice,
  lowercaseAndParseSpaces
} from "traffic-property-app/utils";
import config from "traffic-property-app/config";

// Components
import { useEnquireForm } from "traffic-property-app/components/EnquiryForm";
import Button from "traffic-property-app/components/Button";
import Icon from "traffic-property-app/components/Icon";

import EstateLabel from "./EstateLabel";

import "./OldPackage.scss";

const NB_SLUG = config.get("api.neighbourhood");

const TYPES = config.get("propertyTypes");

//
// Status can be any integer from 1 to 4
//
// 1   =>  Available
// 2   =>  Sold
// 3   =>  On hold
// 4   =>  Builder
const STATUS = config.get("status");

//
// Attributes can be any integer from 1 to 15
//
// 1   =>  Corner Lot
// 2   =>  Standard Lot
// 3   =>  Irregular Lot
// 4   =>  Water Front
// 5   =>  Garden Front
// 6   =>  Golf Course
// 7   =>  Close to School
// 8   =>  Close to Child Care
// 9   =>  Close to Train Station
// 10  =>  Wetlands
// 11  =>  Park Front
// 12  =>  Swimming Pool
// 13  =>  Gym
// 14  =>  Passeggiata
// 15  =>  Extra Parking
const ATTRIBUTES = config.get("attributes");

// @TODO find a better name for the "view" props
export const Package = ({
  view,
  isDetail,
  isSlider,
  isLot,
  isSummary,
  releaseFlyer,
  showScrollToMap,
  className,
  onClick,
  userMenu,
  showNameAsLotName,
  ...props
}) => {
  const dispatch = userMenu[1];
  const toggleEnquire = useEnquireForm();

  // Use pkg variable name instead of package
  // since package is a reserved word
  const pkg = props.package || {};
  const propertyType = pkg.propertyType ? TYPES[pkg.propertyType] : null;

  const isLand = pkg.propertyType === "land";
  const isApartment = pkg.propertyType === "apartment";

  const lotName = isLand ? pkg.name : pkg.lot_name;
  const name = isLand ? STATUS[pkg.status] : pkg.name;
  const landSize = pkg.total_area || pkg.lot_area || pkg.area;
  const frontage = pkg.lot_frontage || pkg.frontage;
  const image =
    pkg.image && typeof pkg.image === "object" ? pkg.image.url : pkg.image;
  const flyer = pkg.flyer;
  const lotFlyer = pkg.lot_flyer;
  const attributes = pkg.attributes || [];

  const price = formatPrice(pkg.price);

  // @TODO add flags on the design
  const flag = config.flags(pkg.flags);
  const Type = isDetail || isLot ? "div" : Link;
  pkg.slug = pkg.slug ? pkg.slug.toString() : "";
  const packageLink = isLand
    ? config.routes.get(
        "landforsale.masterplan",
        NB_SLUG
          ? pkg.release_slug
          : `${pkg.neighbourhood_slug}/${pkg.release_slug}`,
        "lot",
        pkg.slug
      )
    : config.routes.get("houseandland", pkg.slug);

  const scrollToMap = () => {
    let zoomMap = document.getElementById("zoommap");
    if (zoomMap) {
      window.scrollTo({
        top: zoomMap.offsetTop,
        left: 0,
        behavior: "smooth"
      });
    } else {
      return false;
    }
  };

  const estateInfo = pkg.project_slug
    ? config.estates.get(pkg.project_slug)
    : {};
  const neighbourhoodInfo = pkg.neighbourhood_slug
    ? config.neighbourhoods.get(pkg.neighbourhood_slug)
    : {};
  const builderInfo = {
    logo: pkg.builder_logo,
    name: pkg.builders_name,
    slug: pkg.builders_slug
  };

  return (
    <>
      {!isSummary && (
        <div className={classnames("package", className, { isDetail, isLot })}>
          {!isDetail && !isLot ? (
            <Type to={packageLink} className="package--image" onClick={onClick}>
              <img
                src={image}
                alt={isLand ? lotName : name}
                width="100%"
                className="package--image__render"
              />
              <EstateLabel
                estate={estateInfo}
                neighbourhood={neighbourhoodInfo}
                builder={builderInfo}
              />
              {flag ? (
                <span className="package--image__flag">{flag}</span>
              ) : null}
            </Type>
          ) : null}
          <div className="package--content">
            <Type
              to={packageLink}
              className="package--subtitle"
              onClick={onClick}
            >
              <span className="package--subtitle__type">
                {isLot ? pkg.release_name : propertyType}
              </span>
              <span className="package--subtitle__options">
                {attributes.map(id => {
                  if (!ATTRIBUTES[id]) return null;
                  const icon = lowercaseAndParseSpaces(ATTRIBUTES[id]);
                  return (
                    <Icon
                      key={icon}
                      icon={icon}
                      title={capitalize.words(icon)}
                    />
                  );
                })}
              </span>
            </Type>
            <Type to={packageLink} className="package--title" onClick={onClick}>
              <h3>
                <span>
                  {lotName ? (
                    <>
                      {pkg.propertyType === "house" ? "Lot " : ""}
                      {lotName}
                    </>
                  ) : (
                    name
                  )}
                </span>
                <span
                  className={classnames({
                    single: !lotName,
                    [name && name.toLowerCase()]: isLand,
                    hasAfter: isLot
                  })}
                >
                  {/* {lotName ? name : pkg.release_name || ""} */}
                  {lotName ? [3].includes(pkg.status) ? "House & Land Exclusive" : name : pkg.release_name || ""}
                </span>
                {isLot ? <span>{price}</span> : null}
              </h3>
              {showScrollToMap ? (
                <Icon icon="marker_orange" onClick={scrollToMap} />
              ) : null}
            </Type>
            {isApartment ? (
              <Type
                to={packageLink}
                className="package--details"
                onClick={onClick}
              >
                <span className="package--details__item">
                  <span className="package--details__label">Size</span>{" "}
                  {landSize}m²
                </span>
                <span className="package--details__item">
                  <span className="package--details__label">Level</span>{" "}
                  {pkg.level}
                </span>
                {/* @TODO add support to Apt. Size, Type, Aspect,  */}
                <span className="package--details__item">
                  <span className="package--details__label">Aspect</span>{" "}
                  {pkg.aspect}
                </span>
              </Type>
            ) : (
              <Type
                to={packageLink}
                className="package--details"
                onClick={onClick}
              >
                <span className="package--details__item">
                  <span className="package--details__label">Land size</span>{" "}
                  {landSize}m²
                </span>
                <span className="package--details__item">
                  <span className="package--details__label">Frontage</span>{" "}
                  {frontage}m
                </span>
                {/* @TODO add support to depth when available */}
                {/* <span className="package--details__item">
                  <span className="package--details__label">Depth:</span> 24m
                </span> */}
              </Type>
            )}
            {!isLand && (
              <Type
                to={packageLink}
                className="package--specs"
                onClick={onClick}
              >
                <span className="package--price">
                  <span className="package--price__from">From</span>
                  <span
                    className={classnames("package--price__number", {
                      "ta-no-price": price === "-"
                    })}
                  >
                    {price}
                    {isDetail && pkg.promoted ? "*" : ""}
                  </span>
                </span>
                <span className="package--amenities">
                  {pkg.bedrooms && (
                    <span>
                      {pkg.bedrooms} <Icon icon="bedroom" />
                    </span>
                  )}
                  {pkg.bathrooms && (
                    <span>
                      {pkg.bathrooms} <Icon icon="bathroom" />
                    </span>
                  )}
                  {pkg.living && (
                    <span>
                      {pkg.living} <Icon icon="living" />
                    </span>
                  )}
                  {pkg && pkg.garage !== 0 && (
                    <span>
                      {pkg.garage} <Icon icon="garage" />
                    </span>
                  )}
                </span>
              </Type>
            )}
            <div className="package--buttons">
              <Button
                size="small"
                onClick={e => {
                  if (typeof onClick === "function") onClick(e);
                  toggleEnquire({
                    ...pkg,
                    name: lotName
                      ? `${lotName}, ${name}`
                      : `${propertyType} ${name}, ${pkg.release_name}`,
                    url: packageLink
                  });
                }}
              >
                Enquire
              </Button>
              {!isDetail && (
                <>
                  <Button href={flyer} external color="secondary" size="small">
                    {isLot && "Lot "}Flyer
                  </Button>
                </>
              )}
              {isLot && (
                <Button
                  href={releaseFlyer}
                  external
                  color="secondary"
                  size="small"
                >
                  Release Flyer
                </Button>
              )}
              {(isLand || isDetail) && !isLot && (
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => dispatch.addCompare(pkg)}
                >
                  Compare
                </Button>
              )}
              {/* {(isLand || isDetail) && !isLot && (
                <Button
                  onClick={() => dispatch.addCompare(pkg)}
                  color="secondary"
                  size="small"
                  onClick={onClick}
                >
                  Compare
                </Button>
              )} */}
              {!isSlider && !isLot && !isDetail ? (
                <Button
                  href={packageLink}
                  color="secondary"
                  size="small"
                  onClick={onClick}
                >
                  View
                </Button>
              ) : null}
            </div>
            {isDetail && pkg.inclusions ? (
              <div className="package--inclusions">
                <h5>Inclusions</h5>
                <p dangerouslySetInnerHTML={{ __html: pkg.inclusions }} />
              </div>
            ) : null}
            {isDetail && pkg.building_amenities ? (
              <div className="package--inclusions">
                <h5>Building Amenities</h5>
                <p
                  dangerouslySetInnerHTML={{ __html: pkg.building_amenities }}
                />
              </div>
            ) : null}
            {isDetail && pkg.color_scheme ? (
              <div className="package--inclusions">
                <h5>Color Scheme</h5>
                <ul>
                  <li dangerouslySetInnerHTML={{ __html: pkg.color_scheme }} />
                </ul>
              </div>
            ) : null}
            {isDetail && (
              <div className="package--buttons package--buttons__downloads">
                {flyer && (
                  <Button
                    href={flyer}
                    external
                    icon="download"
                    color="secondary"
                    size="small"
                  >
                    Package
                  </Button>
                )}
                {lotFlyer && (
                  <Button
                    href={lotFlyer}
                    external
                    icon="download"
                    color="secondary"
                    size="small"
                  >
                    Lot
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {isSummary && (
        <div className={classnames("package--summary", { isSummary })}>
          <Link
            to={packageLink}
            className="package--summary--link"
            onClick={onClick}
          >
            <div className="package--summary--image">
              <img
                src={image}
                alt={isLand ? lotName : name}
                width="100%"
                className="package--summary--image__render"
              />

              {builderInfo.logo && (
                <img
                  src={builderInfo.logo}
                  alt={builderInfo.name}
                  className="package--summary--image__builder"
                />
              )}
            </div>
            <div className="package--summary--content">
              <div className="package--summary--title">
                <h3>
                  <span className="lot">
                    {showNameAsLotName ? pkg.name : `Lot ${lotName}`}
                  </span>
                  <span className="package--summary--price">
                    <span className="package--summary--price__from">From</span>
                    <span className="package--summary--price__number">
                      {price}
                    </span>
                  </span>
                </h3>
              </div>
              <div className="package--summary--specs">
                <span className="package--summary--amenities">
                  {pkg.bedrooms && (
                    <span>
                      {pkg.bedrooms} <Icon icon="bedroom" />
                    </span>
                  )}
                  {pkg.bathrooms && (
                    <span>
                      {pkg.bathrooms} <Icon icon="bathroom" />
                    </span>
                  )}
                  {pkg.living && (
                    <span>
                      {pkg.living} <Icon icon="living" />
                    </span>
                  )}
                  {pkg && pkg.garage !== 0 ? (
                    <span>
                      {pkg.garage} <Icon icon="garage" />
                    </span>
                  ) : null}
                </span>
              </div>
            </div>
          </Link>
        </div>
      )}
    </>
  );
};

export default Package;

// <Package detailed/>
// <Package summary/>
