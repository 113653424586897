import React from "react";
import classnames from "classnames";

import styles from "./Title.module.scss";

export const Title = ({ header, text, secondaryText, className, small }) => {
  return (
    <div
      className={classnames(className, styles.container, {
        [styles.small]: small
      })}
    >
      <h4 className={styles.header}>{header}</h4>
      <h1 className={styles.text}>{text}</h1>
      {secondaryText && (
        <h1 className={styles.secondaryText}>{secondaryText}</h1>
      )}
    </div>
  );
};

export default Title;
