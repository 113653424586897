import path from "path";
import { useContext } from "react";

import config from "traffic-property-app/config";

import { ApiProvider, Context } from "./ApiContext";

// models
import initNeighbourhoods from "./resources/neighbourhoods";
import initPackages from "./resources/packages";
import initLots from "./resources/lots";
import initTownhomes from "./resources/townhomes";
import initFiltersOptions from "./resources/filters";
import initBuildings from "./resources/buildings";

export const Api = ApiProvider;

const PROJECT_SLUG = config.get("api.project");
const NB_SLUG = config.get("api.neighbourhood");

let api = {};

export const useApi = () => {
  const [{ data, loading, error }, refetch] = useContext(Context);

  if (!api.packageSearch) {
    api = { data, loading, error, refetch };

    const { packageSearch, assetpath } = data || {};
    api.current = { assetpath };

    api.packageSearch = packageSearch;

    const parseUrl = url => {
      if (url) {
        if (url.indexOf("http") === 0) return url.toString();
        return `${assetpath}${path.join("/", url)}`;
      }

      return null;
    };

    const parseSlug = (pkg, { projectSlug, neighbourhoodSlug }) => {
      const id = pkg.slug || pkg.id;
      const propertyType = pkg.propertyType === "hal" ? "hl" : pkg.propertyType;
      const type = propertyType ? `${propertyType}/` : "";

      if (!PROJECT_SLUG && !NB_SLUG)
        return `${type}${projectSlug}/${neighbourhoodSlug}/${id}`;

      if (PROJECT_SLUG) return `${type}${neighbourhoodSlug}/${id}`;

      return `${type}${id.toString()}`;
    };

    const options = { parseUrl, parseSlug };

    //
    // api actions/filters
    //
    api.neighbourhoods = initNeighbourhoods(api, options);
    api.packages = initPackages(api, options);
    api.buildings = initBuildings(api, options);
    api.townhomes = initTownhomes(api, options);
    api.filters = initFiltersOptions(api, options);
    api.lots = initLots(api, options);
  }

  return api;
};

export default useApi;
