import { Routes as SearchRoutes } from "traffic-property-app/pages/explore/search";
import addScrollEngine from "./addScrollEngine";

export const Routes = SearchRoutes.map(route => {
  if (route.name === "Results") {
    return {
      ...route,
      component: addScrollEngine(route.component)
    };
  }

  return route;
});
