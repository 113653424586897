//
// PROPERTY TYPE ICONS
//
import houseandland from "traffic-property-app/assets/icons/property-types/houseandland.svg";
import apartment from "traffic-property-app/assets/icons/property-types/apartment.svg";
import land from "traffic-property-app/assets/icons/property-types/land.svg";
import townhome from "traffic-property-app/assets/icons/property-types/townhome.svg";
import houseandland_blank from "traffic-property-app/assets/icons/property-types/houseandland_blank.svg";
import apartment_blank from "traffic-property-app/assets/icons/property-types/apartment_blank.svg";
import land_blank from "traffic-property-app/assets/icons/property-types/land_blank.svg";
import townhome_blank from "traffic-property-app/assets/icons/property-types/townhome_blank.svg";
import search from "traffic-property-app/assets/icons/property-types/search.svg";
import living from "traffic-property-app/assets/icons/property-types/living.svg";
import bedroom from "traffic-property-app/assets/icons/property-types/bedrooms.svg";
import bathroom from "traffic-property-app/assets/icons/property-types/bathrooms.svg";
import garage from "traffic-property-app/assets/icons/property-types/garage.svg";

//
// ATTRIBUTES ICONS
//
import corner_lot from "traffic-property-app/assets/icons/attributes/corner_lot.svg";
import standard_lot from "traffic-property-app/assets/icons/attributes/standard_lot.svg";
import irregular_lot from "traffic-property-app/assets/icons/attributes/irregular_lot.svg";
import water_front from "traffic-property-app/assets/icons/attributes/water_front.svg";
import garden_front from "traffic-property-app/assets/icons/attributes/garden_front.svg";
import golf_course from "traffic-property-app/assets/icons/attributes/golf_course.svg";
import close_to_school from "traffic-property-app/assets/icons/attributes/school.svg";
import close_to_child_care from "traffic-property-app/assets/icons/attributes/childcare.svg";
import close_to_train_station from "traffic-property-app/assets/icons/attributes/trainstation.svg";
import wetlands from "traffic-property-app/assets/icons/attributes/wetlands.svg";
import park_front from "traffic-property-app/assets/icons/attributes/park_front.svg";
import swimming_pool from "traffic-property-app/assets/icons/attributes/swimming_pool.svg";
import gym from "traffic-property-app/assets/icons/attributes/gym.svg";
import passeggiata from "traffic-property-app/assets/icons/attributes/passeggiata.svg";
import extra_parking from "traffic-property-app/assets/icons/attributes/extra_parking.svg";
import dual_key from "traffic-property-app/assets/icons/attributes/dualkey.svg";
import terrace from "traffic-property-app/assets/icons/attributes/terrace.svg";
import investment from "traffic-property-app/assets/icons/attributes/investment.svg";
import balcony from "traffic-property-app/assets/icons/attributes/balcony.svg";

//
// Others
//
import arrow from "traffic-property-app/assets/icons/others/arrow.svg";
import arrow_orange from "traffic-property-app/assets/icons/others/arrow_orange.svg";
import chevron from "traffic-property-app/assets/icons/others/chevron.svg";
import floorplan from "traffic-property-app/assets/icons/others/floorplan.svg";
import floorplan_black from "traffic-property-app/assets/icons/others/floorplan_black.svg";
import marker from "traffic-property-app/assets/icons/others/marker.svg";
import marker_orange from "traffic-property-app/assets/icons/others/marker_orange.svg";
import download from "traffic-property-app/assets/icons/others/download.svg";
import loupe from "traffic-property-app/assets/icons/others/loupe.svg";
import email from "traffic-property-app/assets/icons/others/email.svg";
import whatsapp from "traffic-property-app/assets/icons/others/whatsapp.svg";
import facebook from "traffic-property-app/assets/icons/others/facebook.svg";
import close from "traffic-property-app/assets/icons/others/close.svg";
import close_grey from "traffic-property-app/assets/icons/others/close-grey.svg";
import close_black from "traffic-property-app/assets/icons/others/close-black.svg";
import filter from "traffic-property-app/assets/icons/others/filter.svg";
import share from "traffic-property-app/assets/icons/others/share.svg";
import minus from "traffic-property-app/assets/icons/others/minus.svg";
import check from "traffic-property-app/assets/icons/others/check.svg";
import trash from "traffic-property-app/assets/icons/others/trash.svg";
import house from "traffic-property-app/assets/icons/others/house.svg";

export const icons = {
  //
  // PROPERTY TYPE ICONS
  //
  houseandland,
  apartment,
  land,
  townhome,
  houseandland_blank,
  apartment_blank,
  land_blank,
  townhome_blank,
  search,
  living,
  bedroom,
  bathroom,
  garage,

  //
  // ATTRIBUTES ICONS
  //
  corner_lot,
  standard_lot,
  irregular_lot,
  water_front,
  garden_front,
  golf_course,
  close_to_school,
  close_to_child_care,
  close_to_train_station,
  wetlands,
  park_front,
  swimming_pool,
  gym,
  passeggiata,
  extra_parking,
  dual_key,
  terrace,
  investment,
  balcony,

  //
  // Others
  //
  chevron,
  download,
  floorplan,
  floorplan_black,
  marker,
  marker_orange,
  arrow,
  arrow_orange,
  loupe,
  email,
  whatsapp,
  filter,
  facebook,
  close,
  close_grey,
  close_black,
  share,
  minus,
  check,
  trash,
  house
};

export default icons;
