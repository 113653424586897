import React from "react";
import Swiper from "react-id-swiper";
import config from "traffic-property-app/config";

import Package from "traffic-property-app/components/Package";
import "./PackageSlider.scss";

// @TODO find a better name for the "view" props
export const PackageSlider = ({ packages = [], title, ...props }) => {
  const params = {
    setWrapperSize: true,
    slidesPerView: 3,
    spaceBetween: 48,
    slideClass: "package",
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    breakpoints: {
      992: {
        slidesPerView: 1,
        spaceBetween: 16
      },
      1680: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        slidesPerView: 2,
        spaceBetween: 48
      }
    }
  };

  if (!packages.length) return null;

  packages = packages.slice(0, 20);

  return (
    <section className="packageslider">
      <div className="ta-container">
        <div className="ta-row">
          <h4>{title}</h4>
          <Swiper {...params}>
            {packages.map((pkg, i) => (
              <Package
                key={i}
                package={pkg}
                isSlider
                view={config.routes.get("houseandland")}
              />
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default PackageSlider;
