import React, { useState } from "react";
import classnames from "classnames";

import QRCode from "./components/QRCode";
import EmailForm from "./components/EmailForm";

import styles from "./SendDetailsForm.module.scss";

export const SendDetailsForm = ({ package: pkg, className, ...props }) => {
  const [activeForm, setActiveForm] = useState(false);

  const toggleForm = name => {
    setActiveForm(name === activeForm || !name ? null : name);
  };

  if (!pkg) return null;

  return (
    <div {...props} className={classnames(styles.form, className)}>
      <button
        className="ta-btn ta-btn__large ta-btn__primary qr-button"
        onClick={() => toggleForm("qrcode")}
      >
        Send flyer to my mobile
      </button>
      <EmailForm
        package={pkg}
        show={activeForm === "email"}
        toggleForm={toggleForm}
      />
      <QRCode
        link={pkg.flyer || pkg.lot_flyer}
        show={activeForm === "qrcode"}
        toggleForm={toggleForm}
      />
    </div>
  );
};

export default SendDetailsForm;
