import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import config from "traffic-property-app/config";

import Icon from "traffic-property-app/components/Icon";
import { withUserMenu } from "traffic-property-app/components/UserMenu";

import { Package as OldPackage } from "./OldPackage";

// components
import Title from "./components/Title";
import Price from "./components/Price";

import styles from "./Package.module.scss";

// globals
const TYPES = config.get("propertyTypes");
const NB_SLUG = config.get("api.neighbourhood");

export const PackageComponent = props => {
  const { data, propertyToCompare = true, summary, onClick, className } = props;
  const classes = { [styles.summary]: summary };
  if (summary) {
    const { name, image, price, promoted, release_name } = data;
    const secondaryText = release_name.replace(" Release", "");
    const isLand = data.propertyType === "land";
    const propertyType = TYPES[data.propertyType];

    const packageLink = isLand
      ? config.routes.get(
          "landforsale.masterplan",
          NB_SLUG
            ? data.release_slug
            : `${data.neighbourhood_slug}/${data.release_slug}`,
          "lot",
          data.slug
        )
      : config.routes.get("houseandland", data.slug);

    const propertResultsPath = config.routes.get("property.results");

    return (
      <div className={classnames(styles.package, classes, className)}>
        <Link to={packageLink} alt="" onClick={onClick}>
          <div className={classnames(styles.header, "ta-row")}>
            <div
              style={{ backgroundImage: `url(${image})` }}
              className={styles.image}
            />
            <Title
              header={propertyType}
              text={name}
              secondaryText={secondaryText}
              className={styles.title}
              small
            />
          </div>
          <div className={classnames(styles.specs, "ta-row")}>
            <Price number={price} promoted={promoted} />
            {!isLand && (
              <span className="package--amenities">
                {data.bedrooms && (
                  <span>
                    {data.bedrooms} <Icon icon="bedroom" />
                  </span>
                )}
                {data.bathrooms && (
                  <span>
                    {data.bathrooms} <Icon icon="bathroom" />
                  </span>
                )}
                {data.living && (
                  <span>
                    {data.living} <Icon icon="living" />
                  </span>
                )}
                {data && data.garage !== 0 && (
                  <span>
                    {data.garage} <Icon icon="garage" />
                  </span>
                )}
              </span>
            )}
          </div>
        </Link>
        {propertyToCompare ? (
          <div className="ta-row">
            <div className={styles.actionButton}>
              {/* <span className={styles.emphasize}>100+</span>
            Others interested */}
            </div>
            <Link
              to={`${propertResultsPath}?similar_properties=${data.slug}`}
              className={styles.actionButton}
              onClick={onClick}
            >
              <span className={styles.emphasize}>
                <Icon icon="house" />
              </span>
              Similar properties
            </Link>
          </div>
        ) : null}
      </div>
    );
  }

  return <OldPackage {...props} />;
};

export const Package = props => withUserMenu(PackageComponent)(props);
export default Package;
