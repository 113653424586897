import React from "react";
import classnames from "classnames";

// state
import withUserMenu from "./UserMenu.store";

// components
import Icon from "traffic-property-app/components/Icon";

// components
import RecentSearches from "./components/RecentSearches";
import RecentlyViewed from "./components/RecentlyViewed";
import Compare from "./components/Compare";

import styles from "./UserMenu.module.scss";

const menu = ["recently-viewed", "searches", "compare"];

const UserMenuComponent = ({ userMenu, className, ...props }) => {
  const [state, dispatch] = userMenu;
  const { show, active } = state;
  const toggle = type => e => dispatch.toggle(type);

  const showClass = {
    [styles.show]: show && active
  };

  const [defaultID] = !active ? menu : [];

  return (
    <div className="ta-user-menu">
      <div
        onClick={toggle(false)}
        className={classnames(styles.backdrop, showClass)}
      />
      <div className={classnames(styles.container, className, showClass)}>
        <div className={styles.menuWrapper}>
          <ul className="ta-container">
            <li className={styles.title}>MY</li>
            {menu.map(id => {
              const name = id.split("-").join(" ");
              const isActive = show && active === id;

              return (
                <li
                  key={id}
                  className={classnames({ [styles.active]: isActive })}
                >
                  <button onClick={toggle(id)}>{name}</button>
                </li>
              );
            })}
            <li className={styles.close}>
              <button
                className={styles.closeButton}
                onClick={toggle(defaultID)}
              >
                <Icon icon="chevron" />
              </button>
            </li>
          </ul>
        </div>
        <div className={styles.content}>
          {active ? (
            <div className={classnames(styles.titleContainer, "ta-container")}>
              <div className="ta-row">
                <h1 className={classnames(styles.title, styles[active])}>
                  {active === "searches"
                    ? "Recent Searches"
                    : active === "recently-viewed"
                    ? "Recently Viewed"
                    : active === "compare"
                    ? "Compare List"
                    : null}
                </h1>
              </div>
            </div>
          ) : null}

          <div
            className={classnames(styles.wrapper, {
              [styles.recentlyViewed]: active === "recently-viewed"
            })}
          >
            {active === "searches" ? (
              <div className="ta-container">
                <RecentSearches onPackageClick={toggle(false)} />
              </div>
            ) : active === "compare" ? (
              <Compare onPackageClick={toggle(false)} />
            ) : active === "recently-viewed" ? (
              <RecentlyViewed onPackageClick={toggle(false)} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export const UserMenu = withUserMenu(UserMenuComponent);
export default UserMenu;
