import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import Modal from 'components/Modal';
import styles from './Video.module.scss';

import playbutton from 'assets/images/icons/video-play-button.svg';

export const Video = ({ video, title, duration, screen }) => {
  // console.log(video);
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, []);
  if (!video) return null;
  return (
    <>
      <div
        className={styles.video}
        onClick={() => {
          toggleOpen();
        }}
      >
        <div
          className={styles.screen}
          style={{ backgroundImage: `url('${screen}')` }}
        >
          <div className={styles.inner}>
            <div className={styles.play}>
              <img src={playbutton} alt='video play button' />
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <h3>{title}</h3>
          <span>{duration} mins</span>
        </div>
      </div>
      <Modal open={open} toggleOpen={toggleOpen}>
        <ReactPlayer controls={true} playing={open} url={video} />
      </Modal>
    </>
  );
};

export default Video;
