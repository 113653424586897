import React, { useEffect, useState, useRef } from "react";
import scrollToComponent from "react-scroll-to-component";

import config from "traffic-property-app/config";
// import dot from "dot-object";

// Hooks
import useApi from "traffic-property-app/api";

// Components
import BackButton from "traffic-property-app/components/BackButton";
import Icon from "traffic-property-app/components/Icon";
import PackageSlider from "traffic-property-app/components/PackageSlider";
import Package from "traffic-property-app/components/Package";
import Advertising from "traffic-property-app/components/Advertising";
import SocialSharing from "traffic-property-app/components/SocialSharing";
import Gallery from "traffic-property-app/components/Gallery";
import { withUserMenu } from "traffic-property-app/components/UserMenu";

import PackagePlan from "./components/PackagePlan";

import "./details.scss";

const PackageDetailsPageComponent = ({
  userMenu,
  match,
  location,
  package: pkgProp
}) => {
  const mapRef = useRef(null);
  const api = useApi();
  const [planImage, setPlanImage] = useState();
  const [galleryIndex, setGalleryIndex] = useState(-1);
  const toggleGallery = index => () => setGalleryIndex(index);

  const { slug } = match.params;
  const dispatch = userMenu[1];

  const pkg = pkgProp
    ? pkgProp
    : api.packages.retrieve(slug, { extend: "building" });

  useEffect(() => {
    if (typeof window.client === "function" && pkg) {
      // @TODO remove this when we added to the plugin configurations
      // this is a quick fix for now
      if (pkg.project_slug === "jubilee") window.client("456503fe");
      if (pkg.project_slug === "silverwoods") window.client("4b84d9ee");
    }

    if (pkg) {
      dispatch.addRecentlyViewed(pkg);
    }
  }, [dispatch, pkg]);

  // @TODO add 404 page
  if (!pkg) return "Package not found";

  const isApartment = pkg.propertyType === "apartment";
  const isTownhome = pkg.propertyType === "townhome";

  const images = [
    {
      image: pkg.image,
      icon: "loupe"
    },
    ...(pkg.gallery || [])
  ];
  
  const isKiosk = location.path.includes("Masterplan")
  const lotplan = 
    {
      image: pkg.gallery && pkg.gallery[pkg.gallery.length - 1],
      icon: "loupe",
      number: pkg.gallery && pkg.gallery.length
    };

  if (pkg.floor_image || pkg.floorplan_image)
    images.push({
      image: pkg.floor_image || pkg.floorplan_image,
      icon: "floorplan"
    });

  if (planImage)
    images.push({
      image: planImage,
      icon: "marker"
    });

  const mapRefIsHidden = mapRef.current && mapRef.current.offsetParent;

  const scrollToMap = () => {
    if (mapRef && mapRef.current) {
      scrollToComponent(mapRef.current, {
        offset: 0,
        align: "top",
        duration: 700,
        ease: "outQuad"
      });
    }
  };

  const flag = config.flags(pkg.flags);

  const propertyType =
    pkg.propertyType === "house" ? "house and land" : pkg.propertyType;

  const adSlug = pkg.slug
    .replace("ap/", "")
    .replace("hl/", "")
    .replace("th/", "");

  return (
    <>
      <div className="ta-container">
        <div className="ta-row">
          <BackButton
            title="results"
            defaultTo={config.routes.get("property.results")}
          />
          <SocialSharing />
        </div>
        <div className="ta-row">
          <section className="packagedetail">
            {/* @TODO add "back to results" button */}
            <Package package={pkg} isDetail />

            <div className="packagedetail--content">
              <div className="packagedetail--gallery">
                <div
                  className="packagedetail--gallery__left"
                  onClick={toggleGallery(0)}
                >
                  <img src={pkg.image} alt={pkg.name} />
                  {flag && (
                    <span className="packagedetail--gallery__flag">{flag}</span>
                  )}
                  {pkg.builder_logo && (
                    <img
                      src={pkg.builder_logo}
                      alt={pkg.builders_name}
                      width="100px"
                      className="packagedetail--gallery__builder"
                    />
                  )}
                </div>
                <div className="packagedetail--gallery__right">
                  {images.map((image, i) => {
                    if (image.icon) {
                      return (
                        <div
                          key={image.image}
                          className="packagedetail--gallery__thumb"
                          style={{ backgroundImage: `url(${image.image})` }}
                          onClick={
                            image.icon === "marker" && mapRefIsHidden
                              ? scrollToMap
                              : toggleGallery(i)
                          }
                        >
                          <span className="packagedetail--gallery__action">
                            <Icon icon={image.icon} className={image.icon} />
                          </span>
                        </div>
                      );
                    }

                    return null;
                  })}

                  {
                    lotplan && isTownhome && isKiosk &&
                    <div
                      key={lotplan.image}
                      className="packagedetail--gallery__thumb"
                      style={{ backgroundImage: `url(${lotplan.image})` }}
                      onClick={
                        lotplan.icon === "marker" && mapRefIsHidden
                          ? scrollToMap
                          : toggleGallery(lotplan.number)
                      }
                    >
                      <span className="packagedetail--gallery__action">
                        <Icon icon={lotplan.icon} className={lotplan.icon} />
                      </span>
                    </div>
                  }
                </div>
                <div className="packagedetail--gallery__bottom">
                  <div
                    className="packagedetail--gallery__viewfloorplan"
                    onClick={toggleGallery(images.length - 2)}
                  >
                    <Icon icon="floorplan_black" /> View floor plan
                  </div>
                  <div className="packagedetail--gallery__count">
                    1 of {images.length}
                  </div>
                </div>
              </div>

              {pkg.inclusions ? (
                <div className="package--inclusions">
                  <h5>Inclusions</h5>
                  <p dangerouslySetInnerHTML={{ __html: pkg.inclusions }} />
                </div>
              ) : null}
              {pkg.building_amenities ? (
                <div className="package--inclusions">
                  <h5>Building Amenities</h5>
                  <p
                    dangerouslySetInnerHTML={{ __html: pkg.building_amenities }}
                  />
                </div>
              ) : null}
              {pkg.color_scheme ? (
                <div className="package--inclusions">
                  <h5>Color Scheme</h5>
                  <ul>
                    <li
                      dangerouslySetInnerHTML={{ __html: pkg.color_scheme }}
                    />
                  </ul>
                </div>
              ) : null}

              <div className="ad--wrapper">
                <Advertising
                  className="ad--item"
                  query={[
                    { type: pkg.propertyType, value: adSlug },
                    { type: "release", value: pkg.release_slug },
                    { type: "neighbourhood", value: pkg.neighbourhood_slug }
                  ]}
                  height={180}
                />
              </div>
              <div ref={mapRef} className="packagedetail--map">
                <h4>
                  {isApartment
                    ? "FLOOR PLATE"
                    : isTownhome
                    ? "STREET PLAN"
                    : "LOT PLAN"}
                </h4>
                <PackagePlan package={pkg} getMainImage={setPlanImage} />
              </div>
            </div>
          </section>
        </div>
      </div>

      <PackageSlider packages={pkg.similar} title={`Similar ${propertyType}`} />

      <Gallery
        slides={images}
        show={galleryIndex > -1}
        startIndex={galleryIndex}
        onClose={toggleGallery(-1)}
      />
    </>
  );
};

export const PackageDetailsPage = withUserMenu(PackageDetailsPageComponent);
export default PackageDetailsPage;
