import React, { createContext, useContext } from "react";
import sift from "sift";
import createPersistedState from "use-persisted-state";
import { useRematchReducer } from "use-rematch-reducer";

const useLocalState = createPersistedState("traffic-app_user-menu");
const UserMenuContext = createContext();

const INITIAL_STATE = {
  recentlyViewed: [],
  recentSearches: [],
  compare: []
};

const store = (defaultState, sync) => ({
  name: "user-menu-store",
  state: { ...INITIAL_STATE, ...defaultState, show: false, active: null },
  reducers: {
    toggle: (state, type) => {
      const isBoolean = typeof type === "boolean";
      let show = isBoolean ? type : !state.show;
      let active = state.active;

      if (!isBoolean && type && active !== type) {
        active = type;
        show = true;
      }

      return {
        ...state,
        show,
        active
      };
    },
    addRecentlyViewed: (state, pkg) => {
      const exists = state.recentlyViewed.find(sift({ id: pkg.id }));
      let recentlyViewed =
        pkg && !exists ? [pkg, ...state.recentlyViewed] : state.recentlyViewed;

      if (pkg && !exists) {
        recentlyViewed = recentlyViewed.map(pkg => ({ ...pkg, similar: null }));
      }
      if (recentlyViewed.length >= 4) recentlyViewed.length = 4;

      const newState = recentlyViewed
        ? {
            ...state,
            recentlyViewed
          }
        : state;

      return sync(newState);
    },
    addRecentSearch: (state, pkg) => {
      const newState = pkg
        ? {
            ...state,
            recentSearches: [...state.recentSearches, pkg]
          }
        : state;

      return sync(newState);
    },
    addCompare: (state, pkg) => {
      const exists = state.compare.find(sift({ slug: pkg.slug }));
      const compare = pkg && !exists ? [...state.compare, pkg] : state.compare;
      if (compare.length >= 25) compare.length = 25;

      const newState = pkg
        ? {
            ...state,
            compare,
            active: "compare",
            show: true
          }
        : state;

      return sync(newState);
    },
    removeCompare: (state, slug) => {
      const newState = slug
        ? {
            ...state,
            compare: state.compare.filter(item => {
              return item.slug !== slug;
            })
          }
        : state;

      return sync(newState);
    }
  }
});

export const UserMenuProvider = ({ children }) => {
  const [defaultState, setState] = useLocalState(INITIAL_STATE);
  const sync = state => {
    setState(state);
    return state;
  };
  const [state, dispatch] = useRematchReducer(store(defaultState, sync));

  return (
    <UserMenuContext.Provider value={[state, dispatch]}>
      {children}
    </UserMenuContext.Provider>
  );
};

export const withUserMenu = Component => props => {
  const [state, dispatch] = useContext(UserMenuContext);
  return <Component {...props} userMenu={[state, dispatch]} />;
};

export default withUserMenu;
