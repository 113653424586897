import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import sift from "sift";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import config from "traffic-property-app/config";

// Components
import Button from "traffic-property-app/components/Button";

import Navigation from "./Navigation";
// import Map from "./Map";
import Map from "../Map";
import SocialSharing from "traffic-property-app/components/SocialSharing";
import Advertising from "traffic-property-app/components/Advertising";

import "./Masterplan.scss";

const NB_SLUG = config.get("api.neighbourhood");

const parseItems = options => item => {
  const {
    name,
    coords,
    landforsale: isNeighbourhood,
    lots: isRelease,
    ...rest
  } = item;

  item.slug = item.slug ? item.slug.toString() : "";
  const { neighbourhood, release } = options;

  const isLot = !isNeighbourhood && !isRelease;
  const path =
    release && !NB_SLUG
      ? `${neighbourhood.slug}/${release.slug}`
      : release && NB_SLUG
      ? release.slug
      : neighbourhood && !NB_SLUG
      ? neighbourhood.slug
      : "";

  return {
    name,
    slug: item.slug,
    coords,
    href: isLot
      ? config.routes.get("landforsale.masterplan", path, "lot", item.slug)
      : config.routes.get("landforsale.masterplan", path, item.slug),
    releases: isNeighbourhood,
    lots: isRelease,
    ...rest
  };
};

export const Masterplan = withRouter(
  ({
    className,
    navigation: showNav,
    neighbourhoods: { project, assetpath, data },
    selectedNeighbourhood,
    selectedRelease,
    selectedLot,
    title,
    getActiveImage,
    showFilters,
    noAdvancedSearch,
    isResultPage,
    history,
    defaultLegends = {},
    onMapClick: onMapClickProp,
    ...props
  }) => {
    const initialRender = useRef();
    const [hovered, setHovered] = useState(null);

    const onHover = slug => {
      if (hovered !== slug) setHovered(slug);
    };

    // Only show neighbourhoods that contains landforsale
    data = data.filter(neighbourhood => neighbourhood.landforsale.length > 0);

    let image = {},
      mapItems = [];

    let lot = null;

    const neighbourhood = selectedNeighbourhood
      ? data.find(({ slug }) => slug === selectedNeighbourhood)
      : null;

    const release =
      neighbourhood && selectedRelease
        ? neighbourhood.landforsale.find(({ slug }) => slug === selectedRelease)
        : neighbourhood && !selectedRelease
        ? neighbourhood.landforsale.find(release => {
            const containsLot = release.lots.find(
              ({ slug }) => slug === selectedLot
            );

            if (containsLot) {
              lot = containsLot;
              return true;
            }

            return false;
          })
        : null;

    let coordMapLevels = [];

    lot = lot
      ? lot
      : release && selectedLot
      ? release.lots.find(({ slug }) => slug === selectedLot)
      : null;

    const buildMapItems = parseItems({ neighbourhood, release });
  
    const legend = [
      {
        label: "Available",
        className: "available"
      },
      {
        label: "Deposit Taken",
        className: "hold"
      },
      {
        label: "House & Land Exclusive",
        className: "builders"
      },
      {
        label: "Sold",
        className: "sold"
      },
      {
        label: "Coming Soon",
        className: "coming_soon"
      }
    ];

    if (project) {
      mapItems = data.map(buildMapItems);

      if (mapItems.length > 1) {
        if (!title) title = "Masterplan";

        image = {
          src: project.image.url,
          width: project.image.width,
          height: project.image.height
        };

        coordMapLevels = [
          {
            image: project.image,
            coords: mapItems,
            legend: defaultLegends.masterplan || legend
          },
          ...coordMapLevels
        ];
      }
    }

    if (neighbourhood) {
      if (!title) title = neighbourhood.name;
      mapItems = neighbourhood.landforsale
        .map(buildMapItems)
        // @NOTE this is a fix to not hide published (disabled on the backend side)
        // releases on the Masterplan
        .filter(
          release =>
            !release.published &&
            release.lots.filter(lot => lot.status !== 2).length
        );

      image = {
        src: neighbourhood.image.url,
        width: neighbourhood.image.width,
        height: neighbourhood.image.height
      };

      coordMapLevels = [
        {
          image: neighbourhood.image,
          coords: release
            ? [mapItems.find(sift({ coords: release.coords }))]
            : mapItems,
          legend: defaultLegends.neighbourhood || legend
        },
        ...coordMapLevels
      ];
    }

    if (release) {
      if (!title) title = release.name;
      mapItems = release.lots.map(buildMapItems);

      image = {
        src: release.image.url,
        width: release.image.width,
        height: release.image.height
      };

      coordMapLevels = [
        {
          image: release.image,
          coords: lot
            ? [mapItems.find(sift({ coords: lot.coords }))]
            : mapItems,
          legend: defaultLegends.release || legend
        },
        ...coordMapLevels
      ];
    }

    if (lot) {
      if (!title) title = lot.name;

      image = {
        src: lot.image.url,
        width: lot.image.width,
        height: lot.image.height
      };

      coordMapLevels = [
        {
          image: lot.image,
          legend: defaultLegends.lot || legend
        },
        ...coordMapLevels
      ];
    }

    const onMapClick = polygon => {
      // console.log("polygon", polygon);
      if (typeof onMapClickProp === "function") onMapClickProp(polygon);
      if (polygon.status !== 2) history.push(polygon.href);
    };

    useEffect(() => {
      if (!initialRender.current) {
        if (typeof getActiveImage === "function") getActiveImage(image);
        initialRender.current = true;
      }
    }, [getActiveImage, image]);

    const masterplanFlyer = neighbourhood
      ? neighbourhood.masterplan_flyer
      : project.masterplan_flyer;
    const designGuidelinesFlyer = neighbourhood
      ? neighbourhood.design_guidelines_flyer
      : project.design_guidelines_flyer;
    const duediligenceFlyer = neighbourhood
      ? neighbourhood.duediligence_flyer
      : project.duediligence_flyer;

    if (!neighbourhood && selectedNeighbourhood)
      return "Neighbourhood doesn't exists";
    if (!release && selectedRelease) return "Release doesn't exists";
    if (!lot && selectedLot) return "Lot doesn't exists";


    return (
      <section className={classnames(className, "lfs", { isLot: lot })}>
        <div className="ta-container">
          <div className="ta-row">
            <SocialSharing />
          </div>
          <div className="ta-row">
            {showNav && (
              <Navigation
                title={title}
                menu={mapItems}
                onHover={onHover}
                hovered={hovered}
                release={release}
                lot={lot}
                assetPath={assetpath}
                showFilters={showFilters}
                noAdvancedSearch={noAdvancedSearch}
                isResultPage={isResultPage}
              />
            )}
            <div
              className={classnames("lfs--map", {
                "no-nav": !showNav,
                [`ta-${selectedRelease}`]: release && release.slug,
                [`ta-neighbourhood--${selectedNeighbourhood}`]:
                  neighbourhood && neighbourhood.slug
              })}
            >
              <Map levels={coordMapLevels} onClick={onMapClick} showLabel />
              {masterplanFlyer || designGuidelinesFlyer || duediligenceFlyer ? (
                <div className="lfs--downloads">
                  <h4>Downloads</h4>
                  {masterplanFlyer && (
                    <Button
                      size="small"
                      href={masterplanFlyer}
                      icon="download"
                      color="secondary"
                      external
                    >
                      Masterplan
                    </Button>
                  )}
                  {designGuidelinesFlyer && (
                    <Button
                      size="small"
                      href={designGuidelinesFlyer}
                      icon="download"
                      color="secondary"
                      external
                    >
                      Design guidelines
                    </Button>
                  )}
                  {duediligenceFlyer && (
                    <Button
                      size="small"
                      href={duediligenceFlyer}
                      icon="download"
                      color="secondary"
                      external
                    >
                      Due Diligence
                    </Button>
                  )}
                </div>
              ) : null}
              {lot ? (
                <Advertising
                  className="ad"
                  query={[
                    { type: "land", value: `${lot.release_slug}/${lot.slug}` },
                    { type: "release", value: lot.release_slug },
                    { type: "neighbourhood", value: lot.neighbourhood_slug }
                  ]}
                  height={200}
                />
              ) : null}
            </div>
          </div>
        </div>
      </section>
    );
  }
);

Masterplan.propTypes = {
  navigation: PropTypes.bool
};

export default Masterplan;
