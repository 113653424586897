import React, { useState, useEffect } from "react";
import classnames from "classnames";
import numeral from "numeral";

import SelectRange from "./SelectRange";
import HorizontalRange from "./HorizontalRange";

import "./Range.scss";

export const Range = ({
  name,
  label,
  from,
  to,
  step = 1,
  onChange,
  value: valueProp,
  currency,
  suffix,
  minOptionLabel,
  maxOptionLabel,
  ...props
}) => {
  const [options, setOptions] = useState([]);
  if (!name) name = label.toLowerCase();

  const handleChange = (value, e) => {
    if (typeof onChange === "function") onChange({ name, value }, e);
  };

  useEffect(() => {
    // initialize all the options
    if (!options.length) {
      const opts = [];
      for (let count = from; count <= to; count += step) {
        const format = count >= 1000000 ? "($0.00a)" : "($0a)";
        const name = currency
          ? numeral(count).format(
              typeof currency === "string" ? currency : format
            )
          : count;
        opts.push({ name, value: count });
      }

      setOptions(opts);
    }
  }, [currency, from, options.length, step, to]);

  if (!options.length) return null;

  // @NOTE if the options length is greater than 7
  // it won't look nice if we show as horizontal
  // so in that case we show 2 selects
  const isSelectRange = options.length > 7;
  if (valueProp && typeof valueProp !== "object") valueProp = [valueProp];
  if (!valueProp && !isSelectRange) valueProp = [from, to];

  // range is an array which contains [min, max]
  const range = Object.assign(valueProp || [], []);

  const className = classnames("range", props.className, {
    "range--checkbox": !isSelectRange,
    "range--select": isSelectRange
  });

  return (
    <div className={className}>
      {isSelectRange ? (
        <SelectRange
          name={name}
          options={options}
          onChange={handleChange}
          minOptionLabel={minOptionLabel}
          maxOptionLabel={maxOptionLabel}
          value={range}
          step={step}
          suffix={suffix}
        />
      ) : (
        <HorizontalRange
          name={name}
          options={options}
          onChange={handleChange}
          value={range}
        />
      )}
    </div>
  );
};

export const UncontrolledRange = props => {
  const [state, setState] = useState();
  const onChange = ({ value }) => setState(value);
  return <Range {...props} value={state} onChange={onChange} />;
};

export default Range;
