import React from "react";

import "./Select.scss";

export const Select = ({
  name,
  label,
  options,
  min,
  max,
  step,
  className,
  value = "",
  ...props
}) => {
  if (!name) name = label.toLowerCase();
  const onChange = e => {
    const { name, value } = e.target;

  
    if (typeof props.onChange === "function") {
      props.onChange({ name, value }, e);
    }
  };

  return (
    <div className="select single">
      <select {...props} value={value} name={name} onChange={onChange}>
        {options.map(({ name, value, slug }, i) => (
          <option key={i} value={value || slug}>
            {name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
