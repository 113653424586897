import React, { useState, useEffect } from "react";
// import classnames from "classnames";
import Swiper from "react-id-swiper";

// components
import withUserMenu from "traffic-property-app/components/UserMenu/UserMenu.store";

// components
import Icon from "traffic-property-app/components/Icon";
import Package from "traffic-property-app/components/Package";

import styles from "./Compare.module.scss";

export const CompareComponent = ({ userMenu, onPackageClick }) => {
  const [swiper, setSwiper] = useState();
  const [state, dispatch] = userMenu;
  const packages = state.compare || [];

  useEffect(() => {
    if (swiper) swiper.updateSlides();
  }, [packages.length, swiper]);

  const options = {
    slidesPerView: 4.1,
    mousewheel: true,
    spaceBetween: 20,
    freeMode: true,
    getSwiper: setSwiper,
    breakpoints: {
      790: {
        slidesPerView: 1.1,
        spaceBetween: 20
      },
      1200: {
        slidesPerView: 2.1,
        spaceBetween: 20
      },
      1545: {
        slidesPerView: 3.1,
        spaceBetween: 20
      }
    }
  };

  if (!state.compare) return null;

  return (
    <div className="ta-container">
      <div className={styles.container}>
        {packages.length ? (
          <Swiper {...options}>
            {packages.map(pkg => (
              <div key={pkg.slug} className={styles.package}>
                <div className={styles.toolbar}>
                  <button
                    className={styles.trash}
                    onClick={() => dispatch.removeCompare(pkg.slug)}
                  >
                    <Icon icon="trash" />
                  </button>
                </div>
                <Package
                  package={pkg}
                  className={styles.item}
                  onClick={onPackageClick}
                />
              </div>
            ))}
          </Swiper>
        ) : (
          <>
            <p className={styles.paragraph}>List is empty</p>
          </>
        )}
      </div>
    </div>
  );
};

export const Compare = withUserMenu(CompareComponent);
export default Compare;
