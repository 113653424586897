import sift from "sift";
import { queryBuilder } from "../utils";

export const initPackages = (api, { parseUrl }) => {
  return {
    list: options => {
      let { sort, ...query } = options || {};
      // Make the default sort the featured flag in desc order
      if (!sort) sort = "flags.featured-desc";

      const { current: data, filters } = api;
      let packages = data.packages || [];

      if (packages.length) {
        return queryBuilder(packages, { ...query, filters, sort });
      }

      return packages;
    },
    retrieve: (slugOrObject, options) => {
      const { extend } = options || {};
      let siftQuery =
        typeof slugOrObject === "object"
          ? slugOrObject
          : { slug: slugOrObject };

      let pkg = null;
      const { current: data } = api;
      const packages = data.packages || [];

      if (data && packages && packages.length) {
        // @NOTE package is a reserved word so use pkg instead
        pkg = packages.find(sift(siftQuery));

        if (pkg) {
          const isApartment = pkg.propertyType === "apartment";

          if (isApartment) {
            if (!pkg.floorplate) {
              const neighbourhood = api.neighbourhoods.retrieve(
                pkg.neighbourhood_slug
              );

              if (extend) {
                neighbourhood.apartments.find(building => {
                  const buildingContainsApartment = building.levels.find(
                    (level, i) => {
                      const levelContainsApartment = level.apartments.find(
                        apartment => {
                          return apartment.package_id === pkg.id;
                        }
                      );

                      if (levelContainsApartment) pkg.floor_number = i;

                      return levelContainsApartment;
                    }
                  );

                  if (buildingContainsApartment) pkg.building = building.slug;

                  return buildingContainsApartment;
                });
              }
            }
          }

          if (pkg.similar && pkg.similar.length) {
            pkg.similar = pkg.similar.map(similarPkg => {
              return (
                similarPkg &&
                packages.find(pkg => pkg && pkg.slug === similarPkg.slug)
              );
            });

            // @NOTE there are "similar" packages that are undefined
            pkg.similar = pkg.similar.filter(el => el);
          }
        }
      }

      return pkg;
    }
  };
};

export default initPackages;
