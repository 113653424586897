import { useEffect, useRef } from "react";
import Drag from "@mystroken/drag";

export const useScrollEngine = (elementRef, options = {}, currentY = 0 ) => {
  const animationFrame = useRef();
  // const [dragging, setDragging] = useState();
  const scrollSpeed = options.scrollSpeed || 0.5;
  const element = elementRef.current || {};

  useEffect(() => {
    if (elementRef.current) {
      const element = elementRef.current;
      const xBoundary = element.offsetWidth;
      const yBoundary = element.parentNode.offsetHeight || window.innerHeight;
      const maximumX = Math.max(0, element.scrollWidth - xBoundary);
      const maximumY = Math.max(0, element.scrollHeight - yBoundary);

      const options = {
        listener: element,
        multiplier: 2
      };

      let currentX = 0;
      // let currentY = 0;
      const drag = new Drag(options);
      drag.on(event => {
        //
        // Calculate X coord
        //
        if (maximumX !== 0) {
          const newCurrentX = currentX + event.X * scrollSpeed;
          // don't allow to scroll more than min
          if (newCurrentX >= 0) currentX = 0;
          // don't allow to scroll more than max
          else if (newCurrentX * -1 >= maximumX) currentX = maximumX * -1;
          else currentX = newCurrentX;
        }

        //
        // Calculate Y coord
        //
        if (maximumY !== 0) {
          const newCurrentY = currentY + event.Y * scrollSpeed;
          // don't allow to scroll more than min
          if (newCurrentY >= 0) currentY = 0; // eslint-disable-line
          // don't allow to scroll more than max
          else if (newCurrentY * -1 >= maximumY) currentY = maximumY * -1;
          else currentY = newCurrentY;
        }
      });

      // Use the cursor position to slide the element elementRef.current.
      const move = () => {
        const x = currentX.toFixed(2);
        const y = currentY.toFixed(2);
        // console.log("x", x);
        element.style.transform = `translate3d(${x}px, ${y}px, 0)`;
        animationFrame.current = requestAnimationFrame(move);
      };

      // const handleClick = event => {
      //   switch (event.type) {
      //     case "mouseup":
      //     case "touchstart":
      //       setDragging(true);
      //       break;
      //     default:
      //       setDragging(false);
      //       break;
      //   }
      // };

      // element.addEventListener("mouseup", handleClick);
      // element.addEventListener("mousedown", handleClick);
      // element.addEventListener("touchstart", handleClick);
      // element.addEventListener("touchend", handleClick);

      animationFrame.current = requestAnimationFrame(move);
      return () => {
        cancelAnimationFrame(animationFrame.current);
        // element.removeEventListener("touchstart", handleClick);
        // element.removeEventListener("touchend", handleClick);
        // element.removeEventListener("mouseup", handleClick);
        // element.removeEventListener("mousedown", handleClick);
      };
    }
  }, [element.scrollWidth, element.scrollHeight, currentY]); // eslint-disable-line

  const elementStyle = {
    willChange: "transform",
    transition: "transform 0.4s ease-out"
    // transition: dragging ? "transform 0s ease-out" : "transform 0.7s ease-out"
  };

  const parentStyle = {
    overflow: "hidden"
  };

  return [parentStyle, elementStyle];
};

export default useScrollEngine;
