import React from 'react';

import Video from 'components/Video';

import config from 'config';

import styles from './videos.module.scss';
const requestImageFile = require.context('assets/videos', true, /.jpg/);
const requestVideoFile = require.context('assets/videos', true, /.mp4/);


export const Videos = () => {
  // console.log('videos inside', requestImageFile);
  return (
    <section className={styles.videos}>
      {config.videos.map((video, i) => {
        return (
          <Video
            key={`video-${i}`}
            title={video.name}
            duration={video.duration}
            video={requestVideoFile(`./${video.video}`)}
            screen={requestImageFile(`./${video.screen}`)}
          />
        );
      })}
    </section>
  );
};

export default Videos;
