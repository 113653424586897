import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

// Components
import Icon from "traffic-property-app/components/Icon";

export const Checkbox = ({
  id,
  name,
  icon,
  label,
  labelStyle = {},
  image,
  imageStyle = {},
  ...props
}) => {
  if (!name) name = label.toLowerCase();
  const className = classnames(props.className, "checkbox", {
    checkbox__icon: icon
  });

  return (
    <span className={classnames(className, { hasImage: image })}>
      <input type="checkbox" id={id} name={name} {...props} />
      {icon ? (
        <Icon icon={icon} tag="label" htmlFor={id} style={labelStyle}>
          {label}
        </Icon>
      ) : (
        <label
          htmlFor={id}
          style={labelStyle}
          className={classnames({ "no-icon": !icon })}
        >
          {image && (
            <img src={image} alt={label} className="logo" style={imageStyle} />
          )}
          {label}
        </label>
      )}
    </span>
  );
};

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string.isRequired,
  icon: PropTypes.string
};

export default Checkbox;
