import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { queryBuilder } from 'traffic-property-app/api/utils';

import config from 'traffic-property-app/config';

//
// Components
//
import { useEnquireForm } from 'traffic-property-app/components/EnquiryForm';
import Button from 'traffic-property-app/components/Button';
import Icon from 'traffic-property-app/components/Icon';

import './ReleaseNavigation.scss';

export const ReleaseNavigation = ({
  menu,
  title,
  hovered,
  onMouseEnter,
  onMouseLeave,
}) => {
  const toggleEnquire = useEnquireForm();
  const [toggled, setToggle] = useState({});

  const [sort, setSort] = useState('frontage-desc');
  const onChange = (e) => {
    const { value } = e.target;
    setSort(value);
  };

  menu = queryBuilder(menu, { sort });

  return (
    <>
      <div className="release--sorting">
        <label className="release--sorting--label">Sort By</label>
        <div className="release--sorting--select">
          <select value={sort} onChange={onChange}>
            <option value="frontage-desc">Frontage High</option>
            <option value="frontage">Frontage Low</option>
            <option value="area-desc">Land Size High</option>
            <option value="area">Land Size Low</option>
          </select>
        </div>
      </div>
      <ul>
        {menu.map((item, i) => {
          const { name, slug, href, image, status, area, frontage, packages } =
            item;

          const toggleFilter = () => {
            setToggle({ [slug]: !toggled[slug] });
          };

          const className = classnames({
            active: slug === hovered,
            isActive: toggled[slug],
          });

          return (
            <li
              key={`release-nav-${i}`}
              className={classnames(className, 'lfs--navitem')}
              onMouseEnter={() => onMouseEnter(slug)}
              onMouseLeave={() => onMouseLeave(slug)}
            >
              <div className="lfs--navitem--wrapper">
                <Link
                  className="lfs--navitem--thumb"
                  to={href}
                  style={{ display: 'block' }}
                >
                  <img src={image.url} alt={name} />
                </Link>
                <div className="lfs--navitem--content" onClick={toggleFilter}>
                  <span className="lfs--navitem--title">{name}</span>
                  <span className="lfs--navitem--status">
                    {config.status.get && config.status.get(status)}
                  </span>
                  <span className="lfs--navitem--specs">
                    <span className="lfs--navitem--landsize">
                      <span>Land Size </span>
                      {area}m²
                    </span>
                    <span className="lfs--navitem--frontage">
                      <span>Frontage </span>
                      {frontage}m
                    </span>
                    <span className="lfs--navitem--packages">
                      <span>Packages </span>
                      {(packages && packages.length) || 0}
                    </span>
                  </span>
                </div>
                <div className="lfs--navitem--c2a">
                  <Button
                    size="small"
                    className="enquire"
                    onClick={() => toggleEnquire(item)}
                  >
                    Enquire
                  </Button>
                  <Button size="small" href={href} color="secondary">
                    View Lot
                  </Button>
                  <a href="/">
                    <Icon icon="download" />
                  </a>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

ReleaseNavigation.propTypes = {
  title: PropTypes.string.isRequired,
  menu: PropTypes.array.isRequired,
  hovered: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default ReleaseNavigation;
