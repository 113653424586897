import config from "traffic-property-app/config";

// Pages
import ApartmentsPage from "./apartments";
import ApartmentsBuilding from "./building";

export const Routes = [
  {
    path: `${config.routes.get("apartments")}/:buildingSlug`,
    component: ApartmentsBuilding,
    exact: true
  }
];

if (process.env.NODE_ENV === "development") {
  Routes.push({
    path: config.routes.get("apartments"),
    component: ApartmentsPage,
    exact: true
  });
}

export * from "./apartments";
