import React, { createContext } from "react";
import useAxios from "axios-hooks";

import Loader from "traffic-property-app/components/Loader";

export const Context = createContext();

const addMinutes = (date, minutes) =>
  new Date(date.getTime() + minutes * 60000);

const cache = {
  get() {
    const exists = localStorage.getItem("cache");
    if (exists) return JSON.parse(localStorage.getItem("cache"));
    return {};
  },
  set(data) {
    // Cache system that refreshes every 10 mins.
    const expiresAt = addMinutes(new Date(), 10);
    const object = { data, expiresAt };
    localStorage.setItem("cache", JSON.stringify(object));
  },
  hasExpired() {
    const { data, expiresAt } = this.get();
    if (!data || !expiresAt) return true;

    // Check if expire date has passed
    const expireDate = new Date(expiresAt);
    const now = new Date();
    if (now > expireDate) return true;

    return false;
  }
};

const Cache = props => {
  const { data } = cache.get();

  const value = [
    {
      data,
      loading: false,
      error: false
    }
  ];

  return <Context.Provider {...props} value={value} />;
};

const Axios = ({ url, ...props }) => {
  const [{ data, loading, error }] = useAxios(url);

  const value = [
    {
      data,
      loading,
      error
    }
  ];

  if (loading) return <Loader />;

  // if this component is rendered it means that the cache has expired
  // So save to cache new data that was queried.
  if (data) cache.set(data);

  return <Context.Provider {...props} value={value} />;
};

export const ApiProvider = ({ url, ...props }) => {
  // Use cache component only if it hasn't expired
  if (!cache.hasExpired()) return <Cache {...props} />;

  // Axios is the default way of getting data
  return <Axios {...props} url={url} />;
};

export default ApiProvider;
