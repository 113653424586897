import React, { useState, useEffect } from "react";
import classnames from "classnames";
import Picker from "rmc-picker";
import "rmc-picker/assets/index.css";
import { useWindowWidth } from "@react-hook/window-size";
import Swiper from "react-id-swiper";

// components
import Map from "traffic-property-app/components/Map";

import styles from "./amenities.module.scss";

const addUniqueId = (slug, array) => {
  return array
    ? array.map((item, i) => ({
        ...item,
        id: item.id || `${slug}-${i}`
      }))
    : [];
};

const Amenities = ({
  title,
  description,
  categories = [],
  map,
  zoom,
  amenitiesListParentStyle,
  amenitiesListRef,
  amenitiesListStyle
}) => {
  const [activeCategory, setActiveCategory] = useState(0);

  const category = categories[activeCategory] || {};
  // console.log(category)
  const levels = [
    {
      image: map,
      markers: addUniqueId(category.name, category.amenities),
      colour: category.colour,
      fontColour: category.fontColour
    }
  ];

  const width = useWindowWidth();
  const [level] = levels || [];
  const [marker] = level.markers || [];
  const [activeAmenity, setActiveAmenity] = useState(marker);

  const changeCategory = i => () => {
    setActiveCategory(i);
    const category = categories[i];
    const amenity = addUniqueId(category.name, category.amenities)[0];
    setActiveAmenity(amenity);
  };

  useEffect(() => changeCategory(0)(), [categories]); // eslint-disable-line

  const isMobileScreen = width < 992;

  const params = {
    slidesPerView: 3,
    spaceBetween: 10,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    }
  };

  return (
    <div className={classnames(styles.container, "ta-container ta-amenities")}>
      <section
        className={classnames(styles.wrapper, "ta-row ta-amenitiesWrapper")}
      >
        {isMobileScreen ? <h1 className={styles.title}>{title}</h1> : null}

        <div className={classnames(styles.leftContent, "ta-amenities--nav")}>
          <div
            className={classnames(styles.content, "ta-amenities--nav__content")}
          >
            {!isMobileScreen ? (
              <>
                <h1
                  className={classnames(
                    styles.title,
                    "ta-amenities--nav__title"
                  )}
                >
                  {title}
                </h1>
                <h4
                  className={classnames(
                    styles.description,
                    "ta-amenities--nav__desc"
                  )}
                >
                  {description}
                </h4>
              </>
            ) : null}

            <div className={styles.categories}>
              <Swiper {...params}>
                {categories.map((category, i) => {
                  const active = activeCategory === i;
                  return (
                    <div key={i}>
                      <button
                        key={`category-${i}`}
                        className={classnames({ active })}
                        onClick={changeCategory(i)}
                      >
                        {category.name}
                      </button>
                    </div>
                  );
                })}
              </Swiper>
            </div>

            <div
              style={amenitiesListParentStyle}
              className={"ta-amenities--nav__ul-wrapper"}
            >
              {isMobileScreen ? (
                <Picker
                  selectedValue={activeAmenity.id}
                  onValueChange={value => {
                    const amenity = level.markers.find(
                      amenity => amenity.id === value
                    );
                    if (amenity) setActiveAmenity(amenity);
                  }}
                >
                  {level.markers.map((amenity, i) => (
                    <Picker.Item key={amenity.id} value={amenity.id}>
                      {amenity.name}
                    </Picker.Item>
                  ))}
                </Picker>
              ) : (
                <ul
                  ref={amenitiesListRef ? amenitiesListRef : null}
                  className={"ta-amenities--nav__ul"}
                  style={amenitiesListStyle}
                >
                  {level.markers.map((amenity, i) => {
                    const { name } = amenity;
                    const active = activeAmenity.id === amenity.id;
                    return (
                      <li
                        key={`amenity-${i}`}
                        className={classnames(
                          { active },
                          "ta-amenities--nav__li"
                        )}
                        onClick={() => setActiveAmenity(amenity)}
                      >
                        <button className={"ta-amenities--nav__button"}>
                          <span
                            className={classnames(
                              styles.icon,
                              "ta-amenities--nav__item"
                            )}
                            style={{ backgroundColor: category.colour, color: category.fontColour }}
                          >
                            
                            {i + 1}
                          </span>
                          <span
                            className={classnames(
                              styles.name,
                              "ta-amenities--nav__name"
                            )}
                          >
                            {name}
                          </span>
                        </button>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        </div>

        <Map
          className={classnames(styles.rightContent, "ta-amenities--map")}
          levels={levels}
          activeMarker={activeAmenity}
          onMarkerClick={setActiveAmenity}
          zoom={typeof zoom === "boolean" ? zoom : true}
        />
      </section>
    </div>
  );
};

export default Amenities;
