export const raw = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
  <head>
    <meta http-equiv="content-type" content="text/html; charset=utf-8" />
    <meta content="telephone=no" name="format-detection" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta http-equiv="x-ua-compatible" content="IE=edge" />
    <title>Eliston - Thank you For Registering</title>

    <style type="text/css">
      body {
        -webkit-text-size-adjust: 100% !important;
        -ms-text-size-adjust: 100% !important;
        -webkit-font-smoothing: antialiased !important;
      }

      img {
        border: 0 !important;
        outline: none !important;
      }

      table {
        border-collapse: collapse;
        mso-table-lspace: 0;
        mso-table-rspace: 0;
      }

      p {
        margin: 0 !important;
        padding: 0 !important;
      }

      td,
      a,
      span {
        border-collapse: collapse;
        mso-line-height-rule: exactly;
      }

      a {
        text-decoration: none !important;
      }

      .ExternalClass * {
        line-height: 100%;
      }

      span.MsoHyperlink {
        mso-style-priority: 99;
        color: inherit;
      }

      span.MsoHyperlinkFollowed {
        mso-style-priority: 99;
        color: inherit;
      }

      .preheader {
        display: none !important;
        visibility: hidden;
        opacity: 0;
        color: transparent;
        height: 0;
        width: 0;
      }

      /* ALL VERTICAL SPACING*/
      @media only screen and (max-width: 480px) {
        .em_hit {
          height: 20px !important;
        }
      }

      @media only screen and (max-width: 480px) {
        .em_hit1 {
          height: 17px !important;
        }
      }

      @media only screen and (max-width: 480px) {
        .em_hit2 {
          height: 15px !important;
        }
      }

      @media only screen and (max-width: 480px) {
        .em_hit3 {
          height: 15px !important;
        }
      }

      @media only screen and (max-width: 480px) {
        .em_hit4 {
          height: 25px !important;
        }
      }

      @media only screen and (max-width: 480px) {
        .em_hit8 {
          height: 5px !important;
        }
      }

      @media only screen and (max-width: 480px) {
        .em_hit9 {
          height: 30px !important;
        }
      }

      @media only screen and (max-width: 480px) {
        .em_hit10 {
          height: 10px !important;
        }
      }

      @media only screen and (max-width: 480px) {
        .em_hit11 {
          height: 0px !important;
        }
      }

      /* ALL HORIZONTAL SPACING*/
      @media only screen and (max-width: 480px) {
        .em_spacer {
          width: 30px !important;
        }
      }

      @media only screen and (max-width: 480px) {
        .em_spacer1 {
          width: 15px !important;
        }
      }

      /* GREEN LINE */
      @media only screen and (max-width: 480px) {
        hr {
          width: 50px !important;
        }
      }

      /* HERO TEXT */
      .em_font1 {
        font-family: Arial, Helvetica, Verdana, sans-serif;
        font-size: 34px;
        line-height: 38px;
        font-weight: bold;
        color: #bad982;
        text-decoration: none !important;
      }

      @media only screen and (max-width: 480px) {
        .em_font1 {
          font-size: 26px !important;
          line-height: 30px !important;
        }
      }

      /* SUB HEADING TEXT 1 */

      .em_font2 {
        font-family: Arial, Helvetica, Verdana, sans-serif;
        font-size: 20px;
        line-height: 22px;
        color: #231d16;
        text-decoration: none !important;
        font-weight: bold;
      }

      @media only screen and (max-width: 480px) {
        .em_font2 {
          font-size: 12px !important;
          line-height: 14px !important;
        }
      }

      .em_font3 {
        font-family: Arial, Helvetica, Verdana, sans-serif;
        font-size: 14px;
        line-height: 18px;
        color: #231d16;
        text-decoration: none !important;
      }

      @media only screen and (max-width: 480px) {
        .em_font3 {
          font-size: 12px !important;
          line-height: 16px !important;
        }
      }

      .em_font4 {
        font-family: Arial, Helvetica, Verdana, sans-serif;
        font-size: 18px;
        line-height: 18px;
        color: #231d16;
        text-decoration: none !important;
      }

      @media only screen and (max-width: 480px) {
        .em_font4 {
          font-size: 16px !important;
          line-height: 18px !important;
        }
      }

      .em_font5 {
        font-family: Arial, Helvetica, Verdana, sans-serif;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
        text-decoration: none !important;
        font-weight: bold;
      }

      .em_font6 {
        font-family: Arial, Helvetica, Verdana, sans-serif;
        font-size: 12px;
        line-height: 24px;
        color: #000000;
        text-decoration: none !important;
      }

      .em_font7 {
        font-family: Arial, Helvetica, Verdana, sans-serif;
        font-size: 12px;
        line-height: 18px;
        color: #000000;
        text-decoration: none !important;
      }

      @media only screen and (max-width: 480px) {
        .em_font7 {
          font-size: 10px !important;
          line-height: 16px !important;
        }

        .em_font7 a {
          font-size: 10px !important;
          line-height: 16px !important;
        }
      }

      /* Columns */
      @media only screen and (max-width: 480px) {
        .col {
          width: 100% !important;
          display: block !important;
          text-align: left !important;
        }
      }

      @media only screen and (max-width: 480px) {
        .em_wrapper {
          width: 100% !important;
        }
      }

      @media only screen and (min-width: 481px) and (max-width: 599px) {
        .em_wrapper {
          width: 100% !important;
        }
      }

      /* Pipe */
      @media only screen and (max-width: 480px) {
        .em_pipe {
          display: none !important;
          width: 0 !important;
          height: 0 !important;
        }
      }
    </style>
  </head>

  <body
    style="margin: 0px;padding: 0px;-webkit-text-size-adjust: 100% !important;-ms-text-size-adjust: 100% !important;-webkit-font-smoothing: antialiased !important;"
    bgcolor="#ffffff"
  >
    <span
      class="preheader"
      style="color:transparent; display:none !important; height:0; opacity:0; visibility:hidden; width:0"
    >
      Thanks for registering your interest at Eliston. Nestled in the heart of
      Clyde, Eliston is a well positioned established estate that has everything
      you and your loved ones could need and want well within reach.
    </span>
    <!-- START Whole email Table-->
    <table
      width="100%"
      border="0"
      align="center"
      cellspacing="0"
      cellpadding="0"
      bgcolor="#ffffff"
      style="border-collapse:collapse;mso-table-lspace:0px;mso-table-rspace:0px;"
    >
      <!-- START Whole email TR-->
      <tr>
        <!-- START Whole email TD-->
        <td>
          <!-- START Main email Table -->
          <table
            style="table-layout:fixed;border-collapse:collapse;mso-table-lspace:0px;mso-table-rspace:0px;"
            width="600"
            border="0"
            cellspacing="0"
            cellpadding="0"
            align="center"
            class="em_wrapper"
          >
            <!-- START Hero Section -->
            <tr>
              <td
                align="center"
                valign="top"
                style="border-collapse:collapse;mso-line-height-rule:exactly;mso-table-lspace:0px;mso-table-rspace:0px;"
              >
                <table
                  bgcolor="#ffffff"
                  width="100%"
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  style="border-collapse:collapse;mso-table-lspace:0px;mso-table-rspace:0px;"
                >
                  <tr>
                    <td
                      align="center"
                      valign="top"
                      style="border-collapse:collapse;mso-line-height-rule:exactly;mso-table-lspace:0px;mso-table-rspace:0px;"
                    >
                      <a
                        href="https://www.eliston.com.au?utm_medium=EDM&utm_source=AutomatedRegistrationEmail&utm_campaign=ElistonEDM"
                        target="_blank"
                        ><img
                          src="images/eliston-hero.jpg"
                          width="100%"
                          class="em_full_img"
                      /></a>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <!-- END Hero Section -->
              <!-- START Email body -->
              <td
                align="center"
                valign="top"
                style="border-collapse:collapse;mso-line-height-rule:exactly;"
              >
                <table
                  width="100%"
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  align="center"
                  bgcolor="#ffffff"
                  style="border-collapse:collapse;mso-table-lspace:0px;mso-table-rspace:0px;"
                >
                  <tr>
                    <td
                      width="53"
                      class="em_spacer"
                      style="border-collapse:collapse;mso-line-height-rule:exactly;"
                    ></td>
                    <td
                      align="center"
                      valign="top"
                      style="border-collapse:collapse;mso-line-height-rule:exactly;"
                    >
                      <table
                        width="100%"
                        border="0"
                        cellspacing="0"
                        cellpadding="0"
                        style="border-collapse:collapse;mso-table-lspace:0px;mso-table-rspace:0px;"
                      >
                        <tr>
                          <td
                            height="36"
                            class="em_hit"
                            style="font-size:1px;line-height:1px;border-collapse:collapse;mso-line-height-rule:exactly;"
                          ></td>
                        </tr>
                        <tr>
                          <!-- START HERO TEXT-->
                          <td
                            align="left"
                            valign="top"
                            class="em_font1"
                            style="border-collapse:collapse;mso-line-height-rule:exactly;font-family:Arial, Helvetica, Verdana, sans-serif;font-size:34px;line-height:38px;
                                                        font-weight:bold;color:#BAD982;text-decoration:none;text-align:left;"
                          >
                            THANKS FOR YOUR ENQUIRY
                          </td>
                        </tr>
                        <!-- END HERO TEXT-->
                        <tr>
                          <td
                            height="17"
                            class="em_hit1"
                            style="font-size:1px;line-height:1px;border-collapse:collapse;mso-line-height-rule:exactly;"
                          ></td>
                        </tr>
                        <tr>
                          <!-- START Sub heading 1 -->
                          <td
                            class="em_font2"
                            align="left"
                            valign="top"
                            style="border-collapse:collapse;mso-line-height-rule:exactly;font-family:Arial, Helvetica, Verdana, sans-serif;font-size:20px;
                                                line-height:22px;color:#231D16;font-weight: bold;text-decoration:none !important;"
                          >
                            Hi |*FNAME*|,
                          </td>
                        </tr>
                        <!-- END Sub heading 1 -->
                        <tr>
                          <td
                            height="27"
                            class="em_hit1"
                            style="font-size:1px;line-height:1px;border-collapse:collapse;mso-line-height-rule:exactly;"
                          ></td>
                        </tr>
                        <tr>
                          <!-- START Body copy -->
                          <td
                            class="em_font3"
                            align="left"
                            valign="top"
                            style="border-collapse:collapse;mso-line-height-rule:exactly;font-family:Arial, Helvetica, Verdana, sans-serif;font-size:14px;line-height:18px;
                                                color:#231D16;text-decoration:none !important;"
                          >
                            Thanks for your interest in |*LOT_NAME*|.
                            <br /><br />
                            To see more details about |*LOT_NAME*|
                            <a
                              href="|*LOT_FLYER_URL*|"
                              target="_blank"
                              style="border-collapse:collapse;mso-line-height-rule:exactly;font-family:Arial, Helvetica, Verdana, sans-serif;font-size:14px;line-height:18px;
                                                                        color:#a8c572;text-decoration:none !important;"
                              >click here</a
                            >.
                          </td>
                        </tr>
                        <!-- END Body copy -->
                        <tr>
                          <td
                            height="30"
                            class="em_hit2"
                            style="font-size:1px;line-height:1px;border-collapse:collapse;mso-line-height-rule:exactly;"
                          ></td>
                        </tr>
                        <tr></tr>
                      </table>
                    </td>
                    <td
                      width="53"
                      class="em_spacer"
                      style="border-collapse:collapse;mso-line-height-rule:exactly;"
                    ></td>
                  </tr>
                </table>
              </td>
            </tr>
            <!-- END Email body -->

            <tr>
              <td
                height="30"
                class="em_hit9"
                style="font-size:1px;line-height:1px;border-collapse:collapse;mso-line-height-rule:exactly;"
              ></td>
            </tr>
            <tr>
              <!-- START Welcome image-->
              <td
                align="center"
                valign="top"
                style="border-collapse:collapse;mso-line-height-rule:exactly;mso-table-lspace:0px;mso-table-rspace:0px;"
              >
                <table
                  bgcolor="#ffffff"
                  width="100%"
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  style="border-collapse:collapse;mso-table-lspace:0px;mso-table-rspace:0px;"
                >
                  <tr>
                    <td
                      align="center"
                      valign="top"
                      style="border-collapse:collapse;mso-line-height-rule:exactly;mso-table-lspace:0px;mso-table-rspace:0px;"
                    >
                      <img
                        width="100%"
                        src="images/welcome.png"
                        class="em_full_img"
                      />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <!-- END Welcome image-->
            <tr>
              <!-- START Footer white section -->
              <td
                align="center"
                valign="top"
                style="border-collapse:collapse;mso-line-height-rule:exactly;"
              >
                <table
                  width="100%"
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  align="center"
                  bgcolor="#ffffff"
                  style="border-collapse:collapse;mso-table-lspace:0px;mso-table-rspace:0px;"
                >
                  <tr>
                    <td
                      width="53"
                      class="em_spacer"
                      style="border-collapse:collapse;mso-line-height-rule:exactly;"
                      bgcolor="#ffffff"
                    ></td>
                    <td
                      align="center"
                      valign="top"
                      style="border-collapse:collapse;mso-line-height-rule:exactly;"
                    >
                      <table
                        width="100%"
                        border="0"
                        cellspacing="0"
                        cellpadding="0"
                        style="border-collapse:collapse;mso-table-lspace:0px;mso-table-rspace:0px;"
                      >
                        <tr>
                          <td
                            height="17"
                            class="em_hit1"
                            style="font-size:1px;line-height:1px;border-collapse:collapse;mso-line-height-rule:exactly;"
                          ></td>
                        </tr>
                        <tr>
                          <!-- START TOP of sales centre-->
                          <td
                            class="em_font5"
                            align="left"
                            width="50%"
                            valign="top"
                            style="border-collapse:collapse;mso-line-height-rule:exactly;font-family:Arial, Helvetica, Verdana, sans-serif;font-size:14px;line-height:24px;
                                                color:#000000;font-weight: bold;text-decoration:none !important;"
                          >
                            Sales Centre
                          </td>
                          <td
                            class="em_font6"
                            align="right"
                            width="50%"
                            valign="top"
                            style="border-collapse:collapse;mso-line-height-rule:exactly;font-family:Arial, Helvetica, Verdana, sans-serif;font-size:12px;line-height:24px;
                                                color:#000000;text-decoration:none !important;"
                          >
                            <a
                              href="https://goo.gl/maps/yrBKw2SFbQ32"
                              target="_blank"
                              style="border-collapse:collapse;mso-line-height-rule:exactly;font-family:Arial, Helvetica, Verdana, sans-serif;font-size:12px;line-height:24px;
                                                    color:#000000;text-decoration:none !important;"
                              >View Map</a
                            >&nbsp;&nbsp;

                            <img
                              src="images/arrow-right.png"
                              class="em_full_img"
                            />
                          </td>
                        </tr>
                        <!-- END TOP of sales centre-->
                        <tr>
                          <td
                            height="5"
                            class="em_hit8"
                            style="font-size:1px;line-height:1px;border-collapse:collapse;mso-line-height-rule:exactly;"
                          ></td>
                        </tr>
                      </table>

                      <table
                        width="100%"
                        border="0"
                        cellspacing="0"
                        cellpadding="0"
                        style="border-collapse:collapse;mso-table-lspace:0px;mso-table-rspace:0px;"
                      >
                        <tr>
                          <!-- START Bottom of sales centre-->
                          <td
                            class="em_font6 col"
                            align="left"
                            valign="top"
                            width="30%"
                            style="border-collapse:collapse;mso-line-height-rule:exactly;font-family:Arial, Helvetica, Verdana, sans-serif;font-size:12px;line-height:24px;
                                                color:#000000;text-decoration:none !important;"
                          >
                            70 Pattersons Road, Clyde
                          </td>
                          <td
                            class="em_font6 em_pipe col"
                            align="center"
                            width="5%"
                            valign="top"
                            style="border-collapse:collapse;mso-line-height-rule:exactly;font-family:Arial, Helvetica, Verdana, sans-serif;font-size:12px;line-height:24px;
                                                color:#000000;text-decoration:none !important;"
                          >
                            |
                          </td>
                          <td
                            class="em_font6 col"
                            align="center"
                            width="30%"
                            valign="top"
                            style="border-collapse:collapse;mso-line-height-rule:exactly;font-family:Arial, Helvetica, Verdana, sans-serif;font-size:12px;line-height:24px;
                                                color:#000000;text-decoration:none !important;"
                          >
                            Open 7 days - 11am to 5pm
                          </td>
                          <td
                            class="em_font6 em_pipe col"
                            align="center"
                            width="5%"
                            valign="top"
                            style="border-collapse:collapse;mso-line-height-rule:exactly;font-family:Arial, Helvetica, Verdana, sans-serif;font-size:12px;line-height:24px;
                                                color:#000000;text-decoration:none !important;"
                          >
                            |
                          </td>
                          <td
                            class="em_font6 col"
                            align="right"
                            width="30%"
                            valign="top"
                            style="border-collapse:collapse;mso-line-height-rule:exactly;font-family:Arial, Helvetica, Verdana, sans-serif;font-size:12px;line-height:24px;
                                                color:#000000;text-decoration:none !important;"
                          >
                            <a
                              href="tel:1300-354-786"
                              target="_blank"
                              style="border-collapse:collapse;mso-line-height-rule:exactly;font-family:Arial, Helvetica, Verdana, sans-serif;font-size:12px;line-height:18px;
                                                    color:#000000;text-decoration:none !important;"
                              >Call us on 1300 354 786</a
                            >
                          </td>
                        </tr>
                        <!-- END Bottom of sales centre-->
                        <tr>
                          <td
                            height="17"
                            class="em_hit1"
                            style="font-size:1px;line-height:1px;border-collapse:collapse;mso-line-height-rule:exactly;"
                          ></td>
                        </tr>
                      </table>
                    </td>
                    <td
                      width="53"
                      class="em_spacer"
                      style="border-collapse:collapse;mso-line-height-rule:exactly;"
                      bgcolor="#ffffff"
                    ></td>
                  </tr>
                </table>
              </td>
            </tr>
            <!-- END Footer white section -->
            <tr>
              <!-- START Footer grey section -->
              <td
                align="center"
                valign="top"
                style="border-collapse:collapse;mso-line-height-rule:exactly;"
              >
                <table
                  width="100%"
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  align="center"
                  bgcolor="#F2F2F2"
                  style="border-collapse:collapse;mso-table-lspace:0px;mso-table-rspace:0px;"
                >
                  <tr>
                    <td
                      height="25"
                      colspan="4"
                      bgcolor="#F2F2F2"
                      class="em_hit4"
                      style="font-size:1px;line-height:1px;border-collapse:collapse;mso-line-height-rule:exactly;"
                    ></td>
                  </tr>
                  <tr>
                    <td
                      width="53"
                      class="em_spacer"
                      style="border-collapse:collapse;mso-line-height-rule:exactly;"
                      bgcolor="#F2F2F2"
                    ></td>
                    <td
                      align="left"
                      valign="top"
                      style="border-collapse:collapse;mso-line-height-rule:exactly;"
                    >
                      <table
                        bgcolor="#F2F2F2"
                        border="0"
                        cellspacing="0"
                        cellpadding="0"
                        style="border-collapse:collapse;mso-table-lspace:0px;mso-table-rspace:0px;"
                      >
                        <tr>
                          <td
                            class="em_font5"
                            align="left"
                            width="50%"
                            valign="top"
                            style="border-collapse:collapse;mso-line-height-rule:exactly;font-family:Arial, Helvetica, Verdana, sans-serif;font-size:14px;line-height:24px;
                                                    color:#000000;font-weight: bold;text-decoration:none !important;"
                          >
                            Brought to you by
                          </td>
                        </tr>
                        <tr>
                          <td
                            height="17"
                            class="em_hit1"
                            bgcolor="#F2F2F2"
                            style="font-size:1px;line-height:1px;border-collapse:collapse;mso-line-height-rule:exactly;"
                          ></td>
                        </tr>
                        <tr>
                          <td
                            valign="top"
                            style="border-collapse:collapse;mso-line-height-rule:exactly;"
                          >
                            <table
                              width="100%"
                              border="0"
                              cellspacing="0"
                              cellpadding="0"
                              style="border-collapse:collapse;mso-table-lspace:0px;mso-table-rspace:0px;"
                            >
                              <tr>
                                <td
                                  align="left"
                                  width="50%"
                                  valign="top"
                                  style="border-collapse:collapse;mso-line-height-rule:exactly;mso-table-lspace:0px;mso-table-rspace:0px;"
                                >
                                  <a
                                    href="http://www.nationalpacific.com.au/?utm_medium=EDM&utm_source=AutomatedRegistrationEmail&utm_campaign=ElistonEDM"
                                    target="_blank"
                                    ><img
                                      src="images/npp-logo.png"
                                      class="em_full_img"
                                  /></a>
                                </td>
                                <td
                                  align="left"
                                  width="50%"
                                  valign="top"
                                  style="border-collapse:collapse;mso-line-height-rule:exactly;mso-table-lspace:0px;mso-table-rspace:0px;"
                                >
                                  <a
                                    href="http://www.rpmrealestate.com.au/?utm_medium=EDM&utm_source=AutomatedRegistrationEmail&utm_campaign=ElistonEDM"
                                    target="_blank"
                                    ><img
                                      src="images/rpm-logo.png"
                                      class="em_full_img"
                                  /></a>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td
                      align="left"
                      valign="top"
                      style="border-collapse:collapse;mso-line-height-rule:exactly;"
                    >
                      <table
                        bgcolor="#F2F2F2"
                        border="0"
                        cellspacing="0"
                        cellpadding="0"
                        style="border-collapse:collapse;mso-table-lspace:0px;mso-table-rspace:0px;"
                      >
                        <tr>
                          <td
                            class="em_font5"
                            align="left"
                            width="50%"
                            valign="top"
                            style="border-collapse:collapse;mso-line-height-rule:exactly;font-family:Arial, Helvetica, Verdana, sans-serif;font-size:14px;line-height:24px;
                                                    color:#000000;font-weight: bold;text-decoration:none !important;"
                          >
                            Connect with us
                          </td>
                        </tr>
                        <tr>
                          <td
                            bgcolor="#F2F2F2"
                            height="17"
                            class="em_hit1"
                            style="font-size:1px;line-height:1px;border-collapse:collapse;mso-line-height-rule:exactly;"
                          ></td>
                        </tr>
                        <tr>
                          <td
                            valign="top"
                            style="border-collapse:collapse;mso-line-height-rule:exactly;"
                          >
                            <table
                              border="0"
                              cellspacing="0"
                              cellpadding="0"
                              style="border-collapse:collapse;mso-table-lspace:0px;mso-table-rspace:0px;"
                            >
                              <tr>
                                <td
                                  align="left"
                                  valign="top"
                                  style="border-collapse:collapse;mso-line-height-rule:exactly;mso-table-lspace:0px;mso-table-rspace:0px;"
                                >
                                  <a
                                    href="https://www.facebook.com/Eliston.com.au/?utm_medium=EDM&utm_source=AutomatedRegistrationEmail&utm_campaign=ElistonEDM"
                                    target="_blank"
                                    ><img
                                      src="images/fb.png"
                                      class="em_full_img"
                                  /></a>
                                </td>
                                <td
                                  bgcolor="#F2F2F2"
                                  width="15"
                                  class="em_spacer1"
                                  style="border-collapse:collapse;mso-line-height-rule:exactly;"
                                ></td>
                                <td
                                  align="left"
                                  valign="top"
                                  style="border-collapse:collapse;mso-line-height-rule:exactly;mso-table-lspace:0px;mso-table-rspace:0px;"
                                >
                                  <a
                                    href="https://www.instagram.com/elistonclyde/?utm_medium=EDM&utm_source=AutomatedRegistrationEmail&utm_campaign=ElistonEDM"
                                    target="_blank"
                                    ><img
                                      src="images/instagram.png"
                                      class="em_full_img"
                                  /></a>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td
                      width="53"
                      class="em_spacer"
                      style="border-collapse:collapse;mso-line-height-rule:exactly;"
                      bgcolor="#F2F2F2"
                    ></td>
                  </tr>
                  <tr>
                    <td
                      bgcolor="#F2F2F2"
                      height="25"
                      class="em_hit4"
                      style="font-size:1px;line-height:1px;border-collapse:collapse;mso-line-height-rule:exactly;"
                    ></td>
                  </tr>
                </table>
              </td>
            </tr>
            <!-- END Footer grey section -->
            <tr>
              <!-- START Footer privacy section -->
              <td
                align="center"
                valign="top"
                style="border-collapse:collapse;mso-line-height-rule:exactly;"
              >
                <table
                  width="100%"
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  align="center"
                  bgcolor="#ffffff"
                  style="border-collapse:collapse;mso-table-lspace:0px;mso-table-rspace:0px;"
                >
                  <tr>
                    <td
                      height="17"
                      class="em_hit1"
                      style="font-size:1px;line-height:1px;border-collapse:collapse;mso-line-height-rule:exactly;"
                    ></td>
                  </tr>
                  <tr>
                    <td
                      class="em_font7"
                      align="center"
                      width="30%"
                      valign="top"
                      style="border-collapse:collapse;mso-line-height-rule:exactly;font-family:Arial, Helvetica, Verdana, sans-serif;font-size:12px;line-height:18px;
                                                            color:#000000;text-decoration:none !important;"
                    >
                      <a
                        href="https://www.eliston.com.au/privacy-policy/?utm_medium=EDM&utm_source=AutomatedRegistrationEmail&utm_campaign=ElistonEDM"
                        target="_blank"
                        style="border-collapse:collapse;mso-line-height-rule:exactly;font-family:Arial, Helvetica, Verdana, sans-serif;font-size:12px;line-height:18px;
                                        color:#000000;text-decoration:underline !important;"
                        >Click here</a
                      >
                      to view our Privacy Policy. *Terms and conditions apply.
                    </td>
                  </tr>
                  <tr>
                    <td
                      height="10"
                      class="em_hit11"
                      style="font-size:1px;line-height:1px;border-collapse:collapse;mso-line-height-rule:exactly;"
                    ></td>
                  </tr>
                  <tr>
                    <td
                      class="em_font7"
                      align="center"
                      width="30%"
                      valign="top"
                      style="border-collapse:collapse;mso-line-height-rule:exactly;font-family:Arial, Helvetica, Verdana, sans-serif;font-size:12px;line-height:18px;
                                                            color:#000000;text-decoration:none !important;"
                    >
                      Please contact our
                      <a
                        href="tel:1300-354-786"
                        target="_blank"
                        style="border-collapse:collapse;mso-line-height-rule:exactly;font-family:Arial, Helvetica, Verdana, sans-serif;font-size:12px;line-height:18px;
                                        color:#000000;text-decoration:underline !important;"
                        >Sales Centre</a
                      >
                      for full details. You are receiving this email because you
                      subscribed by phone or at
                      <a
                        href="https://www.eliston.com.au/?utm_medium=EDM&utm_source=AutomatedRegistrationEmail&utm_campaign=ElistonEDM"
                        target="_blank"
                        style="border-collapse:collapse;mso-line-height-rule:exactly;font-family:Arial, Helvetica, Verdana, sans-serif;font-size:12px;line-height:18px;
                                        color:#000000;text-decoration:underline !important;"
                        >eliston.com.au</a
                      >.
                      <a
                        href="|*unsub*|"
                        target="_blank"
                        style="border-collapse:collapse;mso-line-height-rule:exactly;font-family:Arial, Helvetica, Verdana, sans-serif;font-size:12px;line-height:18px;
                                        color:#000000;text-decoration:underline !important;"
                        >Unsubscribe here</a
                      >
                      from Eliston news.
                    </td>
                  </tr>
                  <tr>
                    <td
                      height="10"
                      class="em_hit11"
                      style="font-size:1px;line-height:1px;border-collapse:collapse;mso-line-height-rule:exactly;"
                    ></td>
                  </tr>
                  <tr>
                    <td
                      class="em_font7"
                      align="center"
                      width="30%"
                      valign="top"
                      style="border-collapse:collapse;mso-line-height-rule:exactly;font-family:Arial, Helvetica, Verdana, sans-serif;font-size:12px;line-height:18px;
                                                            color:#000000;text-decoration:none !important;"
                    >
                      © Copyright 2019. All rights reserved.
                    </td>
                  </tr>
                  <tr>
                    <td
                      height="10"
                      class="em_hit3"
                      style="font-size:1px;line-height:1px;border-collapse:collapse;mso-line-height-rule:exactly;"
                    ></td>
                  </tr>
                  <tr>
                    <td
                      height="10"
                      class="em_hit3"
                      style="font-size:1px;line-height:1px;border-collapse:collapse;mso-line-height-rule:exactly;"
                    ></td>
                  </tr>
                </table>
              </td>
            </tr>
            <!-- END Footer privacy section -->
          </table>

          <!-- END Main email Table -->
        </td>
        <!-- END Whole email TD-->
      </tr>
      <!-- END Whole email TR-->
    </table>

    <!-- END Whole email Table-->
  </body>
</html>
`; export default raw;