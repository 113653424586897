import React, { useState } from "react";
import classnames from "classnames";
import Swiper from "react-id-swiper";
import Icon from "traffic-property-app/components/Icon";

import "./Gallery.scss";

export const Gallery = ({ show, startIndex, onClose, colour, ...props }) => {
  const [swiper, updateSwiper] = useState(null);
  const { slides } = props;

  const params = {
    loop: true,
    pagination: {
      el: ".swiper-pagination",
      type: "fraction",
      renderFraction: (currentClass, totalClass) => {
        return `<span class="${currentClass}"></span> of <span class="${totalClass}"></span>`;
      }
    },
    activeSlideKey: startIndex.toString()
  };

  const gallerySlides = slides.map((slide, index) => {
    const image = typeof slide === "object" ? slide.image : slide;
    return (
      <div key={index} data-hash={index + 1}>
        <div className="swiper-imagewrapper">
          <img src={image} alt="" />
        </div>
      </div>
    );
  });

  const slideToFloorplan = () => {
    const floorplanIndex = slides.findIndex(
      slide => slide.icon === "floorplan"
    );
    if (swiper !== null || floorplanIndex === -1) {
      swiper.slideTo(floorplanIndex + 1);
    }
  };

  const slidePrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  }


  const slideNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  }

  if (!show) return null;

  return (
    <div className={classnames("ta-gallery", { active: show })}>
      <span className="share">
        <Icon icon="share" />Share
      </span>
      <Icon icon={colour === "grey" ? "close_grey" : "close"} className="close" onClick={onClose} />
      <span className="floorplan" onClick={slideToFloorplan}>
        <Icon icon="floorplan" />View floor plan
      </span>
      <div onClick={slidePrev} className="swiper-button-prev">
        <Icon icon="arrow_orange" />
      </div>
      <div onClick={slideNext} className="swiper-button-next">
        <Icon icon="arrow_orange" />
      </div>
      <Swiper getSwiper={updateSwiper} {...params}>
        {gallerySlides}
      </Swiper>);
    </div>
  );
};

export default Gallery;
