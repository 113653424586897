import capitalize from 'capitalize';
import config from 'traffic-property-app/config';

import { lowercaseAndParseSpaces } from 'traffic-property-app/utils';

import { generateBuilderSlug } from '../utils';

export const initFiltersOptions = (api) => {
  const filters = {
    // propertyType: ["house", "townhome", "apartment"],
    // frontage: 'low-high',
    // area: 'low-high', // equals to landSize
    // price: 'low-high',
    // bed: 'options', // equals to bedrooms
    // bath: 'options', // equals to bathrooms
    // car: 'options', // equals to parking
    // stories: 'options',
    // builders: 'options',
    // lots: 'options',
    // otherOptions: ['waterfront', 'golf-course', 'titled', 'school', 'corner', 'promoted'],
  };

  const setRange = (name, values) => {
    const label = values.label != null ? values.label : capitalize(name);
    const filter = { type: 'range', label, name };

    if (values.options) {
      // @NOTE from should always start from 1
      filter.from = values.from || Math.min(...values.options) || 1;
      filter.to = values.to || Math.max(...values.options);
    } else {
      const { to, from } = filters[name] || {};
      const { low, high } = values.original || {};
      const { step } = values;

      filter.from = from ? Math.max(from, low) : low;
      filter.to = to ? Math.min(to, high) : high;
      filter.step = step;
    }

    // some packages values have different name on the api
    // so each filter has a { apiName } field if needed
    if (values.apiName) filter.apiName = values.apiName;

    filters[name] = filter;
  };

  const setSelect = (name, values) => {
    const label = values.label != null ? values.label : capitalize(name);
    const filter = { type: 'select', label, options: values.options, name };

    // some packages values have different name on the api
    // so each filter has a { apiName } field if needed
    if (values.apiName) filter.apiName = values.apiName;

    filters[name] = filter;
  };

  const setCheckList = (name, values) => {
    const label = values.label != null ? values.label : capitalize(name);
    const filter = { type: 'check-list', label, options: values.options, name };

    // some packages values have different name on the api
    // so each filter has a { apiName } field if needed
    if (values.apiName) filter.apiName = values.apiName;

    filters[name] = filter;
  };

  const { current: data, packageSearch } = api;
  const { packages = [], townhomes = [], apartments = [] } = data || {};

  if (data) {
    const propertyTypes = [];

    // @TODO REMOVE WHEN API ADDS PROPERTY TYPE TO THE PACKAGES
    const containsHouseOfLand = packages.find((pkg) => pkg.lot_id);

    if (containsHouseOfLand)
      propertyTypes.push({
        name: 'packages',
        label: 'House & Land',
        value: 'house',
        icon: 'houseandland',
      });

    if (townhomes.length)
      propertyTypes.push({
        name: 'townhomes',
        label: 'Townhomes',
        value: 'townhome',
        icon: 'townhome',
      });

    if (apartments.length)
      propertyTypes.push({
        name: 'apartments',
        label: 'Apartments',
        value: 'apartment',
        icon: 'apartment',
      });

    const propertyType = {
      label: 'Property type',
      options: propertyTypes,
    };

    setCheckList('propertyType', propertyType);
  }

  if (config.api.get('project')) {
    // if a project is selected on the config then show the neighbourhoods
    const { data: neighbourhoods } = api.neighbourhoods.list();

    const options = neighbourhoods.map((neighbourhood) => {
      console.log('neigh', neighbourhood);
      const { name, slug, packages, landforsale } = neighbourhood;

      return {
        name,
        label: name,
        value: slug,
        landforsale: landforsale.length,
        packages: packages.length,
      };
    });

    const neighbourhoodFilter = {
      label: config.labels.get('filters.neighbourhood'),
      options: options.filter((option) => option !== null),
      apiName: 'neighbourhood_slug',
    };

    setCheckList('neighbourhood', neighbourhoodFilter);
  } else if (!config.api.get('neighbourhood')) {
    // if a project is not selected on the config then show the projects (estates)
    const { data: projects } = api.projects.list();

    const estates = projects.map((project) => {
      console.log('project', project);
      const { estate_name: name, slug } = project;
      return {
        name,
        label: config.estates.get(`${slug}.label`),
        value: slug,
        image: config.estates.get(`${slug}.logo`),
        color: config.estates.get(`${slug}.color`),
      };
    });

    const estateFilter = {
      label: config.labels.get('filters.estate'),
      options: estates,
      apiName: 'project_slug',
    };

    setCheckList('estate', estateFilter);
  }

  if (packageSearch) {
    // get search criteria options
    const {
      frontage,
      area,
      price,
      bed,
      bath,
      car,
      stories,
      builders,
    } = packageSearch;

    // set <select> elements
    if (builders) {
      builders.options = builders.options.map((option) => {
        option.slug = generateBuilderSlug(option.slug);
        return option;
      });

      // @NOTE we are not using the api object name. We are generating our
      // own builders_slug and we will use apiName option to map it
      setSelect('builder', { ...builders, apiName: 'builders_slug' });
    }

    // @HACK Added release filter support, statically for now
    // Also the ones in here are only for lots, so it's a quick fix
    // since Land for Sale is the only one who show this release list
    // on kiosk
    setSelect('release', {
      options: [
        { name: 'Search all releases', slug: '' },
        { name: 'Acacia Release A', slug: 'acacia-release-a' },
        { name: 'Acacia Release B', slug: 'acacia-release-b' },
        { name: 'Wattle Release A', slug: 'wattle-release-a' },
        { name: 'Waratah Release', slug: 'waratah-release' },
      ],
      apiName: 'release_slug',
    });

    // set <range> elements
    if (bed) setRange('bedrooms', { ...bed, to: 5 });
    if (bath) setRange('bathrooms', { ...bath, to: 3 });
    if (car) setRange('parking', { ...car, to: 3, apiName: 'garage' });
    if (stories) setRange('stories', { ...stories, label: 'storeys', to: 3 });

    // @TODO change price, landSizeand frontage to range elements
    if (price) setRange("price", { ...price, step: 10000 });
    if (area)
      setRange('landSize', {
        ...area,
        label: 'Land size',
        apiName: 'lot_area',
      });
    if (frontage)
      setRange('frontage', { ...frontage, apiName: 'lot_frontage' });

    if (frontage) {
      const attributes = config.attributes;
      const otherOptions = {
        label: 'Other options',
        options: Object.keys(config.attributes).map((value) => {
          const name = attributes[value];
          return {
            name,
            label: name,
            value: parseInt(value),
            icon: lowercaseAndParseSpaces(name),
          };
        }),
      };

      setCheckList('otherOptions', { ...otherOptions, apiName: 'attributes' });
    }

    const statusOptions = {
      label: 'Status',
      options: ['promoted', 'titled'].map((value) => {
        return {
          name: value,
          label: value,
          value,
          // icon: lowercaseAndParseSpaces(value)
        };
      }),
    };

    setCheckList('statusOptions', statusOptions);
  }

  return filters;
};

export default initFiltersOptions;
