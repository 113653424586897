import React, { useState, useEffect } from "react";
import sift from "sift";
import classnames from "classnames";
import { qs } from "traffic-property-app/utils";

// Hooks
import useApi from "traffic-property-app/api";
import config from "traffic-property-app/config";

// Components
import BackButton from "traffic-property-app/components/BackButton";
import Icon from "traffic-property-app/components/Icon";
import Advertising from "traffic-property-app/components/Advertising";
import Button from "traffic-property-app/components/Button";
import Package from "traffic-property-app/components/Package";
import { CheckList } from "traffic-property-app/components/Form";

import Floorplate from "./components/Floorplate";

import "./building.scss";

export const ApartmentBuilding = ({ match, history }) => {
  const currentQueries = qs.parse(window.location.search);

  const { buildingSlug } = match.params;
  const api = useApi();
  const [filtersOptions, setFiltersOptions] = useState();
  const [building, setBuilding] = useState();
  const [allApartments, setApartments] = useState([]);
  const [level, setLevel] = useState(0);
  const setAllLevels = () => setLevel(building.levels.map((_, i) => i));

  const [activeFilters, setActiveFilters] = useState({
    apartmentType: currentQueries.apartment_type,
    layoutType: currentQueries.layout_type
  });
  const viewAllApartments = () => {
    const activeFilters = { apartmentType: "all", layoutType: "all" };
    setActiveFilters({ apartmentType: "all", layoutType: "all" });
    onSubmit(activeFilters);
  };

  if (activeFilters.apartmentType === "all") delete activeFilters.apartmentType;
  if (activeFilters.layoutType === "all") delete activeFilters.layoutType;

  const layoutTypes = filtersOptions
    ? filtersOptions.layout_type.options.filter(option => {
        if (allApartments.length && activeFilters.apartmentType) {
          const apartmentExists = allApartments.filter(
            sift({
              layout_type: option.name,
              apartment_type: { $in: activeFilters.apartmentType }
            })
          );

          return apartmentExists.length > 0;
        }

        return true;
      })
    : null;

  const apartmentTypes = filtersOptions
    ? filtersOptions.apartment_type.options.map(name => ({
        label: name,
        value: name
      }))
    : [];

  useEffect(
    () => {
      const building = api.buildings.retrieve(buildingSlug);
      setBuilding(building);
      setLevel(building.levels.map((_, i) => i));

      if (!filtersOptions) {
        setFiltersOptions(api.buildings.filters());

        let apartments = [];
        building.levels.map(
          level => (apartments = [...apartments, ...level.apartments])
        );
        setApartments(apartments);
      }
    },
    [api, buildingSlug, filtersOptions]
  );

  // @TODO add 404 page
  if (!building) return "Building not found";

  const siftQuery = { $or: [{ status_id: 1 }, { status: 1 }] };
  if (activeFilters.apartmentType)
    siftQuery.apartment_type = { $in: activeFilters.apartmentType };
  if (activeFilters.layoutType)
    siftQuery.layout_type = activeFilters.layoutType;

  let apartments = [];

  if (typeof level === "object" && level.length) {
    level.map(i => {
      return (apartments = [...apartments, ...building.levels[i].apartments]);
    });
  } else {
    apartments = building.levels[level].apartments;
  }
  apartments = apartments.filter(sift(siftQuery));

  const onFilterChange = ({ name, value }) => {
    setActiveFilters({ ...activeFilters, [name]: value });
  };

  const showResults = Object.keys(currentQueries).length;

  const onSubmit = newActiveFilters => {
    const query = newActiveFilters || activeFilters;

    const queries = qs.stringify({
      apartment_type: query.apartmentType,
      layout_type: query.layoutType
    });

    const siftQuery = { $or: [{ status_id: 1 }, { status: 1 }] };
    if (query.apartmentType)
      siftQuery.apartment_type = { $in: query.apartmentType };
    if (query.layoutType) siftQuery.layout_type = query.layoutType;

    building.levels.find((level, i) => {
      if (level.apartments.length) {
        const apartments = level.apartments.filter(sift(siftQuery));
        if (apartments.length) return level;
      }
      return false;
    });

    setLevel(building.levels.map((_, i) => i));

    const searchPath = config.routes.get(
      "apartments",
      `${building.slug}?${queries}`
    );
    history.push(searchPath);
  };

  const selectedLayout = activeFilters.layoutType
    ? filtersOptions.layout_type.options.find(
        ({ name }) => name === activeFilters.layoutType
      )
    : null;

  return (
    <section className="ta-container apartments">
      <BackButton
        title={showResults ? "search" : "all apartments"}
        className={classnames({ search: showResults })}
        to={
          showResults
            ? config.routes.get("apartments", building.slug)
            : config.routes.get("apartments")
        }
        external
      />
      <div className="ta-row">
        <div className={classnames("apartments--filter", { showResults })}>
          {!showResults ? (
            <>
              <div className="apartments--filtertype">
                <h3>Select an apartment</h3>
                <CheckList
                  name="apartmentType"
                  label="apartmentType"
                  options={apartmentTypes || []}
                  color="secondary"
                  onChange={onFilterChange}
                  value={activeFilters.apartmentType}
                  allSelectedByDefault
                  showSelectAll
                />
              </div>
              <div className="apartments--filterplan">
                <h3>Select a floor plan</h3>
                <div className="apartments--filterplan--items">
                  {layoutTypes.map(
                    ({ label, name, image, bathrooms, bedrooms }) => {
                      const src = image && image.url;
                      const onClick = () => {
                        onFilterChange({
                          name: "layoutType",
                          value: name
                        });
                        onSubmit({ ...activeFilters, layoutType: name });
                      };
                      return (
                        <div
                          className="apartments--filterplan--item"
                          key={name}
                        >
                          <button
                            key={name}
                            onClick={onClick}
                            disabled={
                              activeFilters.apartmentType &&
                              !activeFilters.apartmentType.length
                            }
                          >
                            <img src={src} alt={name} />
                            <div className="apartments--filterplan--item__name">
                              {label}
                            </div>
                            <span className="apartments--amenities">
                              <span>
                                {bedrooms} <Icon icon="bedroom" />
                              </span>
                              <span>
                                {bathrooms} <Icon icon="bathroom" />
                              </span>
                            </span>
                          </button>
                        </div>
                      );
                    }
                  )}
                </div>
                <Button onClick={viewAllApartments} size="small">
                  View all apartments
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="apartments--filterfloor">
                <h3>
                  Choose a floor <span>(Optional)</span>
                </h3>
                <div>
                  <button
                    onClick={() => setAllLevels()}
                    className={classnames({
                      active: typeof level === "object"
                    })}
                    disabled={!apartments.length}
                  >
                    <span>All</span>
                  </button>
                  {building.levels.map(({ name, apartments }, i) => {
                    apartments = apartments.filter(sift(siftQuery));

                    const className = classnames({ active: level === i });
                    return (
                      <button
                        key={name}
                        onClick={() => setLevel(i)}
                        className={className}
                        disabled={!apartments.length}
                      >
                        <span>{name}</span>
                      </button>
                    );
                  })}
                </div>
              </div>
              {selectedLayout && (
                <div className="apartments--selectedplan">
                  <img
                    src={selectedLayout.image.url}
                    alt={selectedLayout.name}
                  />
                  <span className="apartments--selectedplan--label">
                    {selectedLayout.label}
                  </span>
                  <span className="apartments--selectedplan--amenities">
                    <span>
                      {selectedLayout.bedrooms} <Icon icon="bedroom" />
                    </span>
                    <span>
                      {selectedLayout.bathrooms} <Icon icon="bathroom" />
                    </span>
                  </span>
                </div>
              )}
            </>
          )}
        </div>

        {showResults ? (
          <div className="apartments--results">
            <div className="ta-row">
              {apartments.map((apartment, i) => (
                <Package key={apartment.slug || i} package={apartment} />
              ))}
            </div>
          </div>
        ) : null}
        <div className="ta-row">
          <div className="ad--wrapper">
            <Advertising
              className="ad--item"
              query={[{ type: "release", value: building.slug }]}
              height={200}
            />
          </div>
          <Floorplate building={building} />
        </div>
      </div>
    </section>
  );
};

export default ApartmentBuilding;
