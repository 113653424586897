import React from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
// import ReactDelayedList from "react-delayed-list";
import { Animated as ReactAnimated } from 'react-animated-css';

import Logo from 'components/Logo';

import styles from './Navbar.module.scss';

// remove Animated props to avoid console errors
const removeAnimatedProps = ({
  animationIn,
  animationInDuration,
  animationInDelay,
  ...props
}) => props;

const Navbar = ({
  menu,
  show,
  toggle,
  splash,
  className,
  collapse,
  animated,
  ...props
}) => {
  const Animated = animated
    ? ReactAnimated
    : (props) => <div {...removeAnimatedProps(props)} />;
  show = show || collapse;

  const isActive = (path) => (match, location) => {
    return !!(
      match ||
      path === location.path ||
      (path && location.path.includes(path))
    );
  };

  return (
    <div
      className={classnames(styles.sidebar, {
        [styles.show]: show,
        [styles.collapsed]: collapse,
      })}
    >
      <div className={classnames(className, styles.content)}>
        {collapse ? (
          <div className={styles.logoWrapper}>
            <NavLink to='/' className='py-5 d-block'>
              <Logo size='sm' />
            </NavLink>
          </div>
        ) : null}
        {show ? (
          <div className={styles.menu}>
            {menu.map(({ icon, iconAlt, title, shorten, link }, i) => {
              if (collapse) title = shorten;

              return (
                <Animated
                  key={i}
                  animationIn='fadeInLeft'
                  animationInDuration={600}
                  animationInDelay={i * 50}
                  className={styles.item}
                >
                  <NavLink
                    activeClassName={styles.active}
                    isActive={isActive(link)}
                    to={link}
                  >
                    {splash && iconAlt ? (
                      <img src={iconAlt} alt={title} />
                    ) : (
                      <img src={icon} alt={title} />
                    )}

                    <span dangerouslySetInnerHTML={{ __html: title }} />
                  </NavLink>
                </Animated>
              );
            })}
          </div>
        ) : null}
      </div>
      <div className={styles.bgDrop} onClick={toggle} />
    </div>
  );
};

export default Navbar;
