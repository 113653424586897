import React, { useRef } from "react";
import Swiper from 'react-id-swiper';
import useScrollEngine from "utils/useScrollEngine";
import classnames from 'classnames';

import styles from "./List.module.scss";

// components
import Package from "traffic-property-app/components/Package";

export const PackagesList = ({ packages = [] }) => {
  const packagesList = useRef(null);
  const [parentStyle, packagesListStyle] = useScrollEngine(packagesList);

  if (!packages.length) return null;
   
  const params = {
    slidesPerView: 5,
    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  }
  
  return (
    <div style={parentStyle}>
      <div
        ref={packagesList}
        style={packagesListStyle}
        // @TODO make this flex
        className={styles.packagesList}
      >
        <Swiper {...params}>
        {packages.map((pkg, i) => (
          // @TODO make this bourbon column
          <div key={i} className={classnames(styles.package, 'packagesList__wrapper')}>
            <Package package={pkg} className="packagesList" />
          </div>
        ))}
        </Swiper>
      </div>
    </div>
  );
};

export default PackagesList;
