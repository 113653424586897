import React, { useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import useApi from "traffic-property-app/api";

import config from "traffic-property-app/config";

import Button from "traffic-property-app/components/Button";
import Filters from "traffic-property-app/pages/explore/search/components/Filters";

import LotNavigation from "./Navigation/LotNavigation";
import ReleaseNavigation from "./Navigation/ReleaseNavigation";

import "./Navigation.scss";

export const Navigation = ({
  title,
  menu,
  hovered,
  onHover,
  release,
  lot,
  assetPath,
  showFilters,
  noAdvancedSearch = true,
  isResultPage = false
}) => {
  const api = useApi();
  const apartments = api.current.apartments.length;
  const townhomes = api.current.townhomes.length;
  const packages = api.current.packages.length;

  const [showFilter, setShowFilter] = useState(showFilters || false);
  const toggleFilter = () => setShowFilter(!showFilter);

  const onMouseEnter = slug => {
    if (typeof onHover === "function") onHover(slug);
  };
  const onMouseLeave = slug => {
    if (typeof onHover === "function") onHover(null);
  };

  menu = menu.filter(
    ({ status }) => status === undefined || status === 1 || status === 3
  );
  const isRelease = release;
  const isLot = lot;

  let nav = null;

  if (isLot) {
    nav = <LotNavigation release={release} lot={lot} assetPath={assetPath} />;
  } else if (isRelease) {
    nav = (
      <ReleaseNavigation
        menu={menu}
        title={title}
        hovered={hovered}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    );
  } else {
    nav = (
      <>
        <ul>
          {menu.map((item, i) => {
            const { name, slug, href, lots } = item;

            const className = classnames({
              active: slug === hovered
            });

            const availableLots = lots
              ? lots.filter(({ status }) => status === 1 || status === 3).length
              : 0;

            // if(availableLots > 0) {
            return (
              <li
                key={`default-nav-${i}`}
                className={className}
                onMouseEnter={() => onMouseEnter(slug)}
                onMouseLeave={() => onMouseLeave(slug)}
              >
                <Link to={href} className="lfs--nav--link">
                  {name}
                  {availableLots ? ` (${availableLots} lots)` : null}
                </Link>
              </li>
            );
            // }
          })}
        </ul>
      </>
    );
  }
  return (
    <div className="lfs--nav">
      {!isLot && (
        <h2>
          {title} <button onClick={toggleFilter} className="lfs--nav--filter" />
        </h2>
      )}
      <div className="ta-row">
        {!isLot && showFilter ? (
          <Filters
            filters={api.filters}
            onlyLotsFilters={true}
            isResultPage={isResultPage}
            noAdvancedSearch={noAdvancedSearch}
            showNeibourhood={false}
          />
        ) : null}
      </div>
      <div className="lfs--nav--scroll">{nav}</div>
      {!isLot && (
        <div className="lfs--c2as">
          {!isRelease && (
            <>
              <Button href={config.routes.get("landforsale.results")}>
                View all available lots
              </Button>
              {apartments || townhomes || packages ? (
                <Button
                  href={config.routes.get("property.search")}
                  color="secondary"
                  icon="search"
                  size="large"
                >
                  Property search
                </Button>
              ) : null}
              {townhomes ? (
                <Button
                  href={config.routes.get("townhomes")}
                  color="secondary"
                  icon="apartment"
                  size="large"
                  target="_self"
                  external
                >
                  View Townhomes
                </Button>
              ) : null}
              {apartments ? (
                <Button
                  href={config.routes.get("apartments")}
                  color="secondary"
                  icon="apartment"
                  size="large"
                  target="_self"
                  external
                >
                  View Apartments
                </Button>
              ) : null}
            </>
          )}

          {isRelease && (
            <Button href={assetPath + isRelease.flyer} external>
              Release Flyer
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

// Navigation.propTypes = {};

export default Navigation;
