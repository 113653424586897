import React, { createContext, useEffect, useState } from "react";
import { matchPath } from "react-router";
import { withRouter } from "react-router-dom";
import { compareTwoStrings } from "string-similarity";

export const RouterHistoryContext = createContext({ routes: [] });

const RouterHistoryComponent = ({ routes, children, location, ...props }) => {
  const { name } =
    routes.find(route => matchPath(location.pathname, { path: route.path })) ||
    {};

  location.name = name;
  location.path = `${location.pathname}${location.search}`;

  const [state, setState] = useState({ old: null, current: location });
  const { current } = state;

  useEffect(
    () => {
      if (
        current.pathname !== location.pathname ||
        current.search !== location.search
      ) {
        const similarity = compareTwoStrings(
          current.pathname,
          location.pathname
        );
        if (similarity < 0.94) {
          setState({ old: current, current: location });
        }
      }
    },
    [current, location]
  );

  return (
    <RouterHistoryContext.Provider value={state}>
      {children}
    </RouterHistoryContext.Provider>
  );
};

export const RouterHistory = withRouter(RouterHistoryComponent);

export default RouterHistory;
