import React from 'react';
import classnames from 'classnames';

import styles from './Logo.module.scss';

// images
import { ReactComponent as LogoSVG } from './logo.svg';
import { ReactComponent as LogoLandscape } from './logo-landscape.svg';

export const Logo = ({ className, size = '', layout, ...props }) => {
  const sizeClass = size ? styles[size] : null;

  return (
    <div
      {...props}
      className={classnames(className, sizeClass, styles.logo, 'logo')}
    >
      {layout === 'landscape' ? <LogoLandscape /> : <LogoSVG />}
    </div>
  );
};

export default Logo;
