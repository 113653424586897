import React from 'react';
// import classnames from "classnames";
import { useApi } from 'traffic-property-app/api';
import config from 'traffic-property-app/config';
import { PackageDetailsPage } from 'traffic-property-app/pages/explore/package/details';

// Layout components
import { Content, ContentFooter } from 'layouts/MainLayout';

// land-for-sale specific components
import { PackagesList } from 'components/Package';
import SendDetailsForm from 'components/SendDetailsForm';

import styles from './details.module.scss';
import 'react-simple-keyboard/build/css/index.css';

const PackageDetails = (props) => {
  const api = useApi();
  let title =
    props.location.path.indexOf('hl/') > -1
      ? 'House & Land'
      : props.location.path.indexOf('th/') > -1
      ? 'Townhomes'
      : null;

  // @HACK this is a quick fix for react-router issues on this app
  const slug = props.location.pathname.replace(
    `${config.routes.get('houseandland')}/`,
    '',
  );
  props.match.params.slug = slug;

  const pkg = api.packages.retrieve(slug, { extend: 'building' });
  const isTownhome = pkg.propertyType === 'townhome';
  const packages = isTownhome
    ? api.packages.list({ release: pkg.release_slug })
    : [];

  if (!pkg) return null;

  return (
    <>
      <style>
        {`.package.isDetail .package--title h3:after {
        content: '${title}';
      }`}
      </style>
      <PackageDetailsPage {...props} package={pkg} />
      <p className={styles.disclaimer}>
        *All photos and illustrations are representative and should be used as a
        guide only. This information is subject to change without prior notice,
        and while every reasonable effort is made to ensure the current and
        accurate information is presented, Risland Australia Pty Ltd makes no
        guarantee in respect to the final outcome of any kind.
      </p>
      <SendDetailsForm package={pkg} />
      <Content>
        <ContentFooter className='contentfooter'>
          <h1 className={styles.title}>
            {isTownhome
              ? 'Show release lots'
              : 'Similar House and Land Packages'}
          </h1>
          {isTownhome ? (
            packages.length ? (
              <PackagesList packages={packages} />
            ) : (
              <p className={styles.paragraph}>
                There are no similar Townhomes available.
              </p>
            )
          ) : pkg.similar && pkg.similar.length ? (
            <PackagesList packages={pkg.similar} />
          ) : (
            <p className={styles.paragraph}>
              There are no similar House and Land Packages available.
            </p>
          )}
        </ContentFooter>
      </Content>{' '}
    </>
  );
};

export default PackageDetails;
