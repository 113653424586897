import React from "react";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import config from "config";

import styles from "./BackButton.module.scss";

export const BackButton = withRouter(({ location }) => {
  const pathname = location.pathname.replace(/\/$/, "");
  const propertyResults = config.routes.get("property.results");
  const townhomes = config.routes.get("townhomes");
  const apartments = config.routes.get("apartments");
  const masterplan = config.routes.get("landforsale.masterplan");

  const disableSearch = [
    propertyResults,
    townhomes,
    apartments,
    `${masterplan}/Masterplan`,
    "/land-for-sale-search-results"
  ].includes(pathname);

  if (disableSearch) {
    return (
      <>
        <style>
          {`.backbutton {
              display: none;
            }`}
        </style>
        <div className={classnames(styles.wrapper, 'backbutton__wrapper')}></div>
      </>
    );
  }

  return null;
});

export default BackButton;
