import React from "react";

import "./404.scss";

export const PageNotFound = () => (
  <div className="not-found">
    <h4>Page not found</h4>
  </div>
);

export default PageNotFound;
