import React, { useState } from "react";
import classnames from "classnames";
import config from "traffic-property-app/config";

// Components
import Map from "traffic-property-app/components/Map";

import "./Floorplate.scss";

export const Floorplate = ({ building }) => {
  const [activeLevel, setActiveLevel] = useState(0);

  const levels = building.levels
    .slice(0)
    .filter(level => level.apartments.length);
  const level = levels[activeLevel];

  const coordMapLevels = [
    {
      coords: level.apartments,
      image: level.image,
      link: apartment =>
        apartment.slug && config.routes.get("houseandland", apartment.slug),
      legend: [
        { label: "Available" },
        { label: "Hold", className: "builders" },
        { label: "Sold" }
      ]
    }
  ];

  return (
    <section className="floorplates">
      <div className="floorplates--nav">
        <h3>Floor Plates</h3>
        <ul>
          {levels
            .map(({ name }, i) => {
              const className = classnames({ active: activeLevel === i });
              return (
                <li
                  key={i}
                  onClick={() => setActiveLevel(i)}
                  className={className}
                >
                  {name === "G" ? "Ground Floor" : `Level ${name}`}
                </li>
              );
            })
            .reverse()}
        </ul>
      </div>
      <div className="floorplates--map">
        <Map levels={coordMapLevels} showLabel noButtons />
      </div>
    </section>
  );
};

Floorplate.propTypes = {
  // size: PropTypes.oneOf(["small", "medium", "large"]),
  // color: PropTypes.oneOf(["primary", "secondary"]),
  // icon: PropTypes.string,
  // href: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // tag: PropTypes.string
};

export default Floorplate;
