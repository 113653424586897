import React from "react";
import PropTypes from "prop-types";

// @TODO try to avoid api into dumb components
import useApi from "traffic-property-app/api";

import Package from "traffic-property-app/components/Package";

import "./LotNavigation.scss";

export const LotNavigation = ({ release, lot, assetPath }) => {
  const api = useApi();
  return (
    <div className="lfs--lot">
      <Package
        key={lot.slug}
        package={lot}
        releaseFlyer={assetPath + release.flyer}
        isLot
      />
      {lot.packages && lot.packages.length ? (
        <div className="ta-row">
          <h3 className="available">Available Packages</h3>
          {lot.packages.map(({ slug }, i) => {
            const pkg = api.packages.retrieve(slug) || {};
            return (
              <span key={`lot-nav-${i}`}>
                {/* @NOTE the lot " E/land-for-sale/the_mill_quarter/acacia_release/lot/2313 " has packages */}
                <Package
                  key={pkg.slug}
                  package={pkg}
                  isSummary
                  showNameAsLotName
                />
              </span>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

LotNavigation.propTypes = {
  lot: PropTypes.object.isRequired,
  release: PropTypes.object.isRequired
};

export default LotNavigation;
