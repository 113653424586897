import config from "traffic-property-app/config";

import TownhomesMap from "./map";

export const Routes = [
  {
    path: config.routes.get("townhomes"),
    component: TownhomesMap,
    exact: true
  },
  {
    path: `${config.routes.get("townhomes")}/:slug`,
    component: TownhomesMap,
    exact: true
  }
];
