// icons
import amenities from 'assets/images/icons/amenities.png';
import house from 'assets/images/icons/house-and-land.svg';
import houseAlt from 'assets/images/icons/house-and-land-splash.svg';
import land from 'assets/images/icons/land-for-sale.svg';
import landAlt from 'assets/images/icons/land-for-sale-splash.svg';
import masterplan from 'assets/images/icons/masterplan.png';
import townhomes from 'assets/images/icons/townhomes.png';

import mapview from 'assets/images/icons/mapview.svg';
import mapviewActive from 'assets/images/icons/mapviewActive.svg';
import listview from 'assets/images/icons/listview.svg';
import listviewActive from 'assets/images/icons/listviewActive.svg';
import videos from 'assets/images/icons/videos-icon.png';

if (process.env.NODE_ENV === 'production') {
  const windowIcons = window.traffic.style.icons;
  Object.keys(windowIcons).forEach((key) => {
    const icon = windowIcons[key];
    window.traffic.style.icons[key] = icon.replace(
      '.svg',
      `.${process.env.EXAMPLE}.svg`,
    );
  });
}

const { default: config } = require('traffic-property-app/config');
export * from 'traffic-property-app/config';

const icons = {
  amenities,
  house,
  houseAlt,
  land,
  landAlt,
  masterplan,
  townhomes,
  mapview,
  mapviewActive,
  listview,
  listviewActive,
  videos,
};

config.routes = {
  ...config.routes,
  masterplan: 'masterplan',
  amenities: 'amenities',
  videos: 'videos',
};

config.style.icons = {
  ...config.style.icons,
  ...window.traffic.style.icons,
  ...icons,
};
config.videos = [
  {
    name: 'Aerial',
    video: 'aerial.mp4',
    screen: 'aerial.jpg',
    duration: '1',
  },
  {
    name: 'Wilton Greens is',
    video: 'wilton-greens-is.mp4',
    screen: 'wilton-greens-is.jpg',
    duration: '1',
  },

  {
    name: 'Favourite Aspects',
    video: 'favourite-aspects.mp4',
    screen: 'favourite-aspects.jpg',
    duration: '1.14',
  },

  {
    name: 'Our New Home',
    video: 'our-new-home.mp4',
    screen: 'our-new-home.jpg',
    duration: '1.19',
  },

  {
    name: 'The Wilton Area',
    video: 'the-wilton-area-is.mp4',
    screen: 'the-wilton-area-is.jpg',
    duration: '1',
  },
];

config.sidebar = [
  {
    icon: icons.land,
    iconAlt: icons.landAlt,
    title: 'Land for sale',
    shorten: 'Land',
    id: 'land',
    link: config.routes.get('landforsale.masterplan'),
  },
  {
    icon: icons.house,
    iconAlt: icons.houseAlt,
    title: "House and land",
    shorten: "House & Land",
    id: "houseandland",
    link: config.routes.get("property.results", "?type=house")
  },
  // {
  //   icon: icons.townhomes,
  //   title: "Townhomes",
  //   shorten: "Townhomes",
  //   id: "townhomes",
  //   link: config.routes.get("townhomes")
  // },
  {
    icon: icons.masterplan,
    iconAlt: null,
    title: 'Masterplan',
    shorten: 'Masterplan',
    id: 'masterplan',
    link: config.routes.get('masterplan'),
  },
  {
    icon: icons.amenities,
    iconAlt: null,
    title: 'Amenities',
    shorten: 'Amenities',
    id: 'amenities',
    link: config.routes.get('amenities'),
  },
  {
    icon: icons.videos,
    iconAlt: null,
    title: 'Videos',
    shorten: 'Videos',
    id: 'videos',
    link: config.routes.get('videos'),
  },
];

// config.status = {
//   ...config.status,
//   3: 'Deposit',
// };
config.status ={
  1: "Available",
  2: "Sold",
  3: "House & Land Exclusive",
  4: "Deposit Taken",
  5: "Coming Soon",
}

config.other_options= {
  masterplan: {
    show_price_next_to_lot: true,
    legend: [
      { label: "Coming Soon" },
      { label: "Available" },
      { label: "Deposit Taken" },
      { label: "House & Land Exclusive" },
      { label: "Sold" }
    ]
  }
}

export const sidebar = config.sidebar;

config.isTouchTable = window.screen.width >= 3840;

export default config;
