import React from "react";
import classnames from "classnames";

import styles from "./Sidebar.module.scss";

export const Sidebar = ({ className, draggable, ...props }) => (
  <div
    className={classnames(className, styles.sidebar, {
      [styles.draggable]: draggable
    })}
    {...props}
  />
);

export default Sidebar;
