import React from "react";

export const SelectRange = ({
  options,
  from,
  to,
  value: range,
  defaultValue,
  className,
  onChange,
  suffix,
  step,
  minOptionLabel,
  maxOptionLabel,
  ...props
}) => {
  const [minValue = "", maxValue = ""] = range;

  const handleChange = e => {
    const type = e.target.name;
    const value = e.target.value ? parseInt(e.target.value) : null;
    const [min, max] = range;

    // type could be min or max
    let newRange = type === "min" ? [value || from, max] : [min, value || to];

    const [newFrom, newMax] = newRange;

    if (!newFrom && !newMax) newRange = null;

    if (typeof onChange === "function") onChange(newRange, e);
  };

  return (
    <>
      <div className="select">
        <select {...props} name="min" value={minValue} onChange={handleChange}>
          <option>{minOptionLabel || "ANY"}</option>
          {options.map(({ name, value, slug }, i) => (
            <option key={i} value={value}>
              {isNaN(name) ? name : `${name}${suffix}`}
            </option>
          ))}
        </select>
      </div>
      <div className="select--divider" />
      <div className="select">
        <select {...props} name="max" value={maxValue} onChange={handleChange}>
          <option>{maxOptionLabel || "ANY"}</option>
          {options.map(({ name, value, slug }, i) => (
            <option key={i} value={value}>
              {isNaN(name) ? name : `${name}${suffix}`}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default SelectRange;
