import sift from "sift";
// import { generateBuilderSlug } from "../utils";

export const initTownhomes = (api, { parseSlug }) => {
  return {
    list: query => {
      const data = api.current || {};
      let { townhomes } = data || {};

      if (townhomes) {
        const siftQuery = { $and: [], $or: [] };

        if (!siftQuery.$and.length) delete siftQuery.$and;
        if (!siftQuery.$or.length) delete siftQuery.$or;

        townhomes = townhomes.filter(sift(siftQuery));
      }

      return townhomes || [];
    },
    retrieve: slug => {
      const data = api.current || {};
      const { townhomes = [] } = data || {};

      const townhome = townhomes.find(townhome => townhome.slug === slug);

      if (townhome && townhome.lots && townhome.lots.length) {
        townhome.lots.map(lot => {
          lot.packages = lot.packages.map(pkg => {
            if (pkg && pkg.id && !pkg.name)
              return slug ? api.packages.retrieve({ id: pkg.id }) : null;

            return pkg;
          });

          return lot;
        });
      }

      return townhome;
    }
  };
};

export default initTownhomes;
