import sift from "sift";

export const initBuildings = (api, { parseUrl }) => {
  const apartments = api.packages.list({ propertyType: "apartment" });

  // buildings.map(building => {
  //   building.levels.map(level => {
  //     apartments = [...apartments, ...level.apartments];
  //     return level;
  //   });
  //   return building;
  // });

  return {
    list: query => {
      const data = api.current || {};
      const buildings = data.apartments || [];

      // if (apartments) {
      //   apartments.map(release => (apartments = [...apartments, ...release.apartments]));
      //
      //   const siftQuery = { $and: [], $or: [] };
      //   if (!siftQuery.$and.length) delete siftQuery.$and;
      //   if (!siftQuery.$or.length) delete siftQuery.$or;
      //
      //   apartments = apartments.filter(sift(siftQuery));
      // }

      return {
        data: buildings
      };
    },
    retrieve: slug => {
      const data = api.current || {};
      const packages = data.packages || [];
      const buildings = data.apartments || [];

      const building = buildings.find(building => building.slug === slug);

      if (building) {
        building.levels = building.levels.map(level => {
          // console.log("level.apartments", level.apartments);
          level.apartments = level.apartments.map(apartment => {
            if (apartment && apartment.package_id) {
              const apartmentPkg = packages.find(
                ({ id }) => id === apartment.package_id
              );

              if (apartmentPkg) return apartmentPkg;
            }

            return apartment;
          });

          // Remove null apartments
          level.apartments = level.apartments.filter(apartment => apartment);

          if (level.image && level.image.url)
            level.image.url = parseUrl(level.image.url);

          return level;
        });
      }

      return building;
    },
    filters: () => {
      const { apartmentSearch } = api.data || {};
      const layoutTypes = apartmentSearch.layout_type.options.map(
        ({ name }) => name
      );
      const apartmentTypes = apartmentSearch.apartment_type.options;
      const filteredApartments = apartments.filter(
        sift({
          $and: [
            {
              $or: [
                { layout_type: { $in: layoutTypes } },
                { apartment_type: { $in: apartmentTypes } }
              ]
            },
            { $or: [{ status_id: 1 }, { status: 1 }] }
          ]
        })
      );

      const apartmentTypeOptions = [];
      const layoutTypeOptions = [];

      // extract all the types from the filtered apartments
      filteredApartments.map(apartment => {
        apartment.apartment_type.map(type => {
          if (!apartmentTypeOptions.includes(type))
            apartmentTypeOptions.push(type);

          return type;
        });

        const layoutType = apartment.layout_type;
        if (!layoutTypeOptions.includes(layoutType))
          layoutTypeOptions.push(layoutType);

        return apartment;
      });

      // set the apartment_type options to the filtered types
      apartmentSearch.apartment_type.options = apartmentTypeOptions;

      // set the layout_type options to the filtered types
      apartmentSearch.layout_type.options = apartmentSearch.layout_type.options.filter(
        ({ name }) => layoutTypeOptions.includes(name)
      );

      // Extract the labels and info from each option
      apartmentSearch.layout_type.options = apartmentSearch.layout_type.options.map(
        option => {
          const { bedrooms, bathrooms } = filteredApartments.find(
            ({ layout_type }) => layout_type === option.name
          );

          if (option.image) option.image.url = parseUrl(option.image.url);

          // parseUrl
          option.label = `${bedrooms} BEDROOM - TYPE ${option.name}`;
          option.bathrooms = bathrooms;
          option.bedrooms = bedrooms;

          return option;
        }
      );

      return apartmentSearch;
    }
  };
};

export default initBuildings;
