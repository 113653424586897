import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link } from "react-router-dom";

// Components
import Icon from "traffic-property-app/components/Icon";

import "./Button.scss";

export const Button = ({
  type: Type,
  size,
  color,
  icon,
  children,
  href,
  external,
  target,
  className: classNameProp,
  ...props
}) => {
  if (!Type) Type = "button";

  const className = classnames("ta-btn", classNameProp, {
    [`ta-btn__${size}`]: size,
    [`ta-btn__${color}`]: color
  });

  if (href) {
    return (
      <>
        {!external ? (
          <Link to={href} className={className}>
            {icon && <Icon icon={icon} />}
            {children}
          </Link>
        ) : (
          <a
            href={href}
            target={target || "_blank"}
            rel="noopener noreferrer"
            className={className}
          >
            {icon && <Icon icon={icon} />}
            {children}
          </a>
        )}
      </>
    );
  }

  return (
    <Type {...props} className={className}>
      {children}
      {icon && <Icon icon={icon} />}
    </Type>
  );
};

Button.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  color: PropTypes.oneOf(["primary", "secondary"]),
  icon: PropTypes.string,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tag: PropTypes.string
};

export default Button;
