import React from "react";
import classnames from "classnames";

import styles from "./Content.module.scss";

export const ContentLeftBar = ({ className, ...props }) => (
  <div className={classnames(className, styles.left_bar)} {...props} />
);

export default ContentLeftBar;
