import sift from "sift";
import { sort } from "sift-sort";
import { str } from "dot-object";

import { lowercaseAndParseSpaces as _lowercaseAndParseSpaces } from "traffic-property-app/utils";

export const generateBuilderSlug = _lowercaseAndParseSpaces;
export const lowercaseAndParseSpaces = _lowercaseAndParseSpaces;

const isArray = value => Array.isArray(value);

export const queryBuilder = (packages, options) => {
  const { filters, statusOptions, sort: sortQuery, ...query } = options || {};

  if (filters) {
    //
    // Query builder
    //
    // This section generates queries depending on the
    // available filters types ( range, select, multi-select )
    //
    const siftQuery = {
      $and: [],
      $or: [
        { status_id: 1 },
        { status: 1 },
        { status_id: 3 },
        { status: 3 },
        { status_id: 4 },
        { status: 4 },
        { status_id: 5 },
        { status: 5 },
        { $not: { propertyType: "apartment" }, status: { $exists: false } }
      ]
    };

    if (statusOptions && statusOptions.length) {
      if (statusOptions.includes("promoted")) {
        siftQuery.$and.push({ promoted: 1 });
      }

      if (statusOptions.includes("titled")) {
        siftQuery.$and.push({
          flags: { titled: { $and: [{ $exists: 1 }, { $eq: 1 }] } }
        });
      }

      if (statusOptions.includes("featured")) {
        siftQuery.$and.push({
          flags: { featured: { $and: [{ $exists: 1 }, { $eq: 1 }] } }
        });
      }
    }

    Object.keys(filters).map(key => {
      const value = query[key];

      if (value) {
        const filter = filters[key];
        const { type } = filter;

        // some packages values have different name on the api
        // so each filter has a { apiName } field if needed
        const apiName = filter.apiName || key;

        if (type === "range" || (type === "select" && !filter.options)) {
          // if is array means is a range [from, to]
          if (isArray(value)) {
            const [from, to] = value;

            const isDefaultValue = from === filter.from && to === filter.to;

            // if the "to" value is greater or equal to the filter maximum allowed
            // and since the last value has a "+" sign, for example 5
            // then we filter all greater than the "from" value
            if (!isDefaultValue) {
              if ((from && !to) || (!from && to)) {
                const shouldUseGteOrLte = [
                  "price",
                  "area",
                  "frontage"
                ].includes(apiName);

                if (shouldUseGteOrLte) {
                  if (from) siftQuery[apiName] = { $gte: from };
                  if (to) siftQuery[apiName] = { $lte: to };
                } else {
                  siftQuery[apiName] = { $eq: from || to };
                }
              } else if (to >= filter.to) {
                siftQuery[apiName] = { $gte: from };
              } else {
                siftQuery[apiName] = { $gte: from, $lte: to };
              }
            }
          } else {
            if (value >= filter.to) siftQuery[apiName] = { $gte: value };
            else siftQuery.$and.push({ [apiName]: value });
          }
        }

        if (type === "select" && filter.options) {
          siftQuery[apiName] = { $in: isArray(value) ? value : [value] };
        }

        if (type === "check-list" && filter.options) {
          siftQuery[apiName] = { $in: isArray(value) ? value : [value] };
        }
      }

      return null;
    });

    if (!siftQuery.$and.length) delete siftQuery.$and;
    if (!siftQuery.$or.length) delete siftQuery.$or;

    packages = packages.filter(sift(siftQuery));
  }

  if (sortQuery && typeof sortQuery === "string") {
    const [sortBy, sortDesc] = sortQuery.split("-");
    if (sortBy) {
      const siftSort = {};
      str(sortBy, sortDesc ? -1 : 1, siftSort);

      packages = sort(packages, siftSort);
    }
  }

  return packages;
};
