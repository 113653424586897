import React from "react";
import classnames from "classnames";

import config from "traffic-property-app/config";
import useApi from "traffic-property-app/api";
import { qs, formatPrice } from "traffic-property-app/utils";

import styles from "./RecentSearches.module.scss";

export const getRecentSearch = () => {
  return JSON.parse(localStorage.getItem("recent-search")) || [];
};

export const saveRecentSearch = query => {
  const recentSearches = getRecentSearch();
  const rsIndex = recentSearches.indexOf(query);
  if (rsIndex > -1) recentSearches.splice(rsIndex, 1);
  recentSearches.unshift(query);
  recentSearches.length = 4;
  localStorage.setItem("recent-search", JSON.stringify(recentSearches));
};

const TYPES = {
  house: "House & Land",
  townhome: "Townhomes",
  apartment: "Apartments"
};

export const RecentSearches = ({ titleClass, onPackageClick, className }) => {
  const api = useApi();
  const totalPropertyTypes = api.filters.propertyType.options.length;
  const recentSearches = getRecentSearch();

  return recentSearches.length ? (
    <div className={classnames(className, styles.container, "ta-row")}>
      {recentSearches.map(search => {
        if (!search) return null;

        const {
          bedrooms = "1-2",
          bathrooms = "1-2",
          stories = "1-2",
          parking = "1-2",
          price = "400000-1200000",
          type = "all",
          neighbourhood,
          estate
          // ...rest
        } = qs.parse(search);

        const hasEstates = Object.keys(config.estates).length > 1;

        const neighbourhoodsOrEstates = estate
          ? typeof estate === "string"
            ? [estate]
            : estate || []
          : typeof neighbourhood === "string"
          ? [neighbourhood]
          : neighbourhood || [];

        // console.log("bathrooms", bathrooms);
        // console.log("bedrooms", bedrooms);
        // console.log("parking", parking);
        // console.log("stories", stories);
        // console.log("rest", rest);

        const p = price
          .split("-")
          .map(price => formatPrice(price, { other: "($0a)" }));

        return (
          <a
            key={search}
            className={styles.wrapper}
            href={config.routes.get("property.results", `?${search}`)}
            onClick={onPackageClick}
          >
            <h3>
              {p.join(" - ")} •{" "}
              {typeof type === "object"
                ? type.length === totalPropertyTypes
                  ? "all"
                  : type.map(type => TYPES[type]).join(", ")
                : type !== "all"
                ? TYPES[type]
                : "all"}
            </h3>
            <p className={styles.neighbourhoodsOrEstates}>
              {neighbourhoodsOrEstates.length
                ? neighbourhoodsOrEstates
                    .map(neighbourhoodsOrEstate =>
                      neighbourhoodsOrEstate.split("-").join(" ")
                    )
                    .join(" • ")
                : `all ${
                    hasEstates
                      ? config.labels.get("filters.estate")
                      : config.labels.get("filters.neighbourhood")
                  }s`}
            </p>
            <p>
              {bedrooms} Bed • {bathrooms} Bath • {stories} Storey • {parking}{" "}
              Park
            </p>
          </a>
        );
      })}
    </div>
  ) : (
    <div className={classnames(className, styles.container)}>
      No recent searches
    </div>
  );
};

export default RecentSearches;
