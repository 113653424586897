import React, { useRef, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import sift from "sift";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import config from "traffic-property-app/config";
import { useApi } from "traffic-property-app/api";
import { qs, range } from "traffic-property-app/utils";

// Components
import Icon from "traffic-property-app/components/Icon";
import Button from "traffic-property-app/components/Button";
import Package from "traffic-property-app/components/Package";
import {
  RecentSearches,
  saveRecentSearch,
  getRecentSearch
} from "traffic-property-app/components/UserMenu";

import Filter from "./Filter";

import "./Filters.scss";

const rangeFields = [
  "price",
  "bedrooms",
  "bathrooms",
  "parking",
  "stories",
  "frontage",
  "landSize"
];

const LandComponent = () => (
  <div className="ta-filter--checklist ta-filter--checklist--lfs">
    <h3 className="ta-filter--title">
      Land for sale
      <Icon icon="chevron" />
    </h3>
    <div className="ta-filter--item">
      <a href={config.routes.get("landforsale.results")}>
        <div className="checklist checklist__large">
          <span className="checkbox checkbox__icon">
            <Icon icon="land" className="label">
              Land
            </Icon>
          </span>
        </div>
      </a>
    </div>
  </div>
);

// @TODO remove quick fix of onlyLotsFilters and create a search criteria for landforsale
export const Filters = ({
  filters,
  defaultValues = {},
  onlyLotsFilters,
  isResultPage,
  history,
  containsLand,
  noAdvancedSearch,
  showNeibourhood = true
}) => {
  const {
    release,
    estate,
    neighbourhood,
    propertyType,
    price,
    bedrooms,
    bathrooms,
    parking,
    stories,
    landSize,
    frontage,
    builder,

    // @TODO add support to other options when available
    otherOptions,
    statusOptions
  } = filters;

  // Changing parking label
  parking.label = "car spaces"; 

  const api = useApi();

  const [toggled, setToggle] = useState({});
  const onToggle = name => {
    setToggle({ ...toggled, [name]: !toggled[name] });
  };

  const [toggledFilter, setToggledFilter] = useState(false);
  const [query, setQuery] = useState(defaultValues);

  useEffect(() => {
    setQuery(defaultValues);
  }, []); // eslint-disable-line

  const handleChange = ({ name, value }, e) => {
    return setQuery({ ...query, [name]: value || undefined });
  };

  const onSubmit = (e, options) => {
    const { all } = options || {};
    e.preventDefault();

    // re-assign some params for the url
    const _query = all ? {} : query;

    if (!onlyLotsFilters && propertyType && !_query.propertyType)
      _query.propertyType = propertyType.options.map(({ value }) => value);

    rangeFields.map(name => {
      // const filter = filters[name];

      const value = _query[name] ? _query[name] : null;

      if (value) _query[name] = range.stringify(value);
      return value;
    });

    // re-assign no unnecesary params
    _query.type = _query.propertyType;
    _query.land_size = _query.landSize;
    _query.other_options = _query.otherOptions;
    _query.featured_options = _query.statusOptions;

    // remove no unnecesary params
    delete _query.propertyType;
    delete _query.landSize;
    delete _query.otherOptions;
    delete _query.statusOptions;

    const currentQueries = qs.parse(window.location.search);
    const queries = qs.stringify({ ...currentQueries, ..._query });

    if (!onlyLotsFilters) {
      const queriesRecentSearch = { ...currentQueries, ..._query };
      delete queriesRecentSearch.sort;
      const queriesRecentSearchString = qs.stringify(queriesRecentSearch);
      saveRecentSearch(queriesRecentSearchString);
    }

    const route = onlyLotsFilters
      ? config.routes.get("landforsale.results")
      : config.routes.get("property.results");
    const searchPath = `${route}?${queries}`;

    if (isResultPage) {
      setToggle({});
      history.push(searchPath);
    } else window.location.href = searchPath;
  };

  const [showAdvancedSearch, setAdvancedSearch] = useState(false);
  const toggleAdvancedSearch = () => {
    setAdvancedSearch(!showAdvancedSearch);
  };

  const advancedSearchClass = classnames("advancedsearch", {
    isActive: showAdvancedSearch
  });

  const invalidEstate = query.estate && !query.estate.length;
  const invalidNeighbourhood =
    query.neighbourhood && !query.neighbourhood.length;
  const invalidOtherOptions = query.otherOptions && !query.otherOptions.length;
  const invalidPropertyType = query.propertyType && !query.propertyType.length;
  const disableButton =
    invalidEstate ||
    invalidNeighbourhood ||
    invalidPropertyType ||
    invalidOtherOptions;

  const neighbourhoodOptions =
    showNeibourhood && neighbourhood
      ? onlyLotsFilters
        ? neighbourhood.options.filter(option => option.landforsale)
        : neighbourhood.options.filter(option => option.packages)
      : [];
  showNeibourhood = neighbourhoodOptions.length > 1;

  const allPackages = onlyLotsFilters ? api.lots.list() : api.packages.list();
  otherOptions.options = otherOptions.options.filter(option => {
    return allPackages.find(sift({ attributes: { $in: [option.value] } }));
  });
  const disableAdvancedSearch = onlyLotsFilters
    ? otherOptions.options.length > 1
    : !noAdvancedSearch;

  const filterWrapper = useRef();

  const toggleFilters = () => {
    window.innerWidth < 993 && setToggledFilter(!toggledFilter);
    !toggledFilter &&
      window.innerWidth < 993 &&
      disableBodyScroll(filterWrapper.current);
    toggledFilter &&
      window.innerWidth < 993 &&
      enableBodyScroll(filterWrapper.current);
  };

  const propertyToCompare = query.propertyToCompare;
  const recentSearches = getRecentSearch();

  return (
    <section className={classnames("ta-filter", { noAdvancedSearch })}>
      <form
        onSubmit={onSubmit}
        className={classnames({ active: toggledFilter })}
      >
        <div className="ta-row ta-filter--heading">
          <h2>
            {propertyToCompare ? (
              "Your chosen property"
            ) : (
              <>
                Refine your search
                {!toggledFilter ? (
                  <Icon icon="filter" onClick={toggleFilters} />
                ) : (
                  <Icon icon="close_black" onClick={toggleFilters} />
                )}
              </>
            )}
          </h2>
        </div>
        {propertyToCompare ? (
          <span
            ref={filterWrapper}
            className={classnames("ta-filter--fieldswrapper active")}
          >
            <Package
              className="ta-filter--similar-properties-package"
              data={propertyToCompare}
              propertyToCompare={false}
              summary
            />
            {recentSearches.length ? (
              <div className="ta-filter--recent-searches__wrapper">
                <h2>Recent Searches</h2>
                <div className="ta-container">
                  <RecentSearches className="ta-filter--recent-searches" />
                </div>
              </div>
            ) : null}
          </span>
        ) : (
          <span
            ref={filterWrapper}
            className={classnames("ta-filter--fieldswrapper", {
              active: toggledFilter
            })}
          >
            <span className="ta-filter--fieldswrapper__scroll">
              {estate ? (
                <div className="ta-row">
                  <Filter
                    {...estate}
                    onChange={handleChange}
                    value={query.estate}
                    name="estate"
                    className="ta-filter--checklist ta-filter--estates"
                    required={invalidEstate}
                    allSelectedByDefault
                    hideCheckIcon
                  />
                </div>
              ) : null}
              {showNeibourhood && neighbourhood ? (
                <div className="ta-row">
                  <Filter
                    {...neighbourhood}
                    options={neighbourhoodOptions}
                    onChange={handleChange}
                    value={query.neighbourhood}
                    name="neighbourhood"
                    className="ta-filter--checklist"
                    required={invalidNeighbourhood}
                    allSelectedByDefault
                    showSelectAll
                    hideCheckIcon
                  />
                </div>
              ) : null}
              {/* @NOTE each filter props have all the required values for
                Range, Select and CheckList components this is why we can use
                them like {...propertyType} */}
              {!onlyLotsFilters ? (
                <div className="ta-row">
                  <Filter
                    {...propertyType}
                    onChange={handleChange}
                    value={query.propertyType}
                    name="propertyType"
                    className="ta-filter--checklist"
                    size="large"
                    allSelectedByDefault
                    showSelectAll
                    required={invalidPropertyType}
                  />
                  {containsLand && !isResultPage ? <LandComponent /> : null}
                  <Filter
                    {...price}
                    onChange={handleChange}
                    className="ta-filter--range"
                    value={query.price}
                    minOptionLabel="MIN PRICE"
                    maxOptionLabel="MAX PRICE"
                    currency
                  />
                </div>
              ) : null}
              <>
                {!onlyLotsFilters ? (
                  <div className="ta-row">
                    <Filter
                      {...bedrooms}
                      onChange={handleChange}
                      value={query.bedrooms}
                      className="ta-filter--range"
                      onToggle={() => onToggle("bedrooms")}
                      toggled={toggled.bedrooms}
                    />
                    <Filter
                      {...bathrooms}
                      onChange={handleChange}
                      value={query.bathrooms}
                      className="ta-filter--range"
                      onToggle={() => onToggle("bathrooms")}
                      toggled={toggled.bathrooms}
                    />
                    <Filter
                      {...parking}
                      onChange={handleChange}
                      value={query.parking}
                      className="ta-filter--range"
                      onToggle={() => onToggle("parking")}
                      toggled={toggled.parking}
                    />
                    <Filter
                      {...stories}
                      onChange={handleChange}
                      value={query.stories}
                      className="ta-filter--range"
                      onToggle={() => onToggle("stories")}
                      toggled={toggled.stories}
                    />
                  </div>
                ) : null}
              </>

              <div
                className={classnames("ta-row", {
                  [advancedSearchClass]: !onlyLotsFilters
                })}
              >
                <Filter
                  {...landSize}
                  onChange={handleChange}
                  name="landSize"
                  value={query.landSize}
                  className={classnames("ta-filter--range", {
                    noAdvancedSearch
                  })}
                  suffix="m²"
                />
                <Filter
                  {...frontage}
                  onChange={handleChange}
                  value={query.frontage}
                  className={classnames("ta-filter--range", {
                    noAdvancedSearch
                  })}
                  suffix="m"
                />
                {onlyLotsFilters ? (
                  <Filter
                    {...release}
                    onChange={handleChange}
                    value={query.release}
                    className={classnames("ta-filter--range", {
                      noAdvancedSearch
                    })}
                  />
                ) : (
                  <Filter
                    {...builder}
                    onChange={handleChange}
                    value={query.builder}
                    className={classnames("ta-filter--range", {
                      noAdvancedSearch
                    })}
                  />
                )}

                {statusOptions.options.length > 0 && !noAdvancedSearch ? (
                  <div className="status-options">
                    <Filter
                      {...statusOptions}
                      onChange={handleChange}
                      value={query.statusOptions}
                      name="statusOptions"
                      className="ta-filter--checklist"
                    />
                  </div>
                ) : null}
              </div>

              <div
                className={classnames(
                  advancedSearchClass,
                  "ta-row",
                  "other-options"
                )}
              >
                {otherOptions.options.length > 1 ? (
                  <Filter
                    {...otherOptions}
                    onChange={handleChange}
                    value={query.otherOptions}
                    name="otherOptions"
                    showSelectAll
                    className="ta-filter--checklist"
                    required={invalidOtherOptions}
                    allSelectedByDefault
                  />
                ) : null}
              </div>

              <div className="ta-row ta-filter--extra">
                {disableAdvancedSearch && !noAdvancedSearch ? (
                  <div
                    className="ta-filter--extra__toggle"
                    onClick={toggleAdvancedSearch}
                  >
                    Advanced Search
                  </div>
                ) : null}
                <div
                  className={classnames("ta-filter--extra__reset", {
                    single: !disableAdvancedSearch
                  })}
                  onClick={() => setQuery({})}
                >
                  Reset
                </div>
                <div className="ta-filter--extra__submit">
                  <Button
                    color="primary"
                    size="large"
                    disabled={disableButton}
                    onClick={toggleFilters}
                  >
                    {isResultPage ? "Update" : "Search"}
                  </Button>
                  {!isResultPage && !onlyLotsFilters ? (
                    <div
                      className="view-all"
                      onClick={e => onSubmit(e, { all: true })}
                    >
                      View all properties
                    </div>
                  ) : null}
                </div>
              </div>
            </span>
          </span>
        )}
      </form>
    </section>
  );
};

export default withRouter(Filters);
