import React from "react";
import classnames from "classnames";

import Icon from "traffic-property-app/components/Icon";
import { Range, Select, CheckList } from "traffic-property-app/components/Form";

export const Filter = ({
  type,
  apiName,
  className,
  toggled,
  onToggle,
  required,
  ...props
}) => {
  const label = props.label;
  const isRange = type === "range";
  const isSelect = type === "select";
  const isCheckList = type === "check-list";

  let component = null;
  if (isRange) component = <Range {...props} />;
  if (isSelect) component = <Select {...props} />;
  if (isCheckList) component = <CheckList {...props} />;

  const isToggle = typeof onToggle === "function";

  return (
    <div
      className={classnames(className, `ta-` + label.toLowerCase(), {
        isToggle,
        isActive: toggled
      })}
    >
      <h3
        className="ta-filter--title"
        onClick={isToggle ? onToggle : undefined}
      >
        {label}{" "}
        {props.suffix ? (
          <span dangerouslySetInnerHTML={{ __html: `(${props.suffix})` }} />
        ) : null}
        {required ? (
          <span className="ta-filter--title__error"> (Required)</span>
        ) : null}
        {isToggle && isRange ? (
          <span className="selectedvalue">
            {props.value
              ? Array.isArray(props.value)
                ? props.value.join("-")
                : typeof props.value === "number"
                ? props.value
                : props.value.replace("-", " - ")
              : "Any"}
          </span>
        ) : null}
        <Icon icon="chevron" />
      </h3>
      <div className="ta-filter--item">{component}</div>
    </div>
  );
};

export default Filter;
